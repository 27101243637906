// src/services/firebaseService.js

import {
  getFirestore,
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  getDoc,
  doc,
  getDocs,
  onSnapshot,
  serverTimestamp,
  deleteField, // Import deleteField from Firebase Firestore
  query,
  where,
  setDoc,
} from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useCallback, useMemo } from 'react';

const db = getFirestore();

export const useFirebaseService = () => {
  // ======= Profile Management Functions =======

  /**
   * Fetches all profiles for a given user.
   * @param {string} userId - The ID of the user.
   * @returns {Promise<Array>} - An array of profile objects.
   */
  const getProfiles = useCallback(async (userId) => {
    try {
      if (!userId) {
        throw new Error('Missing userId for getProfiles');
      }
      const profilesCollection = collection(db, `users/${userId}/profiles`);
      const snapshot = await getDocs(profilesCollection);
      const profiles = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return profiles;
    } catch (error) {
      console.error('Error fetching profiles:', error);
      toast.error('Failed to fetch profiles.');
      throw error;
    }
  }, []);

  /**
   * Adds a new profile for a user.
   * @param {string} userId - The ID of the user.
   * @param {Object} profileData - The data for the new profile.
   * @returns {Promise<string>} - The ID of the newly created profile.
   */
  const addProfile = useCallback(async (userId, profileData) => {
    try {
      if (!userId || !profileData || !profileData.name) {
        throw new Error('Missing parameters for addProfile');
      }
      const profilesCollection = collection(db, `users/${userId}/profiles`);
      const docRef = await addDoc(profilesCollection, {
        name: profileData.name,
        createdAt: profileData.createdAt || serverTimestamp(),
      });
      toast.success('Profile created successfully!');
      return docRef.id;
    } catch (error) {
      console.error('Error adding profile:', error);
      toast.error('Failed to create profile.');
      throw error;
    }
  }, []);

  /**
   * Updates an existing profile.
   * @param {string} userId - The ID of the user.
   * @param {string} profileId - The ID of the profile to update.
   * @param {Object} updatedData - The data to update.
   */
  const updateProfile = useCallback(async (userId, profileId, updatedData) => {
    try {
      if (!userId || !profileId || !updatedData) {
        throw new Error('Missing parameters for updateProfile');
      }
      const profileDocRef = doc(db, `users/${userId}/profiles/${profileId}`);
      await updateDoc(profileDocRef, updatedData);
      toast.success('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Failed to update profile.');
      throw error;
    }
  }, []);

  /**
   * Deletes a profile.
   * @param {string} userId - The ID of the user.
   * @param {string} profileId - The ID of the profile to delete.
   */
  const deleteProfile = useCallback(async (userId, profileId) => {
    try {
      if (!userId || !profileId) {
        throw new Error('Missing parameters for deleteProfile');
      }
      const profileDocRef = doc(db, `users/${userId}/profiles/${profileId}`);
      await deleteDoc(profileDocRef);
      toast.success('Profile deleted successfully!');
    } catch (error) {
      console.error('Error deleting profile:', error);
      toast.error('Failed to delete profile.');
      throw error;
    }
  }, []);

  /**
   * Subscribes to real-time updates of profiles.
   * @param {string} userId - The ID of the user.
   * @param {Function} callback - The callback function to handle updates.
   * @returns {Function} - The unsubscribe function.
   */
  const subscribeToProfiles = useCallback((userId, callback) => {
    if (!userId || typeof callback !== 'function') {
      console.error('Missing or invalid parameters for subscribeToProfiles');
      toast.error('Unable to subscribe to profiles.');
      return;
    }

    const profilesCollection = collection(db, `users/${userId}/profiles`);
    const unsubscribe = onSnapshot(
      profilesCollection,
      (snapshot) => {
        const profiles = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        callback(profiles);
      },
      (error) => {
        console.error('Error subscribing to profiles:', error);
        toast.error('Failed to subscribe to profiles.');
      }
    );

    return unsubscribe;
  }, []);

  // ======= Shopping List Management Functions =======

  /**
   * Fetches all shopping lists for a given profile.
   * @param {string} userId - The ID of the user.
   * @param {string} profileId - The ID of the profile.
   * @returns {Promise<Array>} - An array of shopping list objects.
   */
  const getShoppingLists = useCallback(async (userId, profileId) => {
    try {
      if (!userId || !profileId) {
        throw new Error('Missing userId or profileId for getShoppingLists');
      }
      const listsCollection = collection(
        db,
        `users/${userId}/profiles/${profileId}/shoppingLists`
      );
      const snapshot = await getDocs(listsCollection);
      const lists = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return lists;
    } catch (error) {
      console.error('Error fetching shopping lists:', error);
      toast.error('Failed to fetch shopping lists.');
      throw error;
    }
  }, []);

  /**
   * Adds a new shopping list.
   * @param {string} userId - The ID of the user.
   * @param {string} profileId - The ID of the profile.
   * @param {Object} listData - The data for the new shopping list.
   * @returns {Promise<string>} - The ID of the newly created shopping list.
   */
  const addShoppingList = useCallback(async (userId, profileId, listData) => {
    try {
      if (!userId || !profileId || !listData || !listData.name) {
        throw new Error('Missing parameters for addShoppingList');
      }
      const listsCollection = collection(
        db,
        `users/${userId}/profiles/${profileId}/shoppingLists`
      );
      const docRef = await addDoc(listsCollection, {
        name: listData.name,
        createdAt: listData.createdAt || serverTimestamp(),
      });
      toast.success('Shopping list created successfully!');
      return docRef.id;
    } catch (error) {
      console.error('Error adding shopping list:', error);
      toast.error('Failed to create shopping list.');
      throw error;
    }
  }, []);

  /**
   * Updates an existing shopping list.
   * @param {string} userId - The ID of the user.
   * @param {string} profileId - The ID of the profile.
   * @param {string} shoppingListId - The ID of the shopping list.
   * @param {Object} updatedData - The data to update.
   */
  const updateShoppingList = useCallback(
    async (userId, profileId, shoppingListId, updatedData) => {
      try {
        if (!userId || !profileId || !shoppingListId || !updatedData) {
          throw new Error('Missing parameters for updateShoppingList');
        }
        const listDocRef = doc(
          db,
          `users/${userId}/profiles/${profileId}/shoppingLists/${shoppingListId}`
        );
        await updateDoc(listDocRef, updatedData);
        toast.success('Shopping list updated successfully!');
      } catch (error) {
        console.error('Error updating shopping list:', error);
        toast.error('Failed to update shopping list.');
        throw error;
      }
    },
    []
  );

  /**
   * Deletes a shopping list.
   * @param {string} userId - The ID of the user.
   * @param {string} profileId - The ID of the profile.
   * @param {string} shoppingListId - The ID of the shopping list to delete.
   */
  const deleteShoppingList = useCallback(
    async (userId, profileId, shoppingListId) => {
      try {
        if (!userId || !profileId || !shoppingListId) {
          throw new Error('Missing parameters for deleteShoppingList');
        }
        const listDocRef = doc(
          db,
          `users/${userId}/profiles/${profileId}/shoppingLists/${shoppingListId}`
        );
        await deleteDoc(listDocRef);
        toast.success('Shopping list deleted successfully!');
      } catch (error) {
        console.error('Error deleting shopping list:', error);
        toast.error('Failed to delete shopping list.');
        throw error;
      }
    },
    []
  );

  /**
   * Subscribes to real-time updates of shopping lists.
   * @param {string} userId - The ID of the user.
   * @param {string} profileId - The ID of the profile.
   * @param {Function} callback - The callback function to handle updates.
   * @returns {Function} - The unsubscribe function.
   */
  const subscribeToShoppingLists = useCallback(
    (userId, profileId, callback) => {
      if (!userId || !profileId || typeof callback !== 'function') {
        console.error('Missing or invalid parameters for subscribeToShoppingLists');
        toast.error('Unable to subscribe to shopping lists.');
        return;
      }

      const listsCollection = collection(
        db,
        `users/${userId}/profiles/${profileId}/shoppingLists`
      );
      const unsubscribe = onSnapshot(
        listsCollection,
        (snapshot) => {
          const lists = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          callback(lists);
        },
        (error) => {
          console.error('Error subscribing to shopping lists:', error);
          toast.error('Failed to subscribe to shopping lists.');
        }
      );

      return unsubscribe;
    },
    []
  );

  // ======= Items Management Functions =======

  /**
   * Fetches all items for a given shopping list.
   * @param {string} userId - The ID of the user.
   * @param {string} profileId - The ID of the profile.
   * @param {string} shoppingListId - The ID of the shopping list.
   * @returns {Promise<Array>} - An array of item objects.
   */
  const getItems = useCallback(
    async (userId, profileId, shoppingListId) => {
      try {
        if (!userId || !profileId || !shoppingListId) {
          throw new Error('Missing parameters for getItems');
        }
        const itemsCollection = collection(
          db,
          `users/${userId}/profiles/${profileId}/shoppingLists/${shoppingListId}/items`
        );
        const snapshot = await getDocs(itemsCollection);
        const items = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        return items;
      } catch (error) {
        console.error('Error fetching items:', error);
        toast.error('Failed to fetch items.');
        throw error;
      }
    },
    []
  );

  /**
   * Adds a new item to a shopping list.
   * @param {string} userId - The ID of the user.
   * @param {string} profileId - The ID of the profile.
   * @param {string} shoppingListId - The ID of the shopping list.
   * @param {Object} itemData - The data for the new item.
   * @returns {Promise<string>} - The ID of the newly created item.
   */
  const addItem = useCallback(
    async (userId, profileId, shoppingListId, itemData) => {
      try {
        if (!userId || !profileId || !shoppingListId || !itemData || !itemData.name) {
          throw new Error('Missing parameters for addItem');
        }
        const itemsCollection = collection(
          db,
          `users/${userId}/profiles/${profileId}/shoppingLists/${shoppingListId}/items`
        );
        const docRef = await addDoc(itemsCollection, {
          ...itemData,
          createdAt: itemData.createdAt || serverTimestamp(),
        });
        toast.success('Item added successfully!');
        return docRef.id;
      } catch (error) {
        console.error('Error adding item:', error);
        toast.error('Failed to add item.');
        throw error;
      }
    },
    []
  );

  /**
   * Updates an existing item.
   * @param {string} userId - The ID of the user.
   * @param {string} profileId - The ID of the profile.
   * @param {string} shoppingListId - The ID of the shopping list.
   * @param {string} itemId - The ID of the item to update.
   * @param {Object} updatedData - The data to update.
   */
  const updateItem = useCallback(
    async (userId, profileId, shoppingListId, itemId, updatedData) => {
      try {
        if (!userId || !profileId || !shoppingListId || !itemId || !updatedData) {
          throw new Error('Missing parameters for updateItem');
        }
        const itemDocRef = doc(
          db,
          `users/${userId}/profiles/${profileId}/shoppingLists/${shoppingListId}/items/${itemId}`
        );
        await updateDoc(itemDocRef, updatedData);
        toast.success('Item updated successfully!');
      } catch (error) {
        console.error('Error updating item:', error);
        toast.error('Failed to update item.');
        throw error;
      }
    },
    []
  );

  /**
   * Deletes an item from a shopping list.
   * @param {string} userId - The ID of the user.
   * @param {string} profileId - The ID of the profile.
   * @param {string} shoppingListId - The ID of the shopping list.
   * @param {string} itemId - The ID of the item to delete.
   */
  const deleteItem = useCallback(
    async (userId, profileId, shoppingListId, itemId) => {
      try {
        if (!userId || !profileId || !shoppingListId || !itemId) {
          throw new Error('Missing parameters for deleteItem');
        }
        const itemDocRef = doc(
          db,
          `users/${userId}/profiles/${profileId}/shoppingLists/${shoppingListId}/items/${itemId}`
        );
        await deleteDoc(itemDocRef);
        toast.success('Item deleted successfully!');
      } catch (error) {
        console.error('Error deleting item:', error);
        toast.error('Failed to delete item.');
        throw error;
      }
    },
    []
  );

  /**
   * Subscribes to real-time updates of items in a shopping list.
   * @param {string} userId - The ID of the user.
   * @param {string} profileId - The ID of the profile.
   * @param {string} shoppingListId - The ID of the shopping list.
   * @param {Function} callback - The callback function to handle updates.
   * @returns {Function} - The unsubscribe function.
   */
  const subscribeToItems = useCallback(
    (userId, profileId, shoppingListId, callback) => {
      if (
        !userId ||
        !profileId ||
        !shoppingListId ||
        typeof callback !== 'function'
      ) {
        console.error('Missing or invalid parameters for subscribeToItems');
        toast.error('Unable to subscribe to items.');
        return;
      }

      const itemsCollection = collection(
        db,
        `users/${userId}/profiles/${profileId}/shoppingLists/${shoppingListId}/items`
      );
      const unsubscribe = onSnapshot(
        itemsCollection,
        (snapshot) => {
          const items = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          callback(items);
        },
        (error) => {
          console.error('Error subscribing to items:', error);
          toast.error('Failed to subscribe to items.');
        }
      );

      return unsubscribe;
    },
    []
  );

  // ======= Savings Goals Management Functions =======

  /**
   * Adds a new savings goal.
   * @param {string} userId - The ID of the user.
   * @param {string} profileId - The ID of the profile.
   * @param {Object} goalData - The data for the new savings goal.
   * @returns {Promise<string>} - The ID of the newly created savings goal.
   */
  const addSavingsGoal = useCallback(
    async (userId, profileId, goalData) => {
      try {
        if (!userId || !profileId || !goalData || !goalData.goalName) {
          throw new Error('Missing parameters for addSavingsGoal');
        }
        const goalsCollection = collection(
          db,
          `users/${userId}/profiles/${profileId}/savingsGoals`
        );
        const docRef = await addDoc(goalsCollection, {
          ...goalData,
          createdAt: goalData.createdAt || serverTimestamp(),
        });
        toast.success('Savings goal added successfully!');
        return docRef.id;
      } catch (error) {
        console.error('Error adding savings goal:', error);
        toast.error('Failed to add savings goal.');
        throw error;
      }
    },
    []
  );

  /**
   * Fetches all savings goals for a given profile.
   * @param {string} userId - The ID of the user.
   * @param {string} profileId - The ID of the profile.
   * @returns {Promise<Array>} - An array of savings goal objects.
   */
  const getSavingsGoals = useCallback(
    async (userId, profileId) => {
      try {
        if (!userId || !profileId) {
          throw new Error('Missing parameters for getSavingsGoals');
        }
        const goalsCollection = collection(
          db,
          `users/${userId}/profiles/${profileId}/savingsGoals`
        );
        const snapshot = await getDocs(goalsCollection);
        const goals = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        return goals;
      } catch (error) {
        console.error('Error fetching savings goals:', error);
        toast.error('Failed to fetch savings goals.');
        throw error;
      }
    },
    []
  );

  /**
   * Updates an existing savings goal.
   * @param {string} userId - The ID of the user.
   * @param {string} profileId - The ID of the profile.
   * @param {string} goalId - The ID of the savings goal.
   * @param {Object} updatedData - The data to update.
   */
  const updateSavingsGoal = useCallback(
    async (userId, profileId, goalId, updatedData) => {
      try {
        if (!userId || !profileId || !goalId || !updatedData) {
          throw new Error('Missing parameters for updateSavingsGoal');
        }
        const goalDocRef = doc(
          db,
          `users/${userId}/profiles/${profileId}/savingsGoals/${goalId}`
        );
        await updateDoc(goalDocRef, updatedData);
        toast.success('Savings goal updated successfully!');
      } catch (error) {
        console.error('Error updating savings goal:', error);
        toast.error('Failed to update savings goal.');
        throw error;
      }
    },
    []
  );

  /**
   * Deletes a savings goal.
   * @param {string} userId - The ID of the user.
   * @param {string} profileId - The ID of the profile.
   * @param {string} goalId - The ID of the savings goal to delete.
   */
  const deleteSavingsGoal = useCallback(
    async (userId, profileId, goalId) => {
      try {
        if (!userId || !profileId || !goalId) {
          throw new Error('Missing parameters for deleteSavingsGoal');
        }
        const goalDocRef = doc(
          db,
          `users/${userId}/profiles/${profileId}/savingsGoals/${goalId}`
        );
        await deleteDoc(goalDocRef);
        toast.success('Savings goal deleted successfully!');
      } catch (error) {
        console.error('Error deleting savings goal:', error);
        toast.error('Failed to delete savings goal.');
        throw error;
      }
    },
    []
  );

  /**
   * Subscribes to real-time updates of savings goals.
   * @param {string} userId - The ID of the user.
   * @param {string} profileId - The ID of the profile.
   * @param {Function} callback - The callback function to handle updates.
   * @returns {Function} - The unsubscribe function.
   */
  const subscribeToSavingsGoals = useCallback(
    (userId, profileId, callback) => {
      if (!userId || !profileId || typeof callback !== 'function') {
        console.error('Missing or invalid parameters for subscribeToSavingsGoals');
        toast.error('Unable to subscribe to savings goals.');
        return;
      }

      const goalsCollection = collection(
        db,
        `users/${userId}/profiles/${profileId}/savingsGoals`
      );
      const unsubscribe = onSnapshot(
        goalsCollection,
        (snapshot) => {
          const goals = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          callback(goals);
        },
        (error) => {
          console.error('Error subscribing to savings goals:', error);
          toast.error('Failed to subscribe to savings goals.');
        }
      );

      return unsubscribe;
    },
    []
  );

  // ======= Coupons Management Functions =======

  /**
   * Adds a new coupon to the top-level 'coupons' collection.
   * @param {string} collectionName - The name of the top-level collection (e.g., 'coupons').
   * @param {Object} data - The data for the new coupon.
   * @returns {Promise<string>} - The ID of the newly created coupon.
   */
  const addTopLevelData = useCallback(async (collectionName, data) => {
    try {
      if (!collectionName || !data) {
        throw new Error('Missing parameters for addTopLevelData');
      }
      const collectionRef = collection(db, collectionName);
      const docRef = await addDoc(collectionRef, data);
      return docRef.id;
    } catch (error) {
      console.error(`Error adding document to ${collectionName}:`, error);
      toast.error(`Failed to add document to ${collectionName}.`);
      throw error;
    }
  }, []);

  /**
   * Deletes a document from a top-level collection.
   * @param {string} collectionName - The name of the top-level collection (e.g., 'coupons').
   * @param {string} docId - The ID of the document to delete.
   */
  const deleteTopLevelData = useCallback(async (collectionName, docId) => {
    try {
      if (!collectionName || !docId) {
        throw new Error('Missing parameters for deleteTopLevelData');
      }
      const docRef = doc(db, collectionName, docId);
      await deleteDoc(docRef);
      toast.success('Document deleted successfully!');
    } catch (error) {
      console.error(`Error deleting document ${docId} from ${collectionName}:`, error);
      toast.error(`Failed to delete document from ${collectionName}.`);
      throw error;
    }
  }, []);

  /**
   * Fetches all documents from a top-level collection.
   * @param {string} collectionName - The name of the top-level collection (e.g., 'coupons').
   * @returns {Promise<Array>} - An array of document objects.
   */
  const getTopLevelData = useCallback(async (collectionName) => {
    try {
      if (!collectionName) {
        throw new Error('Missing collectionName for getTopLevelData');
      }
      const collectionRef = collection(db, collectionName);
      const snapshot = await getDocs(collectionRef);
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return data;
    } catch (error) {
      console.error(`Error fetching documents from ${collectionName}:`, error);
      toast.error(`Failed to fetch documents from ${collectionName}.`);
      throw error;
    }
  }, []);

  /**
   * Subscribes to real-time updates of a top-level collection.
   * @param {string} collectionName - The name of the top-level collection (e.g., 'coupons').
   * @param {Function} callback - The callback function to handle updates.
   * @returns {Function} - The unsubscribe function.
   */
  const listenToTopLevelData = useCallback((collectionName, callback) => {
    if (!collectionName || typeof callback !== 'function') {
      console.error('Missing or invalid parameters for listenToTopLevelData');
      toast.error('Unable to subscribe to top-level data.');
      return;
    }

    const collectionRef = collection(db, collectionName);
    const unsubscribe = onSnapshot(
      collectionRef,
      (snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        callback(data);
      },
      (error) => {
        console.error(`Error subscribing to ${collectionName}:`, error);
        toast.error(`Failed to subscribe to ${collectionName}.`);
      }
    );

    return unsubscribe;
  }, []);

  // ======= General Data Functions =======

  /**
   * Adds data to a nested collection under a user's profile.
   * @param {string} userId - The ID of the user.
   * @param {string} profileId - The ID of the profile.
   * @param {string} collectionName - The name of the collection.
   * @param {Object} data - The data to add.
   * @returns {Promise<string>} - The ID of the newly created document.
   */
  const addData = useCallback(async (userId, profileId, collectionName, data) => {
    try {
      if (!userId || !profileId || !collectionName || !data) {
        throw new Error('Missing parameters for addData');
      }
      const collectionRef = collection(
        db,
        `users/${userId}/profiles/${profileId}/${collectionName}`
      );
      const docRef = await addDoc(collectionRef, data);
      return docRef.id;
    } catch (error) {
      console.error(`Error adding data to ${collectionName}:`, error);
      toast.error(`Failed to add data to ${collectionName}.`);
      throw error;
    }
  }, []);

  /**
   * Updates data in a nested collection under a user's profile.
   * @param {string} userId - The ID of the user.
   * @param {string} profileId - The ID of the profile.
   * @param {string} collectionName - The name of the collection.
   * @param {string} docId - The ID of the document to update.
   * @param {Object} data - The data to update.
   */
  const updateData = useCallback(
    async (userId, profileId, collectionName, docId, data) => {
      try {
        if (!userId || !profileId || !collectionName || !docId || !data) {
          throw new Error('Missing parameters for updateData');
        }
        const docRef = doc(
          db,
          `users/${userId}/profiles/${profileId}/${collectionName}/${docId}`
        );
        await updateDoc(docRef, data);
        toast.success('Data updated successfully!');
      } catch (error) {
        console.error(`Error updating data in ${collectionName}:`, error);
        toast.error(`Failed to update data in ${collectionName}.`);
        throw error;
      }
    },
    []
  );

  /**
   * Deletes data from a nested collection under a user's profile.
   * @param {string} userId - The ID of the user.
   * @param {string} profileId - The ID of the profile.
   * @param {string} collectionName - The name of the collection.
   * @param {string} docId - The ID of the document to delete.
   */
  const deleteData = useCallback(
    async (userId, profileId, collectionName, docId) => {
      try {
        if (!userId || !profileId || !collectionName || !docId) {
          throw new Error('Missing parameters for deleteData');
        }
        const docRef = doc(
          db,
          `users/${userId}/profiles/${profileId}/${collectionName}/${docId}`
        );
        await deleteDoc(docRef);
        toast.success('Data deleted successfully!');
      } catch (error) {
        console.error(`Error deleting data from ${collectionName}:`, error);
        toast.error(`Failed to delete data from ${collectionName}.`);
        throw error;
      }
    },
    []
  );

  /**
   * Retrieves data from a specific document using dynamic path segments.
   * @param {...string} pathSegments - The path segments leading to the document.
   * @returns {Promise<Object|null>} - The document data or null if not found.
   */
  const getData = useCallback(
    async (...pathSegments) => {
      try {
        if (!pathSegments || pathSegments.length === 0) {
          throw new Error('Missing parameters for getData');
        }
        const docRef = doc(db, ...pathSegments);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          return docSnap.data();
        } else {
          console.error('No such document:', pathSegments.join('/'));
          return null;
        }
      } catch (error) {
        console.error(`Error getting data from ${pathSegments.join('/')}:`, error);
        toast.error(`Failed to get data from ${pathSegments.join('/')}.`);
        throw error;
      }
    },
    []
  );

  // ======= General Subscription Function =======

  /**
   * Subscribes to real-time updates of a nested collection under a user's profile.
   * @param {Array<string>} pathSegments - The path segments leading to the collection.
   * @param {Function} callback - The callback function to handle updates.
   * @returns {Function} - The unsubscribe function.
   */
  const subscribeToData = useCallback((pathSegments, callback) => {
    if (!Array.isArray(pathSegments) || typeof callback !== 'function') {
      console.error('Invalid parameters for subscribeToData');
      toast.error('Unable to subscribe to data.');
      return;
    }

    const collectionPath = pathSegments.join('/');
    const dataCollection = collection(db, collectionPath);

    const unsubscribe = onSnapshot(
      dataCollection,
      (snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        callback(data);
      },
      (error) => {
        console.error(`Error subscribing to ${collectionPath}:`, error);
        toast.error('Failed to subscribe to data.');
      }
    );

    return unsubscribe;
  }, []);

  // ======= Badge Management Functions =======

  /**
   * Subscribes to real-time updates of badges for a specific month and year.
   * @param {string} userId - The ID of the user.
   * @param {string} profileId - The ID of the profile.
   * @param {string} monthYear - The month and year in 'YYYY-MM' format.
   * @param {Function} callback - The callback function to handle badge updates.
   * @returns {Function} - The unsubscribe function.
   */
  const subscribeToBadges = useCallback(
    (userId, profileId, monthYear, callback) => {
      if (!userId || !profileId || !monthYear || typeof callback !== 'function') {
        console.error('Missing or invalid parameters for subscribeToBadges');
        toast.error('Unable to subscribe to badges.');
        return;
      }

      const badgesRef = collection(db, `users/${userId}/profiles/${profileId}/badges`);
      const badgesQuery = query(badgesRef, where('month', '==', monthYear));

      const unsubscribe = onSnapshot(
        badgesQuery,
        (snapshot) => {
          const badges = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          callback(badges);
        },
        (error) => {
          console.error('Error subscribing to badges:', error);
          toast.error('Failed to subscribe to badges.');
        }
      );

      return unsubscribe;
    },
    []
  );

  /**
   * Adds a new badge to Firestore.
   * @param {string} userId - The ID of the user.
   * @param {string} profileId - The ID of the profile.
   * @param {Object} badgeData - The data for the badge.
   * @returns {Promise<string>} - The ID of the newly created badge.
   */
  const addBadge = useCallback(
    async (userId, profileId, badgeData) => {
      try {
        if (!userId || !profileId || !badgeData || !badgeData.id || !badgeData.month) {
          throw new Error('Missing parameters for addBadge');
        }

        const badgeDocRef = doc(
          db,
          `users/${userId}/profiles/${profileId}/badges/${badgeData.id}`
        );
        const dataWithTimestamp = {
          ...badgeData,
          earnedAt: serverTimestamp(),
        };

        await setDoc(badgeDocRef, dataWithTimestamp, { merge: true });
        toast.success('Badge added successfully!');
        return badgeData.id;
      } catch (error) {
        console.error(`Error adding badge ${badgeData.id}:`, error);
        toast.error('Failed to add badge.');
        throw error;
      }
    },
    []
  );

  /**
   * Deletes a badge from Firestore.
   * @param {string} userId - The ID of the user.
   * @param {string} profileId - The ID of the profile.
   * @param {string} badgeId - The ID of the badge to delete.
   * @returns {Promise<void>}
   */
  const deleteBadge = useCallback(
    async (userId, profileId, badgeId) => {
      try {
        if (!userId || !profileId || !badgeId) {
          throw new Error('Missing parameters for deleteBadge');
        }

        const badgeDocRef = doc(
          db,
          `users/${userId}/profiles/${profileId}/badges/${badgeId}`
        );
        await deleteDoc(badgeDocRef);
        toast.success('Badge deleted successfully!');
      } catch (error) {
        console.error(`Error deleting badge ${badgeId}:`, error);
        toast.error('Failed to delete badge.');
        throw error;
      }
    },
    []
  );

  // ======= Utility Functions =======

  /**
   * Retrieves the current server timestamp.
   * @returns {FieldValue} - The server timestamp.
   */
  const getCurrentTimestamp = useCallback(() => {
    return serverTimestamp();
  }, []);

  // ======= Return Service Functions =======

  const serviceFunctions = useMemo(
    () => ({
      // Profile Management
      getProfiles,
      addProfile,
      updateProfile,
      deleteProfile,
      subscribeToProfiles,

      // Shopping List Management
      getShoppingLists,
      addShoppingList,
      updateShoppingList,
      deleteShoppingList,
      subscribeToShoppingLists,

      // Items Management
      getItems,
      addItem,
      updateItem,
      deleteItem,
      subscribeToItems,

      // Savings Goals Management
      addSavingsGoal,
      getSavingsGoals,
      updateSavingsGoal,
      deleteSavingsGoal,
      subscribeToSavingsGoals,

      // General Data Functions
      addData,
      updateData,
      deleteData,
      getData,

      // General Subscription Function
      subscribeToData,

      // Coupons Management
      addTopLevelData,
      deleteTopLevelData,
      getTopLevelData,
      listenToTopLevelData,

      // Badge Management
      subscribeToBadges,
      addBadge,
      deleteBadge,

      // Utility
      getCurrentTimestamp,
      fieldDelete: deleteField, // Use deleteField directly
    }),
    [
      // Profile Management
      getProfiles,
      addProfile,
      updateProfile,
      deleteProfile,
      subscribeToProfiles,

      // Shopping List Management
      getShoppingLists,
      addShoppingList,
      updateShoppingList,
      deleteShoppingList,
      subscribeToShoppingLists,

      // Items Management
      getItems,
      addItem,
      updateItem,
      deleteItem,
      subscribeToItems,

      // Savings Goals Management
      addSavingsGoal,
      getSavingsGoals,
      updateSavingsGoal,
      deleteSavingsGoal,
      subscribeToSavingsGoals,

      // General Data Functions
      addData,
      updateData,
      deleteData,
      getData,

      // General Subscription Function
      subscribeToData,

      // Coupons Management
      addTopLevelData,
      deleteTopLevelData,
      getTopLevelData,
      listenToTopLevelData,

      // Badge Management
      subscribeToBadges,
      addBadge,
      deleteBadge,

      // Utility
      getCurrentTimestamp,
    ]
  );

  return serviceFunctions;
};
