// src/components/PortfolioChart.js

import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

const PortfolioChart = ({ data }) => {
  if (!data || data.length === 0) {
    return <p className="text-center text-gray-500">No data to display.</p>;
  }

  return (
    <div style={{ width: '100%', height: 400 }}>
      <ResponsiveContainer>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            tick={{ fill: '#4A5568' }} // Dark gray ticks
            tickFormatter={(tick) => {
              // Format X-axis labels based on date format
              return tick.length === 5 ? tick : tick; // Adjust as needed
            }}
          />
          <YAxis
            tick={{ fill: '#4A5568' }}
            tickFormatter={(value) => `$${value.toFixed(2)}`}
          />
          <Tooltip
            formatter={(value) => `$${value.toFixed(2)}`}
            labelFormatter={(label) => `Date: ${label}`}
          />
          <Line
            type="monotone"
            dataKey="totalValue"
            stroke="#3182CE" // Blue color
            strokeWidth={2}
            dot={false}
            activeDot={{ r: 4 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PortfolioChart;
