// src/contexts/SidebarContext.js

import React, { createContext, useState, useContext } from 'react';

// Create SidebarContext
export const SidebarContext = createContext();

// SidebarProvider Component
export const SidebarProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Sidebar visibility
  const [isCollapsed, setIsCollapsed] = useState(false);     // Sidebar collapse state

  // Toggle sidebar visibility
  const toggleSidebar = () => {
    setIsSidebarOpen(prevState => !prevState);
    console.log(`Sidebar is now ${!isSidebarOpen ? 'open' : 'closed'}`);
  };

  // Toggle sidebar collapse
  const toggleCollapse = () => {
    setIsCollapsed(prevState => !prevState);
    console.log(`Sidebar is now ${!isCollapsed ? 'collapsed' : 'expanded'}`);
  };

  return (
    <SidebarContext.Provider value={{ isSidebarOpen, toggleSidebar, isCollapsed, toggleCollapse }}>
      {children}
    </SidebarContext.Provider>
  );
};

// Custom Hook to Consume SidebarContext
export const useSidebar = () => {
  return useContext(SidebarContext);
};
