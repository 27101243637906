// src/components/PersonalizedTips.js

import React, { useState } from 'react';
import { FiAlertCircle, FiXCircle, FiSmile } from 'react-icons/fi';
import Slider from 'react-slick';

// Import CSS for react-slick (if not imported globally)
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Define the financial best-practice rules
const rules = [
  // Negative Tips (Alerts)
  {
    id: 'highSubscriptions',
    type: 'alert',
    condition: (data) => {
      const totalSubscriptions = data.expenses
        .filter((expense) => expense.category.toLowerCase() === 'subscriptions')
        .reduce((sum, expense) => sum + expense.totalAmount, 0);
      return totalSubscriptions > data.income * 0.1;
    },
    message:
      'Your subscription expenses exceed 10% of your income. Consider reviewing and reducing unnecessary subscriptions.',
  },
  {
    id: 'lowSavingsRate',
    type: 'alert',
    condition: (data) => {
      const totalAmountSaved = data.savingsGoals.reduce(
        (sum, goal) => sum + (goal.amountSaved || 0),
        0
      );
      const savingsRate = totalAmountSaved / data.income;
      return savingsRate < 0.2;
    },
    message:
      'Your savings rate is below 20% of your income. Increasing your savings can help build a financial cushion.',
  },
  {
    id: 'highDebtPayments',
    type: 'alert',
    condition: (data) => {
      const totalDebtPayments = data.expenses
        .filter((expense) => expense.category.toLowerCase() === 'debt')
        .reduce((sum, expense) => sum + expense.totalAmount, 0);
      return totalDebtPayments > data.income * 0.3;
    },
    message:
      'Your debt payments exceed 30% of your income. Consider strategies to reduce your debt load.',
  },
  {
    id: 'exceedingBudget',
    type: 'alert',
    condition: (data) => {
      return data.totalExpenses > data.income;
    },
    message:
      'Your expenses exceed your income. Review your expenses to identify areas where you can cut back.',
  },
  {
    id: 'lowEmergencyFund',
    type: 'alert',
    condition: (data) => {
      const emergencyFundGoal = data.savingsGoals.find(
        (goal) => goal.goalName.toLowerCase() === 'emergency fund'
      );
      if (!emergencyFundGoal) return true;
      return emergencyFundGoal.amountSaved < data.income * 3;
    },
    message:
      'Consider building an emergency fund equal to at least 3 months of your income for unforeseen expenses.',
  },
  {
    id: 'highHousingCosts',
    type: 'alert',
    condition: (data) => {
      const housingExpenses = data.expenses
        .filter((expense) => expense.category.toLowerCase() === 'housing')
        .reduce((sum, expense) => sum + expense.totalAmount, 0);
      return housingExpenses > data.income * 0.3;
    },
    message:
      'Your housing costs exceed 30% of your income. Consider options to reduce housing expenses.',
  },
  // Positive Affirmations
  {
    id: 'excellentSavingsRate',
    type: 'affirmation',
    condition: (data) => {
      const totalAmountSaved = data.savingsGoals.reduce(
        (sum, goal) => sum + (goal.amountSaved || 0),
        0
      );
      const savingsRate = totalAmountSaved / data.income;
      return savingsRate >= 0.2;
    },
    message:
      'Fantastic job! You are saving over 20% of your income. Keep up the great work towards your financial goals!',
  },
  {
    id: 'onTrackWithBudget',
    type: 'affirmation',
    condition: (data) => {
      return data.totalExpenses <= data.income;
    },
    message:
      'Great work staying within your budget this month. Your disciplined approach is paying off!',
  },
  {
    id: 'lowDebt',
    type: 'affirmation',
    condition: (data) => {
      const totalDebtPayments = data.expenses
        .filter((expense) => expense.category.toLowerCase() === 'debt')
        .reduce((sum, expense) => sum + expense.totalAmount, 0);
      return totalDebtPayments <= data.income * 0.1;
    },
    message:
      'Well done maintaining low debt payments! This gives you more flexibility with your finances.',
  },
  {
    id: 'goalAchieved',
    type: 'affirmation',
    condition: (data) => {
      return data.savingsGoals.some(
        (goal) => goal.amountSaved >= goal.goalAmount
      );
    },
    message:
      'Congratulations on achieving one of your savings goals! Celebrate your success and set new ones.',
  },
  // Generalized Tips (Shown to all users)
  {
    id: 'tipBudgetReview',
    type: 'general',
    condition: () => true,
    message:
      'Regularly reviewing your budget helps you stay on track with your financial goals.',
  },
  {
    id: 'tipEmergencyFund',
    type: 'general',
    condition: () => true,
    message:
      'Building an emergency fund can provide peace of mind against unexpected expenses.',
  },
  {
    id: 'tipInvesting',
    type: 'general',
    condition: () => true,
    message:
      'Consider investing a portion of your savings to grow your wealth over time.',
  },
  {
    id: 'tipRetirement',
    type: 'general',
    condition: () => true,
    message:
      'Planning for retirement early can significantly impact your financial future.',
  },
  {
    id: 'tipDebtManagement',
    type: 'general',
    condition: () => true,
    message:
      'Managing and reducing debt can free up resources for savings and investments.',
  },
];

const PersonalizedTips = ({ financialData }) => {
  const [dismissedTips, setDismissedTips] = useState([]);

  const handleDismiss = (tipId) => {
    setDismissedTips([...dismissedTips, tipId]);
  };

  // Combine all tips into a single array and filter
  const allTips = rules
    .filter((rule) => rule.condition(financialData))
    .filter((rule) => !dismissedTips.includes(rule.id));

  // Generate tip components
  const tipComponents = allTips.map((tip) => {
    let icon, heading;

    if (tip.type === 'affirmation') {
      icon = (
        <FiSmile
          className="text-green-500 w-6 h-6 mt-1 mr-3"
          aria-hidden="true"
        />
      );
      heading = 'Great Job!';
    } else if (tip.type === 'alert') {
      icon = (
        <FiAlertCircle
          className="text-red-500 w-6 h-6 mt-1 mr-3"
          aria-hidden="true"
        />
      );
      heading = 'Attention Needed';
    } else {
      icon = (
        <FiSmile
          className="text-indigo-500 w-6 h-6 mt-1 mr-3"
          aria-hidden="true"
        />
      );
      heading = 'Financial Tip';
    }

    return (
      <div key={tip.id} className="p-6">
        <h3 className="text-xl font-semibold mb-4">{heading}</h3>
        <div className="flex items-start">
          {icon}
          <p className="text-gray-700 flex-grow">{tip.message}</p>
          <button
            onClick={() => handleDismiss(tip.id)}
            className="text-gray-400 hover:text-gray-600 ml-2"
            aria-label="Dismiss Tip"
          >
            <FiXCircle className="w-5 h-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    );
  });

  // Slider settings with autoplay enabled and a reasonable timeframe
  const sliderSettings = {
    dots: true,
    infinite: tipComponents.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: tipComponents.length > 1,
    autoplaySpeed: 5000, // 5000 milliseconds = 5 seconds
    arrows: true,
  };

  // If no tips are available
  if (tipComponents.length === 0) {
    return (
      <div className="bg-white p-6 rounded-lg shadow-md">
        <p className="text-green-600">
          Great job! Your finances are in excellent shape.
        </p>
      </div>
    );
  }

  return (
    <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md">
      <Slider {...sliderSettings}>
        {tipComponents.map((tip) => (
          <div key={tip.key}>{tip}</div>
        ))}
      </Slider>
    </div>
  );
};

export default PersonalizedTips;
