// src/components/ViewBudget.js

import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import {
  startOfMonth,
  endOfMonth,
  addWeeks,
  addMonths,
  addYears,
} from 'date-fns';
import { useFirebaseService } from '../services/firebaseService';
import { ProfileContext } from '../contexts/ProfileContext';
import { AuthContext } from '../contexts/AuthContext';

import DataExport from './DataExport';
import DataImport from './DataImport';
import IncomeForm from './IncomeForm';
import ExpenseForm from './ExpenseForm';
import SavingsGoalForm from './SavingsGoalForm';
import PersonalizedTips from './PersonalizedTips'; // Import the PersonalizedTips component

import Modal from 'react-modal';

import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

// **Import useNavigate for navigation**
import { useNavigate } from 'react-router-dom';

// **Ensure Doughnut is imported since it's used in Category Spending Breakdown**
import { Doughnut } from 'react-chartjs-2';

Modal.setAppElement('#root');

const ViewBudget = () => {
  // Contexts
  const { user } = useContext(AuthContext);
  const { activeProfileId, profilesLoading } = useContext(ProfileContext);

  // Initialize navigate
  const navigate = useNavigate();

  // State variables
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [expenseData, setExpenseData] = useState([]);
  const [incomeData, setIncomeData] = useState([]);
  const currentDate = new Date();
  const currentMonth = (currentDate.getMonth() + 1).toString(); // "1" to "12"
  const currentYear = currentDate.getFullYear().toString(); // e.g., "2024"

  const [filterMonth, setFilterMonth] = useState(currentMonth);
  const [filterYear, setFilterYear] = useState(currentYear);
  const [editMode, setEditMode] = useState({});

  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  // State variables for Income and Expense Modals
  const [isIncomeModalOpen, setIsIncomeModalOpen] = useState(false);
  const [isExpenseModalOpen, setIsExpenseModalOpen] = useState(false);

  // State variables for Savings Goal Modal
  const [isSavingsGoalModalOpen, setIsSavingsGoalModalOpen] = useState(false);
  const [savingsGoals, setSavingsGoals] = useState([]);

  // State for Contribution Modal
  const [isContributionModalOpen, setIsContributionModalOpen] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState(null);

  // Firebase Service Hook
  const firebaseService = useFirebaseService();

  // Expanded color palette for categories
  const categoryColors = [
    '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
    '#FF9F40', '#FF5733', '#33FF57', '#3357FF', '#FF33A1',
    '#33FFA1', '#A133FF', '#FFA133',
  ];

  // Function to calculate occurrences of a transaction in a specific month and year
  const getOccurrencesInMonth = useCallback((transaction, year, month) => {
    const occurrences = [];
    const transactionDate = transaction.date ? new Date(transaction.date) : null;
    if (!transactionDate || isNaN(transactionDate)) return occurrences;

    const startDate = startOfMonth(new Date(year, month - 1));
    const endDate = endOfMonth(startDate);

    if (!transaction.isRecurring || !transaction.recurrenceFrequency) {
      // Non-recurring transaction
      if (
        transactionDate.getMonth() + 1 === Number(month) &&
        transactionDate.getFullYear() === Number(year)
      ) {
        occurrences.push(transactionDate);
      }
    } else {
      // Recurring transaction
      let currentDate = new Date(transactionDate);
      const frequency = transaction.recurrenceFrequency;

      // Adjust currentDate to the first occurrence on or after startDate
      while (currentDate < startDate) {
        if (frequency === 'weekly') {
          currentDate = addWeeks(currentDate, 1);
        } else if (frequency === 'bi-weekly') {
          currentDate = addWeeks(currentDate, 2);
        } else if (frequency === 'monthly') {
          currentDate = addMonths(currentDate, 1);
        } else if (frequency === 'yearly') {
          currentDate = addYears(currentDate, 1);
        }

        if (transaction.recurrenceEndDate && currentDate > new Date(transaction.recurrenceEndDate)) {
          break;
        }
      }

      // Now, collect occurrences within the month
      while (currentDate <= endDate) {
        if (currentDate >= startDate && currentDate <= endDate) {
          occurrences.push(new Date(currentDate));
        }

        // Add frequency interval
        if (frequency === 'weekly') {
          currentDate = addWeeks(currentDate, 1);
        } else if (frequency === 'bi-weekly') {
          currentDate = addWeeks(currentDate, 2);
        } else if (frequency === 'monthly') {
          currentDate = addMonths(currentDate, 1);
        } else if (frequency === 'yearly') {
          currentDate = addYears(currentDate, 1);
        }

        if (transaction.recurrenceEndDate && currentDate > new Date(transaction.recurrenceEndDate)) {
          break;
        }
      }
    }

    return occurrences;
  }, []);

  // Function to aggregate expenses by category
  const aggregateExpensesByCategory = (expenses) => {
    const categoryTotals = {};

    expenses.forEach((expense) => {
      // Safety Check: Ensure expense.category exists and is a string
      const category = typeof expense.category === 'string' ? expense.category.trim().toLowerCase() : 'uncategorized';
      const totalAmount = expense.totalAmount;

      // If the category already exists, add to its total; otherwise, initialize it
      if (categoryTotals[category]) {
        categoryTotals[category] += totalAmount;
      } else {
        categoryTotals[category] = totalAmount;
      }
    });

    return categoryTotals;
  };

  // Function to aggregate incomes by category with safety checks
  const aggregateIncomesByCategory = (incomes) => {
    const categoryTotals = {};

    incomes.forEach((income) => {
      // Safety Check: Ensure income.category exists and is a string
      const category =
        typeof income.category === 'string'
          ? income.category.trim().toLowerCase()
          : 'uncategorized';

      const totalAmount = income.totalAmount;

      // If the category already exists, add to its total; otherwise, initialize it
      if (categoryTotals[category]) {
        categoryTotals[category] += totalAmount;
      } else {
        categoryTotals[category] = totalAmount;
      }
    });

    return categoryTotals;
  };

  // Options for the Doughnut chart with improved tooltip
  const chartOptions = {
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const data = tooltipItem.raw;
            const total = tooltipItem.chart.data.datasets[0].data.reduce((acc, value) => acc + value, 0);
            const percentage = ((data / total) * 100).toFixed(2);
            return `${tooltipItem.label}: $${data} (${percentage}%)`;
          },
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
  };

  // Memoize aggregated category totals to prevent unnecessary recalculations
  const aggregatedCategoryTotals = useMemo(() => aggregateExpensesByCategory(expenseData), [expenseData]);

  // Memoize aggregated income totals to prevent unnecessary recalculations
  // Removed 'aggregatedIncomeTotals' since it's not used to fix the 'no-unused-vars' warning

  // Effect: Subscribe to Incomes, Expenses, Savings Goals
  useEffect(() => {
    // Prevent subscription if profiles are loading or contexts are unavailable
    if (profilesLoading || !firebaseService || !activeProfileId || !user) {
      console.warn(
        'ViewBudget - Cannot subscribe to data. Profiles loading or missing context values.'
      );
      return;
    }

    // Subscribe to Incomes
    const unsubscribeIncome = firebaseService.subscribeToData(
      ['users', user.uid, 'profiles', activeProfileId, 'incomes'],
      (data) => {
        console.log('Received incomes:', data);
        const incomeArray = data
          .map((income) => {
            const occurrences = getOccurrencesInMonth(income, filterYear, filterMonth);
            const totalAmount = occurrences.length * parseFloat(income.amount || 0);
            return {
              ...income,
              occurrences,
              totalAmount,
              occurrenceDates: occurrences.map((date) => date.toLocaleDateString()), // Adding formatted dates
            };
          })
          .filter((income) => income.totalAmount > 0);
        setIncomeData(incomeArray);
        const totalIncomeAmount = incomeArray.reduce((acc, inc) => acc + inc.totalAmount, 0);
        setTotalIncome(totalIncomeAmount);
      }
    );

    // Subscribe to Expenses
    const unsubscribeExpenses = firebaseService.subscribeToData(
      ['users', user.uid, 'profiles', activeProfileId, 'expenses'],
      (data) => {
        console.log('Received expenses:', data);
        const expenseArray = data
          .map((expense) => {
            const occurrences = getOccurrencesInMonth(expense, filterYear, filterMonth);
            const totalAmount = occurrences.length * parseFloat(expense.amount || 0);

            // Get the amountPaid for the current month
            const monthKey = `${filterYear}-${filterMonth.padStart(2, '0')}`;
            const amountPaid = expense.payments && expense.payments[monthKey] ? expense.payments[monthKey] : 0;

            // Explicitly set amountPaid to the per-month value and remove any global amountPaid
            return {
              ...expense,
              amountPaid, // Override with per-month amountPaid
              totalAmount,
              occurrences,
            };
          })
          .filter((expense) => expense.totalAmount > 0);
        setExpenseData(expenseArray);
        const totalExpenseAmount = expenseArray.reduce((acc, exp) => acc + exp.totalAmount, 0);
        setTotalExpenses(totalExpenseAmount);
      }
    );

    // Subscribe to Savings Goals
    const unsubscribeSavingsGoals = firebaseService.subscribeToData(
      ['users', user.uid, 'profiles', activeProfileId, 'savingsGoals'],
      (data) => {
        console.log('Received savings goals:', data);
        setSavingsGoals(data);
      }
    );

    // Cleanup subscriptions on unmount or when dependencies change
    return () => {
      if (typeof unsubscribeIncome === 'function') {
        unsubscribeIncome();
        console.log('Unsubscribed from incomes data.');
      }
      if (typeof unsubscribeExpenses === 'function') {
        unsubscribeExpenses();
        console.log('Unsubscribed from expenses data.');
      }
      if (typeof unsubscribeSavingsGoals === 'function') {
        unsubscribeSavingsGoals();
        console.log('Unsubscribed from savings goals data.');
      }
    };
  }, [
    firebaseService,
    activeProfileId,
    profilesLoading,
    user,
    filterMonth,
    filterYear,
    getOccurrencesInMonth,
  ]);

  // Function to calculate savings progress for each goal
  const calculateSavingsProgress = (goal) => {
    if (!goal.goalAmount) return 0;
    const progress = ((goal.amountSaved || 0) / goal.goalAmount) * 100;
    return progress > 100 ? 100 : progress;
  };

  // Function to calculate progress percentage
  const calculateProgress = (amountPaid, totalAmount) => {
    if (!totalAmount) return 0;
    const progress = ((amountPaid / totalAmount) * 100).toFixed(2);
    return Number(progress);
  };

  // Function to handle updating the amount paid
  const handleAmountPaidUpdate = async (id, newAmountPaid, totalAmount) => {
    if (!id || typeof id !== 'string') {
      console.error('Expense ID is invalid:', id);
      toast.error('Invalid expense ID. Cannot update payment.');
      return;
    }

    if (!firebaseService || !activeProfileId || !user || !user.uid) {
      console.error('Missing context values:', { firebaseService, activeProfileId, user });
      toast.error('Cannot update payment due to missing data.');
      return;
    }

    const parsedAmount = parseFloat(newAmountPaid);

    if (isNaN(parsedAmount) || parsedAmount < 0) {
      toast.error('Please enter a valid contribution amount.');
      return;
    }

    if (parsedAmount > totalAmount) {
      const confirmOverBudget = window.confirm(
        `The amount paid ($${parsedAmount.toFixed(2)}) exceeds the total budget for this expense ($${totalAmount.toFixed(
          2
        )}). Do you want to proceed?`
      );
      if (!confirmOverBudget) {
        return;
      }
    }

    const monthKey = `${filterYear}-${filterMonth.padStart(2, '0')}`;

    try {
      // Update the payments object for the specific month
      const expenseRef = ['users', user.uid, 'profiles', activeProfileId, 'expenses', id];

      // Fetch the existing payments object
      const expenseDataFetched = await firebaseService.getData(...expenseRef);
      if (!expenseDataFetched) {
        console.error('No expense data found for ID:', id);
        toast.error('Expense not found. Cannot update payment.');
        return;
      }

      const existingPayments = expenseDataFetched.payments || {};

      // Update the payments object
      const updatedPayments = {
        ...existingPayments,
        [monthKey]: parsedAmount,
      };

      // Update the expense with the new payments object and remove global amountPaid
      await firebaseService.updateData(
        user.uid,
        activeProfileId,
        'expenses',
        id,
        {
          payments: updatedPayments,
          amountPaid: firebaseService.fieldDelete(), // Remove global amountPaid
        }
      );

      setEditMode((prevState) => ({ ...prevState, [id]: false }));
      toast.success('Amount paid updated successfully!');

      // Check for gamification achievements after updating expenses
      checkGamificationAchievements();
    } catch (error) {
      console.error('Error updating amount paid:', error);
      toast.error('Failed to update amount paid.');
    }
  };

  // Function to handle toggling the edit mode for an entry
  const handleEditToggle = (id) => {
    setEditMode((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };

  // Function to handle checkbox change
  const handleCheckboxChange = (id, isChecked, totalAmount) => {
    const newAmountPaid = isChecked ? totalAmount : 0;
    handleAmountPaidUpdate(id, newAmountPaid, totalAmount);
  };

  // Generate options for months
  const monthOptionsList = [
    { value: '1', label: 'January' },
    { value: '2', label: 'February' },
    { value: '3', label: 'March' },
    { value: '4', label: 'April' },
    { value: '5', label: 'May' },
    { value: '6', label: 'June' },
    { value: '7', label: 'July' },
    { value: '8', label: 'August' },
    { value: '9', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
  ];

  // Generate options for years
  const yearOptionsList = [];
  for (let year = Number(currentYear) - 5; year <= Number(currentYear) + 5; year++) {
    yearOptionsList.push(
      <option key={year} value={year.toString()}>
        {year}
      </option>
    );
  }

  // Aggregate expenses by category
  // Already memoized as aggregatedCategoryTotals

  // Aggregate incomes by category with safety checks
  // Already memoized as aggregatedIncomeTotals
  // Removed to fix the 'no-unused-vars' warning

  // Generate labels and data arrays for the expense chart
  const expenseLabels = Object.keys(aggregatedCategoryTotals);
  const expenseDataValues = Object.values(aggregatedCategoryTotals);

  // Updated categorySpendingData for the expense chart
  const categorySpendingData = {
    labels: expenseLabels,
    datasets: [
      {
        label: 'Spending Breakdown',
        data: expenseDataValues,
        backgroundColor: categoryColors.slice(0, expenseLabels.length),
        hoverOffset: 6,
      },
    ],
  };

  // Function to open Contribution Modal
  const openContributionModal = (goal) => {
    setSelectedGoal(goal);
    setIsContributionModalOpen(true);
  };

  // Function to close Contribution Modal
  const closeContributionModal = () => {
    setSelectedGoal(null);
    setIsContributionModalOpen(false);
  };

  // Form handling for Contribution
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const onSubmitContribution = async (data) => {
    const contributionAmount = parseFloat(data.amount);

    if (isNaN(contributionAmount) || contributionAmount <= 0) {
      toast.error('Please enter a valid positive amount.');
      return;
    }

    // Update the amountSaved in Firebase (set to new value)
    try {
      await firebaseService.updateSavingsGoal(
        user.uid,
        activeProfileId,
        selectedGoal.id,
        { amountSaved: contributionAmount }
      );

      // Check if goal is met or exceeded
      if (contributionAmount >= selectedGoal.goalAmount && (selectedGoal.amountSaved || 0) < selectedGoal.goalAmount) {
        toast.success(`Congratulations! You've met your savings goal: ${selectedGoal.goalName}! 🎉`);
      } else {
        toast.success(`Successfully updated ${selectedGoal.goalName} to $${contributionAmount.toFixed(2)}!`);
      }

      // Check for gamification achievements
      checkGamificationAchievements();

      closeContributionModal();
      reset();
    } catch (error) {
      console.error('Error contributing to savings goal:', error);
      toast.error('Failed to contribute to savings goal.');
    }
  };

  // Function to check gamification achievements
  const [badges, setBadges] = useState([]);

  const checkGamificationAchievements = useCallback(() => {
    const newBadges = [];

    // Calculate total income and total expenses for percentage-based conditions
    const income = totalIncome;
    const expenses = totalExpenses;

    // Get specific category totals
    const groceries = aggregatedCategoryTotals['groceries'] || 0;
    const diningOut = aggregatedCategoryTotals['dining out'] || 0;
    const education = aggregatedCategoryTotals['education'] || 0;
    const subscriptionsCount = expenseData.filter(expense => expense.category && expense.category.toLowerCase() === 'subscriptions').length;

    // 1. "Where did it all go wrong": expenses > income
    if (expenses > income) {
      newBadges.push({
        id: 'where-did-it-all-go-wrong',
        name: 'Where did it all go wrong',
        description: 'Your expenses have exceeded your income this month.',
        icon: '😟',
      });
    }

    // 2. "Living the Dream": expenses < 50% of income
    if (expenses < (0.5 * income)) {
      newBadges.push({
        id: 'living-the-dream',
        name: 'Living the Dream',
        description: 'Your expenses are less than 50% of your income this month.',
        icon: '🏖️',
      });
    }

    // 3. "We have food at home": groceries > dining out
    if (groceries > diningOut) {
      newBadges.push({
        id: 'we-have-food-at-home',
        name: 'We have food at home',
        description: 'Your groceries expenses are higher than dining out this month.',
        icon: '🍲',
      });
    }

    // 4. "A Gentleman and a Scholar": education > 20% of income
    if ((education / income) > 0.2) {
      newBadges.push({
        id: 'a-gentleman-and-a-scholar',
        name: 'A Gentleman and a Scholar',
        description: 'Your education expenses exceed 20% of your income this month.',
        icon: '🎓',
      });
    }

    // 5. "A Refined Palette": dining out > groceries
    if (diningOut > groceries) {
      newBadges.push({
        id: 'a-refined-palette',
        name: 'A Refined Palette',
        description: 'Your dining out expenses are higher than groceries this month.',
        icon: '🍷',
      });
    }

    // 6. "Do we really need all these?": subscriptions count >=10
    if (subscriptionsCount >= 10) {
      newBadges.push({
        id: 'do-we-really-need-all-these',
        name: 'Do we really need all these?',
        description: 'You have 10 or more subscription expenses this month.',
        icon: '📺',
      });
    }

    // 7. Existing Badges:

    // Check if all expenses are paid
    const allExpensesPaid = expenseData.length > 0 && expenseData.every(expense => expense.amountPaid >= expense.totalAmount);
    if (allExpensesPaid) {
      newBadges.push({
        id: 'all-expenses-paid',
        name: 'All Expenses Paid',
        description: 'You have successfully paid all your expenses for this month!',
        icon: '🎯',
      });
    }

    // Check if any savings goal is met
    savingsGoals.forEach(goal => {
      if (goal.amountSaved >= goal.goalAmount) {
        newBadges.push({
          id: `goal-met-${goal.id}`,
          name: 'Savings Goal Met',
          description: `You have met your savings goal: ${goal.goalName}!`,
          icon: '🏆',
        });
      }
    });

    // Optionally, add more badge criteria here

    // Determine which badges are newly earned
    const earnedBadges = newBadges.filter(
      (badge) => !badges.some((existingBadge) => existingBadge.id === badge.id)
    );

    // Update the badges state to only include currently earned badges
    setBadges(newBadges);

    // Notify user of newly earned badges
    if (earnedBadges.length > 0) {
      earnedBadges.forEach(badge => toast.success(`${badge.icon} ${badge.name}: ${badge.description}`));
    }
  }, [expenseData, savingsGoals, totalIncome, totalExpenses, aggregatedCategoryTotals]);

  // Effect: Reset badges when month or year changes
  useEffect(() => {
    setBadges([]);
  }, [filterMonth, filterYear]);

  // Gamification: Trigger badges or notifications when conditions are met
  useEffect(() => {
    checkGamificationAchievements();
  }, [checkGamificationAchievements]);

  // Prepare financial data for PersonalizedTips component
  const financialData = {
    income: totalIncome,
    expenses: expenseData,
    savingsGoals: savingsGoals,
    totalExpenses: totalExpenses,
  };

  return (
    <div className="py-10 px-4 sm:px-6 lg:px-8">
      {/* Header and Add Buttons */}
      <div className="max-w-7xl mx-auto mb-8">
        <h2 className="text-6xl font-bold text-center">Budget Overview</h2>

        {/* Add Buttons */}
        <div className="flex justify-center mt-4 space-x-4">
          <button
            onClick={() => setIsIncomeModalOpen(true)}
            className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition duration-300"
          >
            Add Income
          </button>
          <button
            onClick={() => setIsExpenseModalOpen(true)}
            className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition duration-300"
          >
            Add Expense
          </button>
          <button
            onClick={() => setIsSavingsGoalModalOpen(true)}
            className="px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition duration-300"
          >
            Add Savings Goal
          </button>
        </div>

        {/* Month and Year Filters */}
        <div className="flex justify-center mt-6 space-x-4">
          <div>
            <label htmlFor="month" className="block text-sm font-medium ">
              Month
            </label>
            <select
              id="month"
              value={filterMonth}
              onChange={(e) => setFilterMonth(e.target.value)}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            >
              {monthOptionsList.map((month) => (
                <option key={month.value} value={month.value}>
                  {month.label}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="year" className="block text-sm font-medium ">
              Year
            </label>
            <select
              id="year"
              value={filterYear}
              onChange={(e) => setFilterYear(e.target.value)}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            >
              {yearOptionsList}
            </select>
          </div>
        </div>
      </div>

      {/* Display Budget Summary */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-6 w-full max-w-7xl mx-auto">
        <div className="w-full p-4 bg-green-50 rounded-lg shadow flex flex-col items-center text-center">
          <h4 className="text-sm sm:text-md font-semibold text-green-800 mb-1">Total Income</h4>
          <p className="text-lg sm:text-2xl font-bold text-green-600">${totalIncome.toFixed(2)}</p>
        </div>
        <div className="w-full p-4 bg-red-50 rounded-lg shadow flex flex-col items-center text-center">
          <h4 className="text-sm sm:text-md font-semibold text-red-800 mb-1">Total Expenses</h4>
          <p className="text-lg sm:text-2xl font-bold text-red-600">${totalExpenses.toFixed(2)}</p>
        </div>
        <div className="w-full p-4 bg-blue-50 rounded-lg shadow flex flex-col items-center text-center">
          <h4 className="text-sm sm:text-md font-semibold text-blue-800 mb-1">Net Balance</h4>
          <p className="text-lg sm:text-2xl font-bold text-blue-600">
            ${(totalIncome - totalExpenses).toFixed(2)}
          </p>
        </div>
      </div>

      {/* Gamification: Badges Earned */}
      {badges.length > 0 && (
        <div className="max-w-7xl mx-auto px-4 lg:px-8 py-6">
          <h3 className="text-2xl font-semibold text-yellow-600 mb-4 text-center">Badges Earned</h3>
          <div className="flex flex-wrap justify-center gap-4">
            {badges.map((badge) => (
              <div key={badge.id} className="flex items-center space-x-2 bg-yellow-100 p-3 rounded-lg shadow">
                <span className="text-xl">{badge.icon}</span>
                <div>
                  <h4 className="text-lg font-medium text-gray-800">{badge.name}</h4>
                  <p className="text-sm text-gray-600">{badge.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Personalized Tips */}
      <div className="max-w-7xl mx-auto px-4 lg:px-8 py-6">
        <PersonalizedTips financialData={financialData} />
      </div>

      {/* Savings Goals Progress */}
      {savingsGoals.length > 0 && (
        <div className="max-w-7xl mx-auto px-4 lg:px-8 py-6">
          <h3 className="text-2xl font-semibold mb-4 text-center text-purple-700">Savings Goals Progress</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {savingsGoals.map((goal) => {
              const progress = calculateSavingsProgress(goal);
              return (
                <div
                  key={goal.id}
                  className="p-4 bg-white rounded-lg shadow-md flex flex-col items-center text-center relative"
                >
                  <h4 className="text-lg font-semibold text-gray-800 mb-2">{goal.goalName}</h4>
                  <p className="text-md text-gray-600 mb-2">
                    Goal Amount: ${goal.goalAmount.toFixed(2)}
                  </p>
                  <p className="text-md text-gray-600 mb-2">
                    Amount Saved: ${goal.amountSaved ? goal.amountSaved.toFixed(2) : '0.00'}
                  </p>
                  <div className="w-full bg-gray-200 rounded-full h-6 mt-2">
                    <div
                      className="h-6 rounded-full bg-purple-500"
                      style={{ width: `${progress}%` }}
                    ></div>
                  </div>
                  <p className="text-sm text-gray-600 mt-2">{progress.toFixed(2)}% achieved</p>
                  <button
                    onClick={() => openContributionModal(goal)}
                    className="mt-4 px-4 py-2 bg-green-600 text-white rounded-full hover:bg-green-700 transition-colors duration-300"
                  >
                    Edit Contribution
                  </button>
                  {/* Badge for Goal Completion */}
                  {progress >= 100 && (
                    <span className="absolute top-2 left-2 bg-yellow-400 text-white px-2 py-1 text-xs rounded-full">
                      🎉 Goal Met
                    </span>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}

      {/* Category Spending Breakdown */}
      <div className="max-w-7xl mx-auto px-4 lg:px-8 py-6">
        <h3 className="text-2xl sm:text-3xl font-semibold mb-4 text-center">
          Category Spending Breakdown
        </h3>
        <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md flex justify-center">
          <div className="w-full max-w-xs sm:max-w-md md:max-w-lg lg:max-w-xl h-64 sm:h-80 md:h-96">
            <Doughnut data={categorySpendingData} options={chartOptions} />
          </div>
        </div>
      </div>

      {/* Expense Breakdown Table */}
      <div className="max-w-7xl mx-auto px-4 lg:px-8 py-6">
        <h3 className="text-2xl font-semibold mb-4 text-center">Expense Breakdown</h3>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white text-center table-auto lg:table-fixed border-collapse border border-gray-200 shadow-md rounded-lg">
            <thead>
              <tr style={{ background: 'linear-gradient(to right, #4CAF50, #2E7D32)' }}>
                <th className="py-3 px-2 sm:px-4 border-b text-sm font-medium text-white">Expense</th>
                <th className="py-3 px-2 sm:px-4 border-b text-sm font-medium text-white">Total Amount ($)</th>
                <th className="py-3 px-2 sm:px-4 border-b text-sm font-medium text-white">Amount Paid ($)</th>
                <th className="py-3 px-2 sm:px-4 border-b text-sm font-medium text-white">% Paid</th>
                <th className="py-3 px-2 sm:px-4 border-b text-sm font-medium text-white">Progress</th>
                <th className="py-3 px-2 sm:px-4 border-b text-sm font-medium text-white">Actions</th>
                <th className="py-3 px-2 sm:px-4 border-b text-sm font-medium text-white">Edit Expense</th> {/* New Column */}
                <th className="py-3 px-2 sm:px-4 border-b text-sm font-medium text-white">100% Paid</th>
              </tr>
            </thead>
            <tbody>
              {expenseData.length > 0 ? (
                expenseData.map((expense) => {
                  const progress = calculateProgress(expense.amountPaid || 0, expense.totalAmount);
                  const progressBarValue = Math.min(progress, 100);
                  const progressBarColor =
                    progress <= 75 ? 'bg-yellow-500' : progress <= 100 ? 'bg-green-500' : 'bg-red-500';

                  const isFullyPaid = expense.amountPaid >= expense.totalAmount;

                  return (
                    <tr key={expense.id} className="bg-white">
                      <td className="py-4 px-2 sm:px-4 border-b border-gray-300 text-gray-700 font-semibold">
                        <span className="block text-base uppercase font-bold">{expense.description}</span>
                        <span className="block text-sm text-gray-500 uppercase">{expense.category}</span>
                      </td>
                      <td className="py-4 px-2 sm:px-4 border-b border-gray-300 text-gray-700 font-semibold">
                        ${expense.totalAmount.toFixed(2)}
                      </td>
                      <td className="py-4 px-2 sm:px-4 border-b border-gray-300 text-gray-700 font-semibold">
                        {editMode[expense.id] ? (
                          <input
                            type="number"
                            min="0"
                            max={expense.totalAmount}
                            defaultValue={expense.amountPaid}
                            className="w-20 p-2 border border-gray-300 rounded-md text-center"
                            onBlur={(e) =>
                              handleAmountPaidUpdate(expense.id, e.target.value, expense.totalAmount)
                            }
                          />
                        ) : (
                          `$${(expense.amountPaid || 0).toFixed(2)}`
                        )}
                      </td>
                      <td className="py-4 px-2 sm:px-4 border-b border-gray-300 text-gray-700 font-semibold">
                        {progress}%
                      </td>
                      <td className="py-4 px-2 sm:px-4 border-b border-gray-300 text-gray-700">
                        <div className="w-full bg-gray-200 rounded-full h-4">
                          <div
                            className={`h-4 rounded-full ${progressBarColor}`}
                            style={{ width: `${progressBarValue}%` }}
                          ></div>
                        </div>
                      </td>
                      <td className="py-4 px-2 sm:px-4 border-b border-gray-300 text-gray-700">
                        <button
                          onClick={() => handleEditToggle(expense.id)}
                          className="bg-blue-500 text-white px-2 sm:px-3 py-1 rounded-md shadow hover:bg-blue-600 transition duration-300"
                        >
                          {editMode[expense.id] ? 'Save' : 'Edit'}
                        </button>
                      </td>
                      {/* New "Edit Expense" Button */}
                      <td className="py-4 px-2 sm:px-4 border-b border-gray-300 text-center">
                        <button
                          onClick={() => navigate('/expenses', { state: { expenseId: expense.id } })}
                          className="bg-yellow-500 text-white px-2 sm:px-3 py-1 rounded-md shadow hover:bg-yellow-600 transition duration-300"
                        >
                          Edit Expense
                        </button>
                      </td>
                      <td className="py-4 px-2 sm:px-4 border-b border-gray-300 text-center">
                        <label className="flex items-center justify-center cursor-pointer">
                          <input
                            type="checkbox"
                            checked={isFullyPaid}
                            onChange={(e) =>
                              handleCheckboxChange(expense.id, e.target.checked, expense.totalAmount)
                            }
                            className="form-checkbox h-4 w-4 text-green-600"
                          />
                        </label>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="8" className="py-6 px-4 text-center text-gray-600">
                    No expenses to display for the selected month and year.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Monthly Income Breakdown */}
      <div className="max-w-7xl mx-auto px-4 lg:px-8 py-6">
        <h3 className="text-2xl font-semibold mb-4 text-center">Monthly Income Breakdown</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {incomeData.map((income) => (
            <div
              key={income.id}
              className="p-4 bg-white rounded-lg shadow-md flex flex-col items-center text-center relative"
            >
              <h4 className="text-lg font-semibold text-gray-800 mb-2">{income.source}</h4>
              <p className="text-2xl font-bold text-green-600">${income.totalAmount.toFixed(2)}</p>
              <p className="text-sm text-gray-500">
                ({totalIncome ? ((income.totalAmount / totalIncome) * 100).toFixed(2) : 0}% of total)
              </p>
              {/* Display occurrence dates only in Monthly Income Breakdown */}
              <ul className="text-xs text-gray-500 mt-2">
                {income.occurrenceDates.map((date, index) => (
                  <li key={index}>{date}</li>
                ))}
              </ul>
              {/* New "Edit Income" Button */}
              <button
                onClick={() => navigate('/income', { state: { incomeId: income.id } })}
                className="mt-4 px-4 py-2 bg-yellow-500 text-white rounded-md shadow hover:bg-yellow-600 transition duration-300"
              >
                Edit Income
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Savings Goal Contribution Modal */}
      <Modal
        isOpen={isContributionModalOpen}
        onRequestClose={closeContributionModal}
        contentLabel="Edit Contribution"
        className="fixed inset-0 flex items-center justify-center z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
      >
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
          <div className="flex justify-between items-center mb-4">
            <h4 className="text-lg font-semibold text-gray-800">Edit Contribution</h4>
            <button
              onClick={closeContributionModal}
              className="text-gray-600 hover:text-gray-800 focus:outline-none text-2xl leading-none"
            >
              &times;
            </button>
          </div>
          {selectedGoal && (
            <form onSubmit={handleSubmit(onSubmitContribution)} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Savings Goal:
                </label>
                <p className="mt-1 text-gray-600">{selectedGoal.goalName}</p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Total Amount Saved ($):
                </label>
                <input
                  type="number"
                  step="0.01"
                  min="0"
                  {...register('amount', { required: true, min: 0 })}
                  defaultValue={selectedGoal.amountSaved}
                  className={`mt-1 block w-full px-4 py-2 border ${
                    errors.amount ? 'border-red-500' : 'border-gray-300'
                  } rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500`}
                  placeholder="Enter total saved amount"
                />
                {errors.amount && (
                  <p className="mt-1 text-red-500 text-sm">Please enter a valid amount.</p>
                )}
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={closeContributionModal}
                  className="mr-4 px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition-colors duration-300"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors duration-300"
                >
                  Update
                </button>
              </div>
            </form>
          )}
        </div>
      </Modal>

      {/* Income Modal */}
      <Modal
        isOpen={isIncomeModalOpen}
        onRequestClose={() => setIsIncomeModalOpen(false)}
        contentLabel="Add Income"
        className="fixed inset-0 flex items-center justify-center z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
      >
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg">
          <div className="flex justify-between items-center mb-4">
            <h4 className="text-lg font-semibold text-gray-800">Add Income</h4>
            <button
              onClick={() => setIsIncomeModalOpen(false)}
              className="text-gray-600 hover:text-gray-800 focus:outline-none text-2xl leading-none"
            >
              &times;
            </button>
          </div>
          <IncomeForm onClose={() => setIsIncomeModalOpen(false)} isModal={true} />
        </div>
      </Modal>

      {/* Expense Modal */}
      <Modal
        isOpen={isExpenseModalOpen}
        onRequestClose={() => setIsExpenseModalOpen(false)}
        contentLabel="Add Expense"
        className="fixed inset-0 flex items-center justify-center z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
      >
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg">
          <div className="flex justify-between items-center mb-4">
            <h4 className="text-lg font-semibold text-gray-800">Add Expense</h4>
            <button
              onClick={() => setIsExpenseModalOpen(false)}
              className="text-gray-600 hover:text-gray-800 focus:outline-none text-2xl leading-none"
            >
              &times;
            </button>
          </div>
          <ExpenseForm onClose={() => setIsExpenseModalOpen(false)} isModal={true} />
        </div>
      </Modal>

      {/* Savings Goal Modal */}
      <Modal
        isOpen={isSavingsGoalModalOpen}
        onRequestClose={() => setIsSavingsGoalModalOpen(false)}
        contentLabel="Manage Savings Goals"
        className="fixed inset-0 flex items-center justify-center z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
      >
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-2xl">
          <div className="flex justify-between items-center mb-4">
            <h4 className="text-lg font-semibold text-gray-800">Manage Savings Goals</h4>
            <button
              onClick={() => setIsSavingsGoalModalOpen(false)}
              className="text-gray-600 hover:text-gray-800 focus:outline-none text-2xl leading-none"
            >
              &times;
            </button>
          </div>
          <SavingsGoalForm onClose={() => setIsSavingsGoalModalOpen(false)} isModal={true} />
        </div>
      </Modal>

      {/* Import Modal */}
      <Modal
        isOpen={isImportModalOpen}
        onRequestClose={() => setIsImportModalOpen(false)}
        contentLabel="Import Data"
        className="fixed inset-0 flex items-center justify-center z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
      >
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg">
          <div className="flex justify-between items-center mb-4">
            <h4 className="text-lg font-semibold text-gray-800">Import Data</h4>
            <button
              onClick={() => setIsImportModalOpen(false)}
              className="text-gray-600 hover:text-gray-800 focus:outline-none text-2xl leading-none"
            >
              &times;
            </button>
          </div>
          <DataImport onClose={() => setIsImportModalOpen(false)} />
        </div>
      </Modal>

      {/* Export Modal */}
      <Modal
        isOpen={isExportModalOpen}
        onRequestClose={() => setIsExportModalOpen(false)}
        contentLabel="Export Data"
        className="fixed inset-0 flex items-center justify-center z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
      >
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg">
          <div className="flex justify-between items-center mb-4">
            <h4 className="text-lg font-semibold text-gray-800">Export Data</h4>
            <button
              onClick={() => setIsExportModalOpen(false)}
              className="text-gray-600 hover:text-gray-800 focus:outline-none text-2xl leading-none"
            >
              &times;
            </button>
          </div>
          <DataExport onClose={() => setIsExportModalOpen(false)} />
        </div>
      </Modal>
    </div>
  );
};

export default ViewBudget;
