// src/components/DataImport.js

import React, { useState } from 'react';
import { db, auth } from '../firebase-config';
import { 
  collection, 
  addDoc 
} from 'firebase/firestore';
import { toast } from 'react-hot-toast';
import Papa from 'papaparse';

const DataImport = ({ onClose }) => {
  const [importType, setImportType] = useState('expenses');
  const [file, setFile] = useState(null);
  const [isImporting, setIsImporting] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleImport = async (e) => {
    e.preventDefault();

    if (!file) {
      toast.error('Please select a CSV file to import.');
      return;
    }

    const userId = auth.currentUser?.uid;
    if (!userId) {
      toast.error('You must be logged in to import data.');
      return;
    }

    setIsImporting(true);

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async function (results) {
        try {
          const importedItems = results.data.map((row) => ({
            name: row.Name ? row.Name.trim() : '',
            quantity: parseInt(row.Quantity, 10) || 1,
            price: parseFloat(row.Price) || 0,
            category: row.Category ? row.Category.trim() : 'Other',
            checked: row.Checked === 'true' || row.Checked === true,
            shoppedQuantity: parseInt(row.ShoppedQuantity, 10) || 0,
            notes: row.Notes ? row.Notes.trim() : '',
          }));

          // Filter out invalid items
          const validImportedItems = importedItems.filter(
            (item) => item.name && item.quantity > 0 && !isNaN(item.price)
          );

          if (validImportedItems.length === 0) {
            toast.error('No valid items found in the CSV file.');
            setIsImporting(false);
            return;
          }

          // Reference to the appropriate Firestore collection based on importType
          let itemsCollectionPath;
          if (importType === 'expenses') {
            itemsCollectionPath = `expenses/${userId}/expenseList`;
          } else if (importType === 'incomes') {
            itemsCollectionPath = `incomes/${userId}/incomeList`;
          } else if (importType === 'savingsGoals') {
            itemsCollectionPath = `savingsGoals/${userId}/savingsGoalList`;
          } else {
            toast.error('Invalid import type selected.');
            setIsImporting(false);
            return;
          }

          const itemsCollection = collection(db, itemsCollectionPath);

          // Add each item to Firestore
          const addPromises = validImportedItems.map((item) => addDoc(itemsCollection, item));
          await Promise.all(addPromises);

          toast.success('Items imported successfully!');
          if (onClose) {
            onClose();
          }
        } catch (error) {
          console.error('Error importing data:', error);
          toast.error('An error occurred while importing data.');
        } finally {
          setIsImporting(false);
        }
      },
      error: function (error) {
        console.error('Error parsing CSV:', error);
        toast.error('Failed to parse the CSV file.');
        setIsImporting(false);
      },
    });

    // Reset the file input to allow re-importing the same file if needed
    e.target.reset();
  };

  return (
    <form
      onSubmit={handleImport}
      className="space-y-6"
      encType="multipart/form-data"
    >
      {/* Data Type Selection */}
      <div>
        <label className="block text-sm font-medium text-gray-800">
          Data Type:
        </label>
        <select
          value={importType}
          onChange={(e) => setImportType(e.target.value)}
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-500 focus:border-blue-500"
          aria-label="Select Data Type to Import"
        >
          <option value="expenses">Expenses</option>
          <option value="incomes">Incomes</option>
          <option value="savingsGoals">Savings Goals</option>
        </select>
      </div>

      {/* CSV File Input */}
      <div>
        <label className="block text-sm font-medium text-gray-800">
          Upload CSV File:
        </label>
        <input
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          className="mt-1 block w-full text-sm text-gray-700 file:py-2 file:px-4
                     file:border file:border-gray-300 file:rounded-md
                     file:text-sm file:font-semibold
                     file:bg-blue-50 file:text-blue-700
                     hover:file:bg-blue-100"
          aria-label="CSV File Input"
        />
      </div>

      {/* Import Button */}
      <div className="text-center">
        <button
          type="submit"
          disabled={isImporting}
          className={`w-full sm:w-auto px-6 py-2 rounded-md shadow text-white ${
            isImporting ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'
          }`}
          aria-label="Import Data Button"
        >
          {isImporting ? 'Importing...' : 'Import Data'}
        </button>
      </div>
    </form>
  );
};

export default DataImport;
