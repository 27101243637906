// src/components/AdminAddCoupon.js

import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useFirebaseService } from '../services/firebaseService';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Timestamp } from 'firebase/firestore'; // Import Timestamp
import { toast } from 'react-toastify';
import { FiSave, FiTrash2 } from 'react-icons/fi'; // Added FiTrash2 for delete icon
import 'react-toastify/dist/ReactToastify.css';

const AdminAddCoupon = () => {
  const { admin, loading } = useAuth(); 
  const firebaseService = useFirebaseService();
  const [formData, setFormData] = useState({
    retailer: '',
    description: '',
    code: '',
    affiliateLink: '',
    expirationDate: '',
    category: '',
    bannerUrl: '', 
  });
  const [bannerFile, setBannerFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false); 
  const [coupons, setCoupons] = useState([]);
  const [isDeleting, setIsDeleting] = useState({}); // Track deletion states per coupon

  useEffect(() => {
    // Fetch existing coupons on component mount
    const fetchCoupons = async () => {
      try {
        const couponsSnapshot = await firebaseService.getTopLevelData('coupons');
        setCoupons(couponsSnapshot);
      } catch (error) {
        console.error('Error fetching coupons:', error);
        toast.error('Failed to fetch coupons.');
      }
    };

    fetchCoupons();
  }, [firebaseService]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p className="text-gray-500 text-xl">Loading...</p>
      </div>
    );
  }

  if (!admin) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p className="text-red-500 text-xl">You do not have permission to view this page.</p>
      </div>
    );
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];   

    if (file) {
      const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
      const maxSize = 5 * 1024 * 1024; 

      if (!validTypes.includes(file.type)) {
        toast.error('Only JPEG, PNG, and GIF files are allowed.');
        e.target.value = ''; 
        return;
      }

      if (file.size > maxSize) {
        toast.error('File size exceeds the 5MB limit.');
        e.target.value = ''; 
        return;
      }

      setBannerFile(file);
    }
  };

  const validateData = () => {
    const { retailer, code, affiliateLink, expirationDate } = formData;

    if (!retailer.trim()) {
      toast.error('Retailer is required.');
      return false;
    }
    if (!code.trim()) {
      toast.error('Code is required.');
      return false;
    }
    if (!affiliateLink.trim()) {
      toast.error('Affiliate Link is required.');
      return false;
    }
    if (!expirationDate) {
      toast.error('Expiration Date is required.');
      return false;
    }

    const urlPattern = /^https?:\/\/.+$/;
    if (!urlPattern.test(affiliateLink)) {
      toast.error('Affiliate Link must be a valid URL.');
      return false;
    }

    const expDate = new Date(expirationDate);
    if (isNaN(expDate.getTime())) {
      toast.error('Expiration Date is invalid.');
      return false;
    }
    if (expDate <= new Date()) {
      toast.error('Expiration Date must be in the future.');
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!validateData()) {
      setIsSubmitting(false);
      return;
    }

    let bannerUrl = formData.bannerUrl;

    if (bannerFile) {
      try {
        const storage = getStorage();
        const uniqueFileName = `${Date.now()}-${bannerFile.name}`;
        const imageRef = storageRef(storage, `banners/${uniqueFileName}`);
        await uploadBytes(imageRef, bannerFile);
        bannerUrl = await getDownloadURL(imageRef);
      } catch (uploadError) {
        console.error('Error uploading banner:', uploadError);
        toast.error('Failed to upload banner image.');
        setIsSubmitting(false);
        return;
      }
    }

    try {
      console.log("Form Data:", formData); 

      // Prepare data to match security rules
      const preparedData = {
        retailer: formData.retailer.trim(),
        description: formData.description.trim(),
        code: formData.code.trim(),
        affiliateLink: formData.affiliateLink.trim(),
        expirationDate: Timestamp.fromDate(new Date(formData.expirationDate)), // Firestore Timestamp
        category: formData.category.trim(),
        bannerUrl: bannerUrl || '', 
        createdAt: Timestamp.fromDate(new Date()), // Firestore Timestamp
      };

      console.log('Adding coupon with data:', preparedData);

      // Use addTopLevelData to add to 'coupons' collection
      await firebaseService.addTopLevelData('coupons', preparedData);

      console.log('Coupon added successfully');

      toast.success('Coupon added successfully!');
      setFormData({
        retailer: '',
        description: '',
        code: '',
        affiliateLink: '',
        expirationDate: '',
        category: '',
        bannerUrl: '',
      });
      setBannerFile(null);
      document.getElementById('bannerFile').value = ''; 
      
      // Refresh the coupons list
      const updatedCoupons = await firebaseService.getTopLevelData('coupons');
      setCoupons(updatedCoupons);
    } catch (err) {
      console.error('Error adding coupon:', err);
      if (err.code === 'permission-denied') {
        toast.error('You do not have permission to add coupons.');
      } else {
        toast.error('Failed to add coupon. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (couponId) => {
    // Confirm deletion
    const confirmDelete = window.confirm('Are you sure you want to delete this coupon?');
    if (!confirmDelete) return;

    // Set deletion state for this coupon
    setIsDeleting((prev) => ({ ...prev, [couponId]: true }));

    try {
      await firebaseService.deleteTopLevelData('coupons', couponId);
      toast.success('Coupon deleted successfully!');
      // Remove the deleted coupon from the state
      setCoupons((prevCoupons) => prevCoupons.filter((coupon) => coupon.id !== couponId));
    } catch (error) {
      console.error('Error deleting coupon:', error);
      if (error.code === 'permission-denied') {
        toast.error('You do not have permission to delete coupons.');
      } else {
        toast.error('Failed to delete coupon. Please try again.');
      }
    } finally {
      setIsDeleting((prev) => ({ ...prev, [couponId]: false }));
    }
  };

  return (
    <div className="max-w-4xl mx-auto mt-10 bg-white p-8 rounded shadow">
      <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">Manage Coupons</h2>

      {/* **Add New Coupon Form** */}
      <form onSubmit={handleSubmit} className="mb-12">
        <div className="mb-4">
          <label htmlFor="retailer" className="block text-gray-700 font-medium mb-2">
            Retailer <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="retailer"
            name="retailer"
            value={formData.retailer}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Retailer Name"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="description" className="block text-gray-700 font-medium mb-2">
            Description
          </label>
          <textarea
            id="description"
            name="description"
            value={formData.description}   
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Description (optional)"
            rows="3"
          ></textarea>
        </div>

        <div className="mb-4">
          <label htmlFor="code" className="block text-gray-700 font-medium mb-2">
            Code 
          </label>
          <input
            type="text"
            id="code"
            name="code"
            value={formData.code}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Coupon Code"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="affiliateLink" className="block text-gray-700 font-medium mb-2">
            Affiliate Link <span className="text-red-500">*</span>
          </label>
          <input
            type="url"
            id="affiliateLink"
            name="affiliateLink"
            value={formData.affiliateLink}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="https://affiliate-link.com"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="expirationDate" className="block text-gray-700 font-medium mb-2">
            Expiration Date 
          </label>
          <input
            type="date"
            id="expirationDate"
            name="expirationDate"
            value={formData.expirationDate}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="category"   
className="block text-gray-700 font-medium mb-2">
            Category
          </label>
          <input
            type="text"   

            id="category"
            name="category"
            value={formData.category}
            onChange={handleChange}
            className="w-full   
px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Category (optional)"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="bannerUrl" className="block text-gray-700 font-medium mb-2">
            Banner Image URL
          </label>
          <input
            type="url"
            id="bannerUrl"
            name="bannerUrl"
            value={formData.bannerUrl}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="https://example.com/banner.jpg"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="bannerFile" className="block text-gray-700 font-medium mb-2">
            Or Upload Banner Image
          </label>
          <input
            type="file"
            id="bannerFile"
            name="bannerFile"
            accept="image/*"
            onChange={handleFileChange}
            className="w-full"
          />
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className={`w-full bg-blue-600 text-white font-semibold py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-300 flex items-center justify-center ${
            isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {isSubmitting ? 'Adding Coupon...' : 'Add Coupon'} <FiSave className="ml-2" />
        </button>
      </form>

      {/* **Existing Coupons List** */}
      <h3 className="text-2xl font-bold mb-6 text-gray-800 text-center">Existing Coupons</h3>
      {coupons.length === 0 ? (
        <p className="text-center text-gray-500">No coupons available.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">Retailer</th>
                <th className="py-2 px-4 border-b">Code</th>
                <th className="py-2 px-4 border-b">Affiliate Link</th>
                <th className="py-2 px-4 border-b">Expiration Date</th>
                <th className="py-2 px-4 border-b">Category</th>
                <th className="py-2 px-4 border-b">Banner</th>
                <th className="py-2 px-4 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {coupons.map((coupon) => (
                <tr key={coupon.id} className="hover:bg-gray-100">
                  <td className="py-2 px-4 border-b text-center">{coupon.retailer}</td>
                  <td className="py-2 px-4 border-b text-center">{coupon.code}</td>
                  <td className="py-2 px-4 border-b text-center">
                    <a href={coupon.affiliateLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                      Visit Link
                    </a>
                  </td>
                  <td className="py-2 px-4 border-b text-center">
                    {coupon.expirationDate.toDate().toLocaleDateString()}
                  </td>
                  <td className="py-2 px-4 border-b text-center">{coupon.category || 'N/A'}</td>
                  <td className="py-2 px-4 border-b text-center">
                    {coupon.bannerUrl ? (
                      <img src={coupon.bannerUrl} alt={`${coupon.retailer} Banner`} className="w-16 h-16 object-cover mx-auto" />
                    ) : (
                      'No Image'
                    )}
                  </td>
                  <td className="py-2 px-4 border-b text-center">
                    <button
                      onClick={() => handleDelete(coupon.id)}
                      disabled={isDeleting[coupon.id]}
                      className={`text-red-500 hover:text-red-700 font-semibold ${
                        isDeleting[coupon.id] ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                      title="Delete Coupon"
                    >
                      {isDeleting[coupon.id] ? 'Deleting...' : <FiTrash2 className="inline-block" />}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AdminAddCoupon;