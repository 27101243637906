// src/components/ExpenseForm.js

import React, { useState, useEffect, useContext } from 'react';
import { useFirebaseService } from '../services/firebaseService';
import { ProfileContext } from '../contexts/ProfileContext';
import { AuthContext } from '../contexts/AuthContext';
import toast from 'react-hot-toast';
import {
  startOfMonth,
  endOfMonth,
  addWeeks,
  addMonths,
  addYears,
} from 'date-fns';

// Helper function to parse local date strings
const parseLocalDate = (dateString) => {
  const [year, month, day] = dateString.split('-').map(Number);
  return new Date(year, month - 1, day);
};

const ExpenseForm = ({ onClose, isModal = false }) => {
  // Contexts
  const { user } = useContext(AuthContext);
  const { activeProfileId, profilesLoading } = useContext(ProfileContext);

  // State variables for form fields
  const [amount, setAmount] = useState('');
  const [date, setDate] = useState('');
  const [category, setCategory] = useState('');
  const [description, setDescription] = useState('');
  const [isRecurring, setIsRecurring] = useState(false);
  const [recurrenceFrequency, setRecurrenceFrequency] = useState('');
  const [recurrenceDay, setRecurrenceDay] = useState('');
  const [recurrenceEndDate, setRecurrenceEndDate] = useState('');
  const [expenseData, setExpenseData] = useState([]);
  const [editId, setEditId] = useState(null);

  // Current month and year for filtering
  const currentDate = new Date();
  const currentMonth = (currentDate.getMonth() + 1).toString();
  const currentYear = currentDate.getFullYear().toString();

  const [filterMonth, setFilterMonth] = useState(currentMonth);
  const [filterYear, setFilterYear] = useState(currentYear);

  // Firebase Service Hook
  const firebaseService = useFirebaseService();

  useEffect(() => {
    // Prevent subscription if profiles are loading or contexts are unavailable
    if (profilesLoading || !firebaseService || !activeProfileId || !user || !user.uid) {
      console.warn(
        'ExpenseForm - Cannot subscribe to data. Profiles loading or missing context values.'
      );
      return;
    }

    // Subscribe to Expenses
    const unsubscribe = firebaseService.subscribeToData(
      ['users', user.uid, 'profiles', activeProfileId, 'expenses'],
      (data) => {
        console.log('Fetched Expenses:', data);
        setExpenseData(data);
      }
    );

    return () => {
      if (typeof unsubscribe === 'function') {
        unsubscribe();
        console.log('Unsubscribed from expenses data.');
      }
    };
  }, [firebaseService, activeProfileId, profilesLoading, user]);

  // Function to handle adding or updating an expense
  const handleAddOrUpdateExpense = async (e) => {
    e.preventDefault();
    if (!firebaseService || !activeProfileId || !user) {
      toast.error('Firebase service, active profile ID, or user is not available.');
      return;
    }

    // Data validation
    if (!amount || !date || !category) {
      toast.error('Please fill in all required fields.');
      return;
    }

    // If recurring and frequency is weekly or bi-weekly, ensure recurrenceDay is selected
    if (
      isRecurring &&
      (recurrenceFrequency === 'weekly' || recurrenceFrequency === 'bi-weekly') &&
      !recurrenceDay
    ) {
      toast.error('Please select a day of the week for recurring expense.');
      return;
    }

    const parsedDate = parseLocalDate(date);
    if (isNaN(parsedDate)) {
      toast.error('Invalid date. Please enter a valid date.');
      return;
    }

    const unixTimestamp = parsedDate.getTime();

    let parsedRecurrenceEndDate = null;
    if (recurrenceEndDate) {
      const tempDate = parseLocalDate(recurrenceEndDate);
      if (isNaN(tempDate)) {
        toast.error('Invalid recurrence end date. Please enter a valid date.');
        return;
      }
      parsedRecurrenceEndDate = tempDate.getTime();
    }

    const expenseDataObj = {
      amount: parseFloat(amount),
      date: unixTimestamp,
      category,
      description,
      isRecurring,
      recurrenceFrequency,
      recurrenceDay: isRecurring && (recurrenceFrequency === 'weekly' || recurrenceFrequency === 'bi-weekly') ? recurrenceDay : '',
      recurrenceEndDate: parsedRecurrenceEndDate,
    };

    try {
      if (editId) {
        await firebaseService.updateData(
          user.uid,
          activeProfileId,
          'expenses',
          editId,
          expenseDataObj
        );
        toast.success('Expense updated successfully!');
      } else {
        await firebaseService.addData(
          user.uid,
          activeProfileId,
          'expenses',
          expenseDataObj
        );
        toast.success('Expense added successfully!');
      }
      resetForm();
      if (onClose) {
        onClose();
      }
    } catch (error) {
      console.error('Error adding/updating expense:', error);
      toast.error('Failed to add/update expense.');
    }
  };

  // Function to reset the form fields
  const resetForm = () => {
    setEditId(null);
    setAmount('');
    setDate('');
    setCategory('');
    setDescription('');
    setIsRecurring(false);
    setRecurrenceFrequency('');
    setRecurrenceDay('');
    setRecurrenceEndDate('');
  };

  // Function to get occurrences of a transaction in a given month
  const getOccurrencesInMonth = (transaction, year, month) => {
    const occurrences = [];
    const transactionDate = transaction.date ? new Date(transaction.date) : null;
    if (!transactionDate || isNaN(transactionDate)) return occurrences;

    const startDate = startOfMonth(new Date(year, month - 1));
    const endDate = endOfMonth(startDate);

    if (!transaction.isRecurring || !transaction.recurrenceFrequency) {
      // Non-recurring transaction
      if (
        transactionDate.getMonth() + 1 === Number(month) &&
        transactionDate.getFullYear() === Number(year)
      ) {
        occurrences.push(transactionDate);
      }
    } else {
      // Recurring transaction
      const recurrenceFrequency = transaction.recurrenceFrequency;
      const recurrenceDay = transaction.recurrenceDay; // e.g., 'Tuesday'
      const dayOfWeekMap = {
        Sunday: 0,
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6,
      };
      const targetDay = dayOfWeekMap[recurrenceDay];

      if (recurrenceFrequency === 'weekly' || recurrenceFrequency === 'bi-weekly') {
        if (!recurrenceDay) {
          console.error('Recurrence day is not set for recurring expense.');
          return occurrences;
        }

        // Find the first occurrence of the target day in the month
        let currentDate = new Date(startDate);
        const currentDay = currentDate.getDay();
        const daysToAdd = (targetDay - currentDay + 7) % 7;
        currentDate.setDate(currentDate.getDate() + daysToAdd);

        // Ensure the first occurrence is not before the transaction date
        if (currentDate < transactionDate) {
          currentDate = addWeeks(
            currentDate,
            recurrenceFrequency === 'weekly' ? 1 : 2
          );
        }

        const frequencyInWeeks = recurrenceFrequency === 'weekly' ? 1 : 2;

        while (currentDate <= endDate) {
          if (transaction.recurrenceEndDate) {
            const recurrenceEndDate = new Date(transaction.recurrenceEndDate);
            if (currentDate > recurrenceEndDate) break;
          }

          // Ensure the occurrence is within the month and after the transaction date
          if (
            currentDate.getMonth() + 1 === Number(month) &&
            currentDate.getFullYear() === Number(year) &&
            currentDate >= transactionDate
          ) {
            occurrences.push(new Date(currentDate));
          }

          // Add frequency interval
          currentDate = addWeeks(currentDate, frequencyInWeeks);
        }
      } else if (recurrenceFrequency === 'monthly') {
        // For monthly, assume the day of month based on transactionDate
        const dayOfMonth = transactionDate.getDate();
        let currentDate = new Date(year, month - 1, dayOfMonth);

        // Handle months with fewer days
        if (currentDate.getMonth() + 1 !== Number(month)) {
          // If dayOfMonth doesn't exist in this month, set to last day of the month
          currentDate = endDate;
        }

        if (currentDate >= startDate && currentDate <= endDate) {
          if (!transaction.recurrenceEndDate || currentDate <= new Date(transaction.recurrenceEndDate)) {
            occurrences.push(currentDate);
          }
        }
      } else if (recurrenceFrequency === 'yearly') {
        // For yearly, assume the day and month are based on transactionDate
        const monthOfYear = transactionDate.getMonth(); // 0-indexed
        const dayOfMonth = transactionDate.getDate();
        let currentDate = new Date(year, monthOfYear, dayOfMonth);

        // Handle leap year and invalid dates
        if (currentDate.getMonth() !== monthOfYear) {
          // If invalid date (e.g., Feb 29 on non-leap year), set to Feb 28
          currentDate = new Date(year, monthOfYear, 28);
        }

        if (currentDate >= startDate && currentDate <= endDate) {
          if (!transaction.recurrenceEndDate || currentDate <= new Date(transaction.recurrenceEndDate)) {
            occurrences.push(currentDate);
          }
        }
      }
    }

    return occurrences;
  };

  // Function to handle editing an expense
  const handleEdit = (expense) => {
    setEditId(expense.id);
    setAmount(expense.amount);

    let expenseDate = '';
    if (expense.date) {
      expenseDate = new Date(expense.date).toISOString().split('T')[0];
    }

    let recurrenceEnd = '';
    if (expense.recurrenceEndDate) {
      recurrenceEnd = new Date(expense.recurrenceEndDate).toISOString().split('T')[0];
    }

    setDate(expenseDate);
    setCategory(expense.category);
    setDescription(expense.description || '');
    setIsRecurring(expense.isRecurring || false);
    setRecurrenceFrequency(expense.recurrenceFrequency || '');
    setRecurrenceDay(expense.recurrenceDay || '');
    setRecurrenceEndDate(recurrenceEnd);
  };

  // Function to handle deleting an expense
  const handleDelete = async (id) => {
    if (!firebaseService || !activeProfileId || !user) {
      toast.error('Firebase service, active profile ID, or user is not available.');
      return;
    }
    try {
      await firebaseService.deleteData(
        user.uid,
        activeProfileId,
        'expenses',
        id
      );
      toast.success('Expense deleted successfully!');
    } catch (error) {
      console.error('Error deleting expense:', error);
      toast.error('Failed to delete expense.');
    }
  };

  // Generate options for months
  const monthOptions = [
    { value: '1', label: 'January' },
    { value: '2', label: 'February' },
    { value: '3', label: 'March' },
    { value: '4', label: 'April' },
    { value: '5', label: 'May' },
    { value: '6', label: 'June' },
    { value: '7', label: 'July' },
    { value: '8', label: 'August' },
    { value: '9', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
  ];

  // Generate options for years
  const yearOptions = [];
  for (let year = Number(currentYear) - 5; year <= Number(currentYear) + 5; year++) {
    yearOptions.push(
      <option key={year} value={year.toString()}>
        {year}
      </option>
    );
  }

  return (
    <div className={`max-w-5xl mx-auto p-6 bg-white rounded-lg shadow-md ${isModal ? '' : 'mb-8'}`}>
      {/* Month and Year Filter Section at the Top */}
      {!isModal && (
        <div className="flex flex-wrap items-center justify-center space-x-4 mb-6">
          <select
            value={filterMonth}
            onChange={(e) => setFilterMonth(e.target.value)}
            className="w-40 p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
          >
            {monthOptions.map((month) => (
              <option key={month.value} value={month.value}>
                {month.label}
              </option>
            ))}
          </select>
          <select
            value={filterYear}
            onChange={(e) => setFilterYear(e.target.value)}
            className="w-32 p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
          >
            {yearOptions}
          </select>
        </div>
      )}

      <h3 className="text-xl font-semibold text-green-700 mb-6 text-center">
        {editId ? 'Edit Expense' : 'Add Expense'}
      </h3>

      <form onSubmit={handleAddOrUpdateExpense} className="space-y-6">
        {/* Form Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {/* Amount */}
          <div>
            <label className="block text-sm font-medium text-gray-800">Amount:</label>
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
              required
              min="0"
              step="0.01"
            />
          </div>
          {/* Date */}
          <div>
            <label className="block text-sm font-medium text-gray-800">Date:</label>
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
              required
            />
          </div>
          {/* Category */}
          <div>
            <label className="block text-sm font-medium text-gray-800">Category:</label>
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
              required
            >
              <option value="">Select a category</option>
              <option value="bills">Bills</option>
              <option value="housing">Housing</option>
              <option value="groceries">Groceries</option>
              <option value="transportation">Transportation</option>
              <option value="entertainment">Entertainment</option>
              <option value="health">Health</option>
              <option value="education">Education</option>
              <option value="emergency fund">Emergency Fund</option>
              <option value="savings">Savings</option>
              <option value="shopping">Shopping</option>
              <option value="travel">Travel</option>
              <option value="subscriptions">Subscriptions</option>
              <option value="insurance">Insurance</option>
              <option value="dining out">Dining Out</option>
              <option value="miscellaneous">Miscellaneous</option>
            </select>
          </div>
          {/* Description */}
          <div className="sm:col-span-2 md:col-span-3">
            <label className="block text-sm font-medium text-gray-800">Description:</label>
            <input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
            />
          </div>
        </div>

        {/* Recurring Expense Section */}
        <div className="border-t border-gray-200 pt-6">
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={isRecurring}
              onChange={(e) => {
                setIsRecurring(e.target.checked);
                if (!e.target.checked) {
                  // Reset recurrence fields when unchecked
                  setRecurrenceFrequency('');
                  setRecurrenceDay('');
                  setRecurrenceEndDate('');
                }
              }}
              className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded"
            />
            <label className="ml-2 text-sm font-medium text-gray-800">
              Is this a recurring expense?
            </label>
          </div>

          {isRecurring && (
            <div className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-6">
              {/* Recurrence Frequency */}
              <div>
                <label className="block text-sm font-medium text-gray-800">
                  Recurrence Frequency:
                </label>
                <select
                  value={recurrenceFrequency}
                  onChange={(e) => setRecurrenceFrequency(e.target.value)}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                  required
                >
                  <option value="">Select frequency</option>
                  <option value="weekly">Weekly</option>
                  <option value="bi-weekly">Bi-Weekly</option>
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
                </select>
              </div>
              {/* Recurrence Day - Only for weekly and bi-weekly */}
              {(recurrenceFrequency === 'weekly' || recurrenceFrequency === 'bi-weekly') && (
                <div>
                  <label className="block text-sm font-medium text-gray-800">
                    Recurrence Day:
                  </label>
                  <select
                    value={recurrenceDay}
                    onChange={(e) => setRecurrenceDay(e.target.value)}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                    required
                  >
                    <option value="">Select day</option>
                    <option value="Sunday">Sunday</option>
                    <option value="Monday">Monday</option>
                    <option value="Tuesday">Tuesday</option>
                    <option value="Wednesday">Wednesday</option>
                    <option value="Thursday">Thursday</option>
                    <option value="Friday">Friday</option>
                    <option value="Saturday">Saturday</option>
                  </select>
                </div>
              )}
              {/* Recurrence End Date */}
              <div>
                <label className="block text-sm font-medium text-gray-800">
                  Recurrence End Date:
                </label>
                <input
                  type="date"
                  value={recurrenceEndDate}
                  onChange={(e) => setRecurrenceEndDate(e.target.value)}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                />
              </div>
            </div>
          )}
        </div>

        {/* Submit Button */}
        <div className="text-center">
          <button
            type="submit"
            className="w-full sm:w-auto px-6 py-2 bg-green-600 text-white rounded-md shadow hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
          >
            {editId ? 'Update Expense' : 'Add Expense'}
          </button>
        </div>
      </form>

      {/* Expenses List */}
      {!isModal && (
        <>
          <h4 className="text-lg font-bold mb-4 mt-10 text-center">Your Expenses</h4>
          {expenseData.length > 0 ? (
            <ul className="space-y-4">
              {expenseData.map((expense) => {
                // Get occurrences of expense for the selected month and year
                const occurrences = getOccurrencesInMonth(expense, filterYear, filterMonth);
                if (occurrences.length > 0) {
                  return (
                    <li
                      key={expense.id}
                      className="border p-4 rounded-lg shadow-sm flex flex-col md:flex-row justify-between items-start md:items-center"
                    >
                      <div className="mb-4 md:mb-0 md:flex-1">
                        <p className="text-sm">
                          <strong>Category:</strong> {expense.category}
                        </p>
                        <p className="text-sm">
                          <strong>Amount:</strong> ${parseFloat(expense.amount).toFixed(2)}
                        </p>
                        <p className="text-sm">
                          <strong>Date:</strong>{' '}
                          {occurrences.map((date) => date.toLocaleDateString()).join(', ')}
                        </p>
                        {expense.isRecurring && (
                          <>
                            <p className="text-sm">
                              <strong>Recurrence:</strong>{' '}
                              {expense.recurrenceFrequency.charAt(0).toUpperCase() + expense.recurrenceFrequency.slice(1)}
                            </p>
                            {(expense.recurrenceFrequency === 'weekly' || expense.recurrenceFrequency === 'bi-weekly') && (
                              <p className="text-sm">
                                <strong>Day:</strong> {expense.recurrenceDay}
                              </p>
                            )}
                            {expense.recurrenceEndDate && (
                              <p className="text-sm">
                                <strong>Recurs Until:</strong>{' '}
                                {new Date(expense.recurrenceEndDate).toLocaleDateString()}
                              </p>
                            )}
                          </>
                        )}
                        {expense.description && (
                          <p className="text-sm">
                            <strong>Description:</strong> {expense.description}
                          </p>
                        )}
                      </div>
                      <div className="flex space-x-2">
                        <button
                          onClick={() => handleEdit(expense)}
                          className="bg-blue-500 text-white px-3 py-1 rounded-md shadow hover:bg-blue-600 text-sm"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleDelete(expense.id)}
                          className="bg-red-500 text-white px-3 py-1 rounded-md shadow hover:bg-red-600 text-sm"
                        >
                          Delete
                        </button>
                      </div>
                    </li>
                  );
                } else {
                  return null;
                }
              })}
            </ul>
          ) : (
            <p className="text-center">No expenses to display for the selected month and year.</p>
          )}
        </>
      )}
    </div>
  );
};

export default ExpenseForm;







