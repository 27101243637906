// src/components/UserRights.js

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet'; // For SEO and managing document head

const UserRights = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  return (
    <>
      <Helmet>
        <title>User Rights - Atlas Budget</title>
        <meta
          name="description"
          content="Learn about your rights as a user of Atlas Budget."
        />
      </Helmet>
      <div className="max-w-4xl mx-auto p-6">
        <h1 className="text-3xl font-bold mb-4">User Rights</h1>
        
        {/* 1. Right to Access */}
        <section className="mb-4">
          <h2 className="text-xl font-semibold">1. Right to Access</h2>
          <p>
            Users have the right to access their personal data stored within our platform.
          </p>
        </section>

        {/* 2. Right to Rectification */}
        <section className="mb-4">
          <h2 className="text-xl font-semibold">2. Right to Rectification</h2>
          <p>
            Users can request corrections to any inaccurate or incomplete information we hold about them.
          </p>
        </section>

        {/* 3. Right to Erasure */}
        <section className="mb-4">
          <h2 className="text-xl font-semibold">3. Right to Erasure</h2>
          <p>
            Users have the right to request the deletion of their personal data under certain conditions.
          </p>
        </section>

        {/* 4. Right to Restrict Processing */}
        <section className="mb-4">
          <h2 className="text-xl font-semibold">4. Right to Restrict Processing</h2>
          <p>
            Users can request the restriction of processing their personal data under specific circumstances.
          </p>
        </section>

        {/* 5. Right to Data Portability */}
        <section className="mb-4">
          <h2 className="text-xl font-semibold">5. Right to Data Portability</h2>
          <p>
            Users have the right to receive their personal data in a structured, commonly used, and machine-readable format.
          </p>
        </section>

        {/* 6. Right to Object */}
        <section className="mb-4">
          <h2 className="text-xl font-semibold">6. Right to Object</h2>
          <p>
            Users can object to the processing of their personal data for direct marketing purposes.
          </p>
        </section>

        {/* 7. Automated Decision-Making and Profiling */}
        <section className="mb-4">
          <h2 className="text-xl font-semibold">7. Automated Decision-Making and Profiling</h2>
          <p>
            Users have the right not to be subject to decisions based solely on automated processing, including profiling, that produce legal effects concerning them.
          </p>
        </section>

        {/* Additional User Rights */}
        <section className="mb-4">
          <h2 className="text-xl font-semibold">8. Additional Rights</h2>
          <p>
            Users may have additional rights depending on their jurisdiction. Please contact us for more information.
          </p>
        </section>

        {/* Contact Information */}
        <section>
          <h2 className="text-xl font-semibold">Contact Information</h2>
          <p>
            If you wish to exercise any of your rights or have any questions about your rights, please contact us at:
          </p>
          <ul className="list-none mt-2">
            <li><strong>Email:</strong> <a href="mailto:support@atlasbudget.com" className="text-blue-500 hover:underline">support@atlasbudget.com</a></li>
            <li><strong>Mailing Address:</strong> 123 Budget Street, Financial City, FC 12345</li>
          </ul>
        </section>
      </div>
    </>
  );
};

export default UserRights;
