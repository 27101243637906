// src/components/AssetChart.js

import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale, // x-axis
  LinearScale, // y-axis
  PointElement,
  Tooltip,
  Legend,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend
);

const AssetChart = ({ data, assetName }) => {
  // Prepare data for Chart.js
  const chartData = {
    labels: data.map((d) => d.time),
    datasets: [
      {
        label: `${assetName} Price (USD)`,
        data: data.map((d) => d.price),
        fill: false,
        backgroundColor: 'rgba(79, 70, 229, 0.5)', // Indigo color
        borderColor: 'rgba(79, 70, 229, 1)',
        tension: 0.1,
        pointRadius: 0, // Remove points for a cleaner line
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allow chart to adjust height
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          font: {
            size: 12, // Smaller font size on mobile
          },
        },
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Time',
          font: {
            size: 14,
          },
        },
        ticks: {
          maxTicksLimit: 10, // Limit ticks to prevent clutter
          font: {
            size: 10,
          },
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Price (USD)',
          font: {
            size: 14,
          },
        },
        ticks: {
          callback: function (value) {
            return `$${value}`;
          },
          font: {
            size: 10,
          },
        },
        beginAtZero: false,
      },
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
    elements: {
      line: {
        borderWidth: 2,
      },
    },
  };

  return (
    <div className="bg-white p-4 rounded-md shadow-md w-full h-64 sm:h-80 md:h-96">
      <Line data={chartData} options={options} />
    </div>
  );
};

export default AssetChart;
