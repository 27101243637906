// src/contexts/ThemeContext.js

import React, { createContext, useState, useEffect, useContext } from 'react';
import { ProfileContext } from './ProfileContext';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const { profiles, activeProfileId, profilesLoading } = useContext(ProfileContext);
  const [currentTheme, setCurrentTheme] = useState('Light');

  useEffect(() => {
    if (profilesLoading) return; // Wait until profiles are loaded

    const activeProfile = profiles.find(profile => profile.id === activeProfileId);
    if (activeProfile && activeProfile.theme) {
      setCurrentTheme(activeProfile.theme);
      console.log(`Theme set to: ${activeProfile.theme}`);
    } else {
      setCurrentTheme('Light'); // Default theme
      console.log('Theme set to: Light');
    }
  }, [profiles, activeProfileId, profilesLoading]);

  // Apply the theme by setting the data-theme attribute on <html>
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', currentTheme);
    console.log(`Applied data-theme: ${currentTheme}`);
  }, [currentTheme]);

  return (
    <ThemeContext.Provider value={{ currentTheme, setCurrentTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
