// src/components/Coupons.js

import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
  limit,
  startAfter,
} from 'firebase/firestore';
import { FiSearch, FiFilter } from 'react-icons/fi';
import { toast } from 'react-toastify';
import debounce from 'lodash.debounce';
import { db } from '../firebase-config'; // Ensure correct import path

const Coupons = () => {
  const [coupons, setCoupons] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCoupons, setFilteredCoupons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    retailer: '',
    category: '',
  });
  const [lastVisible, setLastVisible] = useState(null);
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  // **Fetch Initial Coupons with Real-Time Updates**
  const fetchCoupons = useCallback(() => {
    setLoading(true);
    const couponsRef = collection(db, 'coupons');
    let q = query(
      couponsRef,
      where('expirationDate', '>=', new Date()),
      orderBy('expirationDate'),
      limit(20)
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        if (snapshot.size > 0) {
          const couponsList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setCoupons(couponsList);
          setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
        } else {
          setCoupons([]);
          setLastVisible(null);
        }
        setLoading(false);
      },
      (error) => {
        console.error('Error fetching coupons:', error.code, error.message);
        toast.error('Failed to fetch coupons.');
        setLoading(false);
      }
    );

    return unsubscribe;
  }, []);

  // **Fetch More Coupons for Pagination**
  const fetchMoreCoupons = useCallback(() => {
    if (isFetchingMore || !lastVisible) return;
    setIsFetchingMore(true);

    const couponsRef = collection(db, 'coupons');
    let q = query(
      couponsRef,
      where('expirationDate', '>=', new Date()),
      orderBy('expirationDate'),
      startAfter(lastVisible),
      limit(20)
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        if (snapshot.size > 0) {
          const newCoupons = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setCoupons((prevCoupons) => [...prevCoupons, ...newCoupons]);
          setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
        } else {
          setLastVisible(null);
        }
        setIsFetchingMore(false);
      },
      (error) => {
        console.error('Error fetching more coupons:', error.code, error.message);
        toast.error('Failed to fetch more coupons.');
        setIsFetchingMore(false);
      }
    );

    // **Cleanup Listener After Fetching More**
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [isFetchingMore, lastVisible]);

  useEffect(() => {
    const unsubscribe = fetchCoupons();
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [fetchCoupons]);

  // **Debounce Search Input to Optimize Performance**
  const debouncedSearchTerm = useMemo(() => {
    return debounce((value) => setSearchTerm(value), 300);
  }, []);

  const handleSearchChange = (e) => {
    debouncedSearchTerm(e.target.value);
  };

  useEffect(() => {
    let tempCoupons = [...coupons];

    // **Search Filtering**
    if (searchTerm.trim() !== '') {
      const lowerSearch = searchTerm.toLowerCase();
      tempCoupons = tempCoupons.filter(
        (coupon) =>
          (coupon.retailer && coupon.retailer.toLowerCase().includes(lowerSearch)) ||
          (coupon.description && coupon.description.toLowerCase().includes(lowerSearch)) ||
          (coupon.code && coupon.code.toLowerCase().includes(lowerSearch)) ||
          (coupon.category && coupon.category.toLowerCase().includes(lowerSearch))
      );
    }

    // **Retailer Filtering**
    if (filters.retailer.trim() !== '') {
      tempCoupons = tempCoupons.filter(
        (coupon) =>
          coupon.retailer &&
          coupon.retailer.toLowerCase() === filters.retailer.toLowerCase()
      );
    }

    // **Category Filtering**
    if (filters.category.trim() !== '') {
      tempCoupons = tempCoupons.filter(
        (coupon) =>
          coupon.category &&
          coupon.category.toLowerCase() === filters.category.toLowerCase()
      );
    }

    setFilteredCoupons(tempCoupons);
  }, [searchTerm, filters, coupons]);

  // **Unique Retailers and Categories for Filters**
  const uniqueRetailers = useMemo(() => {
    return Array.from(new Set(coupons.map((coupon) => coupon.retailer)));
  }, [coupons]);

  const uniqueCategories = useMemo(() => {
    return Array.from(
      new Set(
        coupons.map((coupon) => coupon.category).filter((category) => category)
      )
    );
  }, [coupons]);

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  // **Infinite Scroll Effect**
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 200 >=
          document.documentElement.offsetHeight &&
        !isFetchingMore &&
        lastVisible
      ) {
        fetchMoreCoupons();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isFetchingMore, lastVisible, fetchMoreCoupons]);

  return (
    <div className="py-10 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        {/* **Amazon Affiliate Disclosure (Primary Location)** */}
        <div className="mb-6 text-center text-sm">
          <p>
            Some links on this page are affiliate links. If you make a purchase through these links, we may earn a commission at no additional cost to you. Thank you for your support!
          </p>
        </div>

        <h2 className="text-5xl font-extrabold mb-8 text-center">
          Available Deals
        </h2>

        {/* **Search and Filters** */}
        <div className="flex flex-col md:flex-row justify-between items-center mb-10">
          {/* **Search Bar** */}
          <div className="flex items-center w-full md:w-auto mb-4 md:mb-0">
            <FiSearch className="w-5 h-5 text-gray-500 mr-2" />
            <input
              type="text"
              placeholder="Search coupons..."
              onChange={handleSearchChange}
              className="w-full md:w-64 px-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          {/* **Filter Dropdowns** */}
          <div className="flex items-center space-x-4 w-full md:w-auto">
            {/* **Retailer Filter** */}
            <div className="flex items-center">
              <FiFilter className="w-5 h-5 text-gray-500 mr-2" />
              <select
                name="retailer"
                value={filters.retailer}
                onChange={handleFilterChange}
                className="w-40 px-4 py-2 border border-gray-300 rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">All Retailers</option>
                {uniqueRetailers.map((retailer, index) => (
                  <option key={index} value={retailer}>
                    {retailer}
                  </option>
                ))}
              </select>
            </div>

            {/* **Category Filter** */}
            <div className="flex items-center">
              <FiFilter className="w-5 h-5 text-gray-500 mr-2" />
              <select
                name="category"
                value={filters.category}
                onChange={handleFilterChange}
                className="w-40 px-4 py-2 border border-gray-300 rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">All Categories</option>
                {uniqueCategories.map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* **Coupons List** */}
        {loading ? (
          // **Loading Indicator**
          <div className="flex items-center justify-center min-h-screen">
            <p className="text-gray-500 text-xl">Loading coupons...</p>
          </div>
        ) : filteredCoupons.length > 0 ? (
          // **Coupons Grid**
          <ul className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {filteredCoupons.map((coupon) => (
              <li
                key={coupon.id}
                className="bg-white shadow-lg rounded-2xl overflow-hidden transform hover:scale-105 transition-transform duration-300"
              >
                {/* **Coupon Banner Image with Improved Layout** */}
                <div className="w-full aspect-video bg-gray-100 flex items-center justify-center">
                  {coupon.bannerUrl ? (
                    <img
                      src={coupon.bannerUrl}
                      alt={`${coupon.retailer} Banner`}
                      className="w-full h-full object-contain"
                      loading="lazy"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = 'https://placehold.co/600x400?text=Image+Unavailable';
                      }}
                    />
                  ) : (
                    <span className="text-gray-500 text-lg">
                      No Image Available
                    </span>
                  )}
                </div>

                {/* **Coupon Details** */}
                <div className="p-6">
                  <h3 className="text-2xl font-semibold text-indigo-600">
                    {coupon.retailer}
                  </h3>
                  {coupon.description && (
                    <p className="mt-2 text-gray-600">{coupon.description}</p>
                  )}
                  <div className="mt-4">
                    <span className="inline-block bg-green-200 text-green-800 px-3 py-1 rounded-full text-sm font-semibold">
                      {coupon.category || 'Uncategorized'}
                    </span>
                  </div>
                  <div className="mt-4 flex flex-wrap items-center">
                    <span className="inline-block bg-yellow-200 text-yellow-800 px-4 py-2 rounded-full font-bold mr-2 mb-2">
                      {coupon.code}
                    </span>
                    <span className="text-sm text-gray-500">
                      Expires on {coupon.expirationDate.toDate().toLocaleDateString()}
                    </span>
                  </div>
                </div>

                {/* **Use Coupon Button** */}
                <div className="px-6 pb-6">
                  <a
                    href={coupon.affiliateLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-full inline-block text-center px-4 py-2 border border-transparent text-sm font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-300"
                  >
                    Get Deal
                  </a>
                </div>

                {/* **Affiliate Disclosure within Coupon Card** */}
                <div className="px-6 pb-4">
                  <p className="text-xs text-gray-500">
                    This is an affiliate link. We earn from qualifying purchases.
                  </p>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          // **No Coupons Found Message**
          <div className="flex justify-center">
            <p className="text-gray-500 text-lg">
              No coupons match your search or filter criteria.
            </p>
          </div>
        )}

        {/* **Loading More Spinner** */}
        {isFetchingMore && (
          <div className="flex justify-center mt-6">
            <p className="text-gray-500 text-lg">Loading more coupons...</p>
          </div>
        )}
      </div>
    </div>
  );
};

// **Helper Functions**

/**
 * Extracts the image URL from the content:encoded field.
 * @param {string} content - The HTML content string.
 * @returns {string} - The extracted image URL or a placeholder.
 */
const extractImageFromContent = (content) => {
  const regex = /<img src="([^"]+)" alt="[^"]*"[^>]*>/;
  const match = content.match(regex);
  return match ? match[1] : 'https://via.placeholder.com/300';
};

/**
 * Truncates text to a specified length, adding ellipsis if necessary.
 * @param {string} text - The text to truncate.
 * @param {number} maxLength - The maximum allowed length.
 * @returns {string} - The truncated text.
 */
const truncateText = (text, maxLength) => {
  if (!text) return '';
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

export default Coupons;
