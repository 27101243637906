// src/components/IncomeForm.js

import React, { useState, useEffect, useContext } from 'react';
import { useFirebaseService } from '../services/firebaseService';
import { ProfileContext } from '../contexts/ProfileContext';
import { AuthContext } from '../contexts/AuthContext';
import toast from 'react-hot-toast';
import {
  startOfMonth,
  endOfMonth,
  addWeeks,
  addMonths,
  addYears,
} from 'date-fns';

// Helper function to parse local date strings
const parseLocalDate = (dateString) => {
  const [year, month, day] = dateString.split('-').map(Number);
  return new Date(year, month - 1, day);
};

const IncomeForm = ({ onClose, isModal = false }) => {
  // Contexts
  const { user } = useContext(AuthContext);
  const { activeProfileId, profilesLoading } = useContext(ProfileContext);

  // State variables for form fields
  const [source, setSource] = useState('');
  const [amount, setAmount] = useState('');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');
  const [isRecurring, setIsRecurring] = useState(false);
  const [recurrenceFrequency, setRecurrenceFrequency] = useState('');
  const [recurrenceEndDate, setRecurrenceEndDate] = useState('');
  const [incomeData, setIncomeData] = useState([]);
  const [editId, setEditId] = useState(null);

  // Current month and year for filtering
  const currentDate = new Date();
  const currentMonth = (currentDate.getMonth() + 1).toString();
  const currentYear = currentDate.getFullYear().toString();

  const [filterMonth, setFilterMonth] = useState(currentMonth);
  const [filterYear, setFilterYear] = useState(currentYear);

  // Firebase Service Hook
  const firebaseService = useFirebaseService();

  useEffect(() => {
    // Prevent subscription if profiles are loading or contexts are unavailable
    if (profilesLoading || !firebaseService || !activeProfileId || !user || !user.uid) {
      console.warn(
        'IncomeForm - Cannot subscribe to data. Profiles loading or missing context values.'
      );
      return;
    }

    // Subscribe to Incomes
    const unsubscribe = firebaseService.subscribeToData(
      ['users', user.uid, 'profiles', activeProfileId, 'incomes'],
      (data) => {
        console.log('Fetched Incomes:', data);
        setIncomeData(data);
      }
    );

    return () => {
      if (typeof unsubscribe === 'function') {
        unsubscribe();
        console.log('Unsubscribed from incomes data.');
      }
    };
  }, [firebaseService, activeProfileId, profilesLoading, user]);

  // Function to handle adding or updating an income
  const handleAddOrUpdateIncome = async (e) => {
    e.preventDefault();
    if (!firebaseService || !activeProfileId) {
      toast.error('Firebase service or active profile ID is not available.');
      return;
    }

    // Data validation
    if (!source || !amount || !date) {
      toast.error('Please fill in all required fields.');
      return;
    }

    const parsedDate = parseLocalDate(date);
    if (isNaN(parsedDate)) {
      toast.error('Invalid date. Please enter a valid date.');
      return;
    }

    const unixTimestamp = parsedDate.getTime();

    let parsedRecurrenceEndDate = null;
    if (recurrenceEndDate) {
      const tempDate = parseLocalDate(recurrenceEndDate);
      if (isNaN(tempDate)) {
        toast.error('Invalid recurrence end date. Please enter a valid date.');
        return;
      }
      parsedRecurrenceEndDate = tempDate.getTime();
    }

    const incomeDataObj = {
      source,
      amount: parseFloat(amount),
      date: unixTimestamp,
      description,
      isRecurring,
      recurrenceFrequency,
      recurrenceEndDate: parsedRecurrenceEndDate,
    };

    try {
      if (editId) {
        await firebaseService.updateData(
          user.uid,
          activeProfileId,
          'incomes',
          editId,
          incomeDataObj
        );
        toast.success('Income updated successfully!');
      } else {
        await firebaseService.addData(
          user.uid,
          activeProfileId,
          'incomes',
          incomeDataObj
        );
        toast.success('Income added successfully!');
      }
      resetForm();
      if (onClose) {
        onClose();
      }
    } catch (error) {
      console.error('Error adding/updating income:', error);
      toast.error('Failed to add/update income.');
    }
  };

  // Function to reset the form fields
  const resetForm = () => {
    setEditId(null);
    setSource('');
    setAmount('');
    setDate('');
    setDescription('');
    setIsRecurring(false);
    setRecurrenceFrequency('');
    setRecurrenceEndDate('');
  };

  // Function to get occurrences of a transaction in a given month
  const getOccurrencesInMonth = (transaction, year, month) => {
    const occurrences = [];
    const transactionDate = transaction.date ? new Date(transaction.date) : null;
    if (!transactionDate || isNaN(transactionDate)) return occurrences;

    const startDate = startOfMonth(new Date(year, month - 1));
    const endDate = endOfMonth(startDate);

    if (!transaction.isRecurring || !transaction.recurrenceFrequency) {
      // Non-recurring transaction
      if (
        transactionDate.getMonth() + 1 === Number(month) &&
        transactionDate.getFullYear() === Number(year)
      ) {
        occurrences.push(transactionDate);
      }
    } else {
      // Recurring transaction
      let nextDate = new Date(transactionDate);
      const frequency = transaction.recurrenceFrequency;

      // Adjust nextDate to the first occurrence on or after startDate
      while (nextDate < startDate) {
        if (frequency === 'weekly') {
          nextDate = addWeeks(nextDate, 1);
        } else if (frequency === 'bi-weekly') {
          nextDate = addWeeks(nextDate, 2);
        } else if (frequency === 'monthly') {
          nextDate = addMonths(nextDate, 1);
        } else if (frequency === 'yearly') {
          nextDate = addYears(nextDate, 1);
        }

        if (transaction.recurrenceEndDate && nextDate > new Date(transaction.recurrenceEndDate)) {
          break;
        }
      }

      // Now, collect occurrences within the month
      while (nextDate <= endDate) {
        if (nextDate >= startDate && nextDate <= endDate) {
          occurrences.push(new Date(nextDate));
        }

        // Add frequency interval
        if (frequency === 'weekly') {
          nextDate = addWeeks(nextDate, 1);
        } else if (frequency === 'bi-weekly') {
          nextDate = addWeeks(nextDate, 2);
        } else if (frequency === 'monthly') {
          nextDate = addMonths(nextDate, 1);
        } else if (frequency === 'yearly') {
          nextDate = addYears(nextDate, 1);
        }

        if (transaction.recurrenceEndDate && nextDate > new Date(transaction.recurrenceEndDate)) {
          break;
        }
      }
    }

    return occurrences;
  };

  // Function to handle editing an income
  const handleEdit = (income) => {
    setEditId(income.id);
    setSource(income.source);
    setAmount(income.amount);

    let incomeDate = '';
    if (income.date) {
      incomeDate = new Date(income.date).toISOString().split('T')[0];
    }

    let recurrenceEnd = '';
    if (income.recurrenceEndDate) {
      recurrenceEnd = new Date(income.recurrenceEndDate).toISOString().split('T')[0];
    }

    setDate(incomeDate);
    setDescription(income.description || '');
    setIsRecurring(income.isRecurring || false);
    setRecurrenceFrequency(income.recurrenceFrequency || '');
    setRecurrenceEndDate(recurrenceEnd);
  };

  // Function to handle deleting an income
  const handleDelete = async (id) => {
    if (!firebaseService || !activeProfileId) {
      toast.error('Firebase service or active profile ID is not available.');
      return;
    }
    try {
      await firebaseService.deleteData(
        user.uid,
        activeProfileId,
        'incomes',
        id
      );
      toast.success('Income deleted successfully!');
    } catch (error) {
      console.error('Error deleting income:', error);
      toast.error('Failed to delete income.');
    }
  };

  // Generate options for months
  const monthOptions = [
    { value: '1', label: 'January' },
    { value: '2', label: 'February' },
    { value: '3', label: 'March' },
    { value: '4', label: 'April' },
    { value: '5', label: 'May' },
    { value: '6', label: 'June' },
    { value: '7', label: 'July' },
    { value: '8', label: 'August' },
    { value: '9', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
  ];

  // Generate options for years (from currentYear - 5 to currentYear + 5)
  const yearOptions = [];
  for (let year = Number(currentYear) - 5; year <= Number(currentYear) + 5; year++) {
    yearOptions.push(
      <option key={year} value={year.toString()}>
        {year}
      </option>
    );
  }

  return (
    <div className={`max-w-5xl mx-auto p-6 bg-white rounded-lg shadow-md ${isModal ? '' : 'mb-8'}`}>
      {/* Month and Year Filter Section at the Top */}
      {!isModal && (
        <div className="flex flex-wrap items-center justify-center space-x-4 mb-6">
          <select
            value={filterMonth}
            onChange={(e) => setFilterMonth(e.target.value)}
            className="w-40 p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
          >
            {monthOptions.map((month) => (
              <option key={month.value} value={month.value}>
                {month.label}
              </option>
            ))}
          </select>
          <select
            value={filterYear}
            onChange={(e) => setFilterYear(e.target.value)}
            className="w-32 p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
          >
            {yearOptions}
          </select>
        </div>
      )}

      <h3 className="text-xl font-semibold text-green-700 mb-6 text-center">
        {editId ? 'Edit Income' : 'Add Income'}
      </h3>

      <form onSubmit={handleAddOrUpdateIncome} className="space-y-6">
        {/* Form Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {/* Source */}
          <div>
            <label className="block text-sm font-medium text-gray-800">Source:</label>
            <input
              type="text"
              value={source}
              onChange={(e) => setSource(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
              required
            />
          </div>
          {/* Amount */}
          <div>
            <label className="block text-sm font-medium text-gray-800">Amount:</label>
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
              required
              min="0"
              step="0.01"
            />
          </div>
          {/* Date */}
          <div>
            <label className="block text-sm font-medium text-gray-800">Date:</label>
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
              required
            />
          </div>
          {/* Description */}
          <div className="sm:col-span-2 md:col-span-3">
            <label className="block text-sm font-medium text-gray-800">Description:</label>
            <input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
            />
          </div>
        </div>

        {/* Recurring Income Section */}
        <div className="border-t border-gray-200 pt-6">
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={isRecurring}
              onChange={(e) => {
                setIsRecurring(e.target.checked);
                if (!e.target.checked) {
                  // Reset recurrence fields when unchecked
                  setRecurrenceFrequency('');
                  setRecurrenceEndDate('');
                }
              }}
              className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded"
            />
            <label className="ml-2 text-sm font-medium text-gray-800">
              Is this a recurring income?
            </label>
          </div>

          {isRecurring && (
            <div className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-6">
              {/* Recurrence Frequency */}
              <div>
                <label className="block text-sm font-medium text-gray-800">
                  Recurrence Frequency:
                </label>
                <select
                  value={recurrenceFrequency}
                  onChange={(e) => setRecurrenceFrequency(e.target.value)}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                  required
                >
                  <option value="">Select frequency</option>
                  <option value="weekly">Weekly</option>
                  <option value="bi-weekly">Bi-Weekly</option>
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
                </select>
              </div>
              {/* Recurrence End Date */}
              <div>
                <label className="block text-sm font-medium text-gray-800">
                  Recurrence End Date:
                </label>
                <input
                  type="date"
                  value={recurrenceEndDate}
                  onChange={(e) => setRecurrenceEndDate(e.target.value)}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                />
              </div>
              {/* Placeholder for future expansion or spacing */}
              <div></div>
            </div>
          )}
        </div>

        {/* Submit Button */}
        <div className="text-center">
          <button
            type="submit"
            className="w-full sm:w-auto px-6 py-2 bg-green-600 text-white rounded-md shadow hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
          >
            {editId ? 'Update Income' : 'Add Income'}
          </button>
        </div>
      </form>

      {/* Income List */}
      {!isModal && (
        <>
          <h4 className="text-lg font-bold mb-4 mt-10 text-center">Your Income</h4>
          {incomeData.length > 0 ? (
            <ul className="space-y-4">
              {incomeData.map((income) => {
                // Get occurrences of income for the selected month and year
                const occurrences = getOccurrencesInMonth(income, filterYear, filterMonth);
                if (occurrences.length > 0) {
                  return (
                    <li
                      key={income.id}
                      className="border p-4 rounded-lg shadow-sm flex flex-col md:flex-row justify-between items-start md:items-center"
                    >
                      <div className="mb-4 md:mb-0 md:flex-1">
                        <p className="text-sm">
                          <strong>Source:</strong> {income.source}
                        </p>
                        <p className="text-sm">
                          <strong>Amount:</strong> ${parseFloat(income.amount).toFixed(2)}
                        </p>
                        <p className="text-sm">
                          <strong>Date(s):</strong> {occurrences.map((date) => date.toLocaleDateString()).join(', ')}
                        </p>
                        {income.isRecurring && (
                          <>
                            <p className="text-sm">
                              <strong>Recurrence:</strong> {income.recurrenceFrequency.charAt(0).toUpperCase() + income.recurrenceFrequency.slice(1)}
                            </p>
                            {income.recurrenceEndDate && (
                              <p className="text-sm">
                                <strong>Recurs Until:</strong> {new Date(income.recurrenceEndDate).toLocaleDateString()}
                              </p>
                            )}
                          </>
                        )}
                        {income.description && (
                          <p className="text-sm">
                            <strong>Description:</strong> {income.description}
                          </p>
                        )}
                      </div>
                      <div className="flex space-x-2">
                        <button
                          onClick={() => handleEdit(income)}
                          className="bg-blue-500 text-white px-3 py-1 rounded-md shadow hover:bg-blue-600 text-sm"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleDelete(income.id)}
                          className="bg-red-500 text-white px-3 py-1 rounded-md shadow hover:bg-red-600 text-sm"
                        >
                          Delete
                        </button>
                      </div>
                    </li>
                  );
                } else {
                  return null;
                }
              })}
            </ul>
          ) : (
            <p className="text-center">No income to display for the selected month and year.</p>
          )}
        </>
      )}
    </div>
  );
};

export default IncomeForm;
