// src/components/SavingsGoalForm.js

import React, { useState, useEffect, useContext } from 'react';
import { useFirebaseService } from '../services/firebaseService';
import { ProfileContext } from '../contexts/ProfileContext';
import { AuthContext } from '../contexts/AuthContext';
import toast from 'react-hot-toast';

const SavingsGoalForm = ({ onClose, isModal = false }) => {
  // Contexts
  const { user } = useContext(AuthContext);
  const { activeProfileId, profilesLoading } = useContext(ProfileContext);

  // State variables
  const [goalName, setGoalName] = useState('');
  const [goalAmount, setGoalAmount] = useState('');
  const [targetDate, setTargetDate] = useState('');
  const [description, setDescription] = useState('');
  const [savingsGoals, setSavingsGoals] = useState([]);
  const [editGoalId, setEditGoalId] = useState(null);

  const firebaseService = useFirebaseService();

  useEffect(() => {
    // Fetch existing savings goals
    if (!firebaseService || !user || !activeProfileId || profilesLoading) return;

    const fetchSavingsGoals = async () => {
      try {
        const data = await firebaseService.getSavingsGoals(user.uid, activeProfileId);
        setSavingsGoals(data);
      } catch (error) {
        console.error('Error fetching savings goals:', error);
      }
    };

    fetchSavingsGoals();
  }, [firebaseService, user, activeProfileId, profilesLoading]);

  const handleSaveSavingsGoal = async (e) => {
    e.preventDefault();
    if (!firebaseService || !user || !activeProfileId) return;

    // Data validation
    if (!goalName.trim()) {
      toast.error('Please enter a goal name.');
      return;
    }
    if (!goalAmount || parseFloat(goalAmount) <= 0) {
      toast.error('Please enter a valid goal amount.');
      return;
    }
    if (targetDate && new Date(targetDate) < new Date()) {
      toast.error('Target date must be in the future.');
      return;
    }

    const savingsGoalObj = {
      goalName: goalName.trim(),
      goalAmount: parseFloat(goalAmount),
      targetDate: targetDate ? new Date(targetDate).getTime() : null,
      description,
      amountSaved: 0, // Initialize with zero saved
    };

    try {
      if (editGoalId) {
        // Update existing goal
        await firebaseService.updateSavingsGoal(
          user.uid,
          activeProfileId,
          editGoalId,
          savingsGoalObj
        );
        toast.success('Savings goal updated successfully!');
      } else {
        // Add new goal
        await firebaseService.addSavingsGoal(user.uid, activeProfileId, savingsGoalObj);
        toast.success('Savings goal added successfully!');
      }
      resetForm();
      if (onClose) onClose();
    } catch (error) {
      console.error('Error saving savings goal:', error);
      toast.error('Failed to save savings goal.');
    }
  };

  const resetForm = () => {
    setEditGoalId(null);
    setGoalName('');
    setGoalAmount('');
    setTargetDate('');
    setDescription('');
  };

  const handleEditGoal = (goal) => {
    setEditGoalId(goal.id);
    setGoalName(goal.goalName);
    setGoalAmount(goal.goalAmount);
    setTargetDate(goal.targetDate ? new Date(goal.targetDate).toISOString().split('T')[0] : '');
    setDescription(goal.description || '');
  };

  const handleDeleteGoal = async (id) => {
    if (!firebaseService || !user || !activeProfileId) return;
    if (!window.confirm('Are you sure you want to delete this savings goal?')) return;

    try {
      await firebaseService.deleteSavingsGoal(user.uid, activeProfileId, id);
      toast.success('Savings goal deleted successfully!');
    } catch (error) {
      console.error('Error deleting savings goal:', error);
      toast.error('Failed to delete savings goal.');
    }
  };

  return (
    <div className={`max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-md ${isModal ? '' : 'mb-8'}`}>
      <h3 className="text-xl font-semibold text-green-700 mb-6 text-center">
        {editGoalId ? 'Edit Savings Goal' : 'Add Savings Goal'}
      </h3>

      <form onSubmit={handleSaveSavingsGoal} className="space-y-6">
        {/* Goal Name */}
        <div>
          <label className="block text-sm font-medium text-gray-800">Goal Name:</label>
          <input
            type="text"
            value={goalName}
            onChange={(e) => setGoalName(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
            required
          />
        </div>
        {/* Goal Amount */}
        <div>
          <label className="block text-sm font-medium text-gray-800">Goal Amount:</label>
          <input
            type="number"
            value={goalAmount}
            onChange={(e) => setGoalAmount(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
            required
            min="0"
            step="0.01"
          />
        </div>
        {/* Target Date */}
        <div>
          <label className="block text-sm font-medium text-gray-800">Target Date:</label>
          <input
            type="date"
            value={targetDate}
            onChange={(e) => setTargetDate(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
          />
        </div>
        {/* Description */}
        <div>
          <label className="block text-sm font-medium text-gray-800">Description:</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
          ></textarea>
        </div>

        {/* Submit Button */}
        <div className="text-center">
          <button
            type="submit"
            className="w-full sm:w-auto px-6 py-2 bg-green-600 text-white rounded-md shadow hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
          >
            {editGoalId ? 'Update Savings Goal' : 'Add Savings Goal'}
          </button>
        </div>
      </form>

      {/* List of Savings Goals */}
      {!isModal && savingsGoals.length > 0 && (
        <div className="mt-8">
          <h4 className="text-lg font-bold mb-4 text-center">Your Savings Goals</h4>
          <ul className="space-y-4">
            {savingsGoals.map((goal) => {
              const progress = goal.goalAmount
                ? ((goal.amountSaved || 0) / goal.goalAmount) * 100
                : 0;
              const progressBarWidth = progress > 100 ? 100 : progress;
              return (
                <li
                  key={goal.id}
                  className="border p-4 rounded-lg shadow-sm flex flex-col md:flex-row justify-between items-start md:items-center"
                >
                  <div className="mb-4 md:mb-0 md:flex-1">
                    <p className="text-sm">
                      <strong>Name:</strong> {goal.goalName}
                    </p>
                    <p className="text-sm">
                      <strong>Goal Amount:</strong> ${goal.goalAmount.toFixed(2)}
                    </p>
                    <p className="text-sm">
                      <strong>Amount Saved:</strong> ${goal.amountSaved ? goal.amountSaved.toFixed(2) : '0.00'}
                    </p>
                    {goal.targetDate && (
                      <p className="text-sm">
                        <strong>Target Date:</strong> {new Date(goal.targetDate).toLocaleDateString()}
                      </p>
                    )}
                    {goal.description && (
                      <p className="text-sm">
                        <strong>Description:</strong> {goal.description}
                      </p>
                    )}
                    {/* Progress Bar */}
                    <div className="mt-2">
                      <div className="w-full bg-gray-200 rounded-full h-4">
                        <div
                          className="h-4 rounded-full bg-purple-500"
                          style={{ width: `${progressBarWidth}%` }}
                        ></div>
                      </div>
                      <p className="text-sm text-gray-600 mt-1">{progress.toFixed(2)}% achieved</p>
                    </div>
                  </div>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => handleEditGoal(goal)}
                      className="bg-blue-500 text-white px-3 py-1 rounded-md shadow hover:bg-blue-600 text-sm"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDeleteGoal(goal.id)}
                      className="bg-red-500 text-white px-3 py-1 rounded-md shadow hover:bg-red-600 text-sm"
                    >
                      Delete
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SavingsGoalForm;
