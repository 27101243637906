// src/components/LandingPage.js

import React from 'react';
import { Link } from 'react-router-dom';
import {
  FiCheckCircle,
  FiUser,
  FiDollarSign,
  FiMenu,
  FiHeart,
  FiUsers
} from 'react-icons/fi';
import {
  FaUsers,
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaArrowDown,
} from 'react-icons/fa';
import { motion } from 'framer-motion';
import Slider from 'react-slick'; // For testimonials carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Helmet } from 'react-helmet';
import { useTypewriter, Cursor } from 'react-simple-typewriter';

// Import your images
import heroImage1 from '../assets/images/website-image-1.webp';
import heroImage2 from '../assets/images/website-image-2.webp';
import heroImage3 from '../assets/images/website-image-3.webp';
import selfCareImage from '../assets/images/self-care-image.jpg';
import logo from '../assets/images/logo.png'; // Import the logo image

// Reusable Feature Component with Enhanced Animations and Accessibility
const Feature = ({ icon: Icon, title, description, delay }) => (
  <motion.div
    className="flex flex-col items-center text-center p-6 bg-white rounded-lg shadow hover:shadow-xl transition-shadow duration-300"
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.6, delay }}
    whileHover={{ scale: 1.05 }}
    tabIndex="0" // Make focusable
    role="article"
    aria-labelledby={`feature-title-${title}`}
  >
    <Icon className="text-blue-800 w-12 h-12 mb-4" aria-hidden="true" />
    <h3 id={`feature-title-${title}`} className="text-xl font-semibold mb-2">
      {title}
    </h3>
    <p className="text-gray-600">{description}</p>
  </motion.div>
);

// Reusable Testimonial Component with Fade-In Animation and Accessibility
const Testimonial = ({ icon: Icon, name, role, feedback }) => (
  <motion.div
    className="bg-gray-100 p-8 rounded-lg shadow-md flex flex-col items-center text-center"
    initial={{ opacity: 0, scale: 0.95 }}
    whileInView={{ opacity: 1, scale: 1 }}
    viewport={{ once: true }}
    transition={{ duration: 0.6 }}
    tabIndex="0" // Make focusable
    role="blockquote"
    aria-label={`Testimonial from ${name}, ${role}`}
  >
    <Icon className="text-blue-800 w-8 h-8 mb-4" aria-hidden="true" />
    <h4 className="text-lg font-semibold">{name}</h4>
    <p className="text-sm text-gray-500 mb-4">{role}</p>
    <p className="text-gray-700">"{feedback}"</p>
  </motion.div>
);

// Navigation Bar Component with Sticky Behavior and Animated Links
const Navbar = () => (
  <nav className="fixed top-0 left-0 w-full bg-white shadow-md z-50 transition-all duration-300">
    <div className="container mx-auto px-6 py-4 flex justify-between items-center">
      {/* Logo Placement */}
      <div className="flex items-center">
        <img src={logo} alt="Atlas Budget Logo" className="w-10 h-10 mr-2" />
        <h2 className="text-xl font-bold text-gray-800">Atlas Budget</h2>
      </div>
      
      {/* Navigation Links */}
      <div className="hidden md:flex space-x-6">
        <Link to="/signup">
          <motion.button
            className="bg-blue-800 text-white px-4 py-2 rounded-full shadow hover:bg-blue-900 transition focus:outline-none focus:ring-2 focus:ring-blue-500"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            aria-label="Get Started"
          >
            Get Started
          </motion.button>
        </Link>
        <Link to="/login">
          <motion.button
            className="bg-orange-500 text-white px-4 py-2 rounded-full shadow hover:bg-orange-600 transition focus:outline-none focus:ring-2 focus:ring-orange-400"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            aria-label="Log In"
          >
            Log In
          </motion.button>
        </Link>
      </div>
      
      {/* Mobile Menu Button */}
      <div className="md:hidden">
        <motion.button
          className="text-gray-700 focus:outline-none"
          whileHover={{ rotate: 90 }}
          whileTap={{ scale: 0.9 }}
          aria-label="Open Menu"
        >
          <FiMenu className="w-6 h-6" />
        </motion.button>
      </div>
    </div>
  </nav>
);

// Reusable Floating Shape Component
const FloatingShape = ({ size, color, initialX, initialY, duration, delay }) => (
  <motion.div
    className="absolute"
    style={{
      width: size,
      height: size,
      backgroundColor: color,
      borderRadius: '50%',
      top: initialY,
      left: initialX,
    }}
    animate={{
      y: [0, -20, 0],
      x: [0, 30, 0],
    }}
    transition={{
      repeat: Infinity,
      duration: duration,
      delay: delay,
      ease: 'easeInOut',
    }}
  />
);

const LandingPage = () => {
  // Settings for the testimonials carousel
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    arrows: false,
    fade: true,
    pauseOnHover: true,
    accessibility: true,
    adaptiveHeight: true,
  };

  // Sample Features Data
  const featuresData = [
    {
      icon: FiDollarSign,
      title: 'Easy Tracking',
      description:
        'Monitor your expenses in real-time with our intuitive dashboard, reducing financial stress.',
    },
    {
      icon: FiCheckCircle,
      title: 'Smart Analytics',
      description:
        'Gain insights into your spending habits to make mindful financial decisions.',
    },
    {
      icon: FiUser,
      title: 'User-Friendly',
      description:
        'Designed with simplicity in mind, making budgeting a relaxing and accessible experience.',
    },
    {
      icon: FiHeart,
      title: 'Self-Care Insights',
      description:
        'Receive personalized tips that integrate financial health with overall well-being.',
    },
  ];

  // Sample Testimonials Data
  const testimonialsData = [
    {
      icon: FaUsers,
      name: 'Greg Woods',
      role: 'Small Business Owner',
      feedback:
        'Atlas Budget not only streamlined my finances but also gave me peace of mind. Managing my budget feels like taking care of myself.',
    },
    {
      icon: FaUsers,
      name: 'Rhia McQueen',
      role: 'Freelancer',
      feedback:
        'As a freelancer, managing irregular income was challenging. Atlas Budget makes it seamless and reduces my financial anxiety.',
    },
    {
      icon: FaUsers,
      name: 'Emily Johnson',
      role: 'Student',
      feedback:
        'Balancing my studies and finances was tough. Atlas Budget keeps me on track effortlessly, allowing me to focus on my well-being.',
    },
    // Add more testimonials as needed
  ];

  // Typing Effect Hook from react-simple-typewriter
  const [text] = useTypewriter({
    words: ['Budgeting is Self Care', 'Finance Made Simple', 'Your Path to Financial Freedom'],
    loop: 0, // 0 = infinite
    typeSpeed: 70,
    deleteSpeed: 50,
    delaySpeed: 1000,
  });

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      
      {/* Meta Tags for SEO */}
      <Helmet>
        <title>Atlas Budget - Master Your Finances Effortlessly</title>
        <meta
          name="description"
          content="Take control of your finances with Atlas Budget's intuitive tools and insightful analytics, nurturing your financial well-being."
        />
        <meta
          name="keywords"
          content="Budgeting, Finance, Expense Tracking, Financial Well-being, Atlas Budget"
        />
        <meta name="author" content="Atlas Budget Team" />
        {/* Canonical Link */}
        <link rel="canonical" href="https://www.atlasbudget.com/" />
        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "Atlas Budget",
            "url": "https://www.atlasbudget.com",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://www.atlasbudget.com/search?q={search_term_string}",
              "query-input": "required name=search_term_string",
            },
          })}
        </script>
      </Helmet>
      
      {/* Navigation Bar */}
      <Navbar />

      {/* Main Content Wrapped in <main> for Semantic HTML */}
      <main>
        {/* Hero Section without Logo */}
        <section
          className="relative h-screen flex flex-col md:flex-row items-center justify-center bg-blue-800 text-white overflow-hidden"
        >
          {/* Left Side - Text and CTA */}
          <div className="w-full md:w-1/2 px-6 md:px-12 flex flex-col items-center justify-center">
            {/* Animated Headline with Typing Effect */}
            <motion.h1
              className="text-4xl md:text-5xl font-bold mb-4 text-center font-montserrat"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              {text}
              <Cursor />
            </motion.h1>
            {/* Animated Subheadline */}
            <motion.h2
              className="text-2xl md:text-3xl font-semibold mb-6 text-center"
              initial={{ opacity: 0, y: -30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.3 }}
            >
              Master Your Budget Effortlessly
            </motion.h2>
            {/* Animated Paragraph */}
            <motion.p
              className="text-lg md:text-xl mb-8 max-w-md text-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.5 }}
            >
              Take control of your finances with Atlas Budget's intuitive tools and insightful analytics, nurturing your financial well-being.
            </motion.p>
            {/* **Added Description and Selling Points** */}
            <motion.p
              className="text-md md:text-lg mb-8 max-w-md text-center text-gray-200"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.7 }}
            >
              Whether you're looking to save for a goal, reduce unnecessary expenses, or simply understand where your money goes, Atlas Budget is your trusted companion in the journey towards financial freedom.
            </motion.p>
            {/* **Added Selling Points as Bullet List** */}
            <motion.ul
              className="list-disc list-inside text-md md:text-lg mb-8 max-w-md text-center text-gray-200"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.9 }}
            >
              <li>🔍 <strong>Detailed Expense Tracking</strong> - Keep a close eye on your spending habits.</li>
              <li>📈 <strong>Comprehensive Analytics</strong> - Gain valuable insights to optimize your budget.</li>
              <li>💡 <strong>Personalized Tips</strong> - Receive tailored advice to improve your financial health.</li>
              <li>🛡️ <strong>Secure and Private</strong> - Your data is safe with us.</li>
            </motion.ul>
            {/* Animated CTA Buttons */}
            <motion.div
              className="flex flex-row justify-center gap-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 1 }}
            >
              <Link to="/signup">
                <motion.button
                  className="bg-orange-500 text-white font-semibold py-3 px-6 rounded-full shadow hover:bg-orange-600 transition focus:outline-none focus:ring-2 focus:ring-orange-400"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  aria-label="Get Started"
                >
                  Get Started
                </motion.button>
              </Link>
              <Link to="/login">
                <motion.button
                  className="bg-transparent border border-orange-500 text-orange-500 font-semibold py-3 px-6 rounded-full hover:bg-orange-500 hover:text-white transition focus:outline-none focus:ring-2 focus:ring-orange-400"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  aria-label="Log In"
                >
                  Log In
                </motion.button>
              </Link>
            </motion.div>
          </div>

          {/* Right Side - Enlarged Image */}
          <div className="w-full md:w-1/2 px-6 md:px-12 flex items-center justify-center">
            <motion.img
              src={heroImage1}
              alt="Illustration representing budgeting and self-care"
              className="w-full h-full object-cover rounded-lg shadow-lg"
              loading="lazy"
              srcSet={`${heroImage1} 1x, ${heroImage1} 2x`}
              sizes="(max-width: 768px) 100vw, 50vw"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.7 }}
            />
          </div>

          {/* Animated Floating Shapes */}
          <FloatingShape
            size="60px"
            color="rgba(255, 255, 255, 0.2)"
            initialX="15%"
            initialY="25%"
            duration={6}
            delay={0}
          />
          <FloatingShape
            size="40px"
            color="rgba(255, 255, 255, 0.3)"
            initialX="80%"
            initialY="10%"
            duration={8}
            delay={2}
          />
          <FloatingShape
            size="50px"
            color="rgba(255, 255, 255, 0.25)"
            initialX="50%"
            initialY="70%"
            duration={7}
            delay={1}
          />

          {/* Scroll Indicator */}
          <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2">
            <FaArrowDown className="animate-bounce w-6 h-6 text-gray-200" aria-hidden="true" />
            <span className="sr-only">Scroll Down</span>
          </div>
        </section>
        
        {/* Features Section with Staggered Animations */}
        <section className="py-16 bg-gray-50">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold text-center mb-12">Why Choose Atlas Budget?</h2>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
              {featuresData.map((feature, index) => (
                <Feature
                  key={index}
                  icon={feature.icon}
                  title={feature.title}
                  description={feature.description}
                  delay={index * 0.2}
                />
              ))}
            </div>
          </div>
        </section>
        
        {/* Self-Care Section with Scroll Animations */}
        <section className="py-16 bg-white">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold text-center mb-12">Budgeting as Self Care</h2>
            <div className="flex flex-col md:flex-row items-center">
              <motion.img
                src={selfCareImage} // Replace with an appropriate image
                alt="Person practicing self-care with finances"
                className="w-full md:w-1/2 rounded-lg shadow-md mb-8 md:mb-0"
                loading="lazy"
                srcSet={`${selfCareImage} 1x, ${selfCareImage} 2x`}
                sizes="(max-width: 768px) 100vw, 50vw"
                initial={{ opacity: 0, x: -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8 }}
              />
              <motion.div
                className="md:ml-12"
                initial={{ opacity: 0, x: 100 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, delay: 0.2 }}
              >
                <h3 className="text-2xl font-semibold mb-4">Why Budgeting is Essential for Your Well-Being</h3>
                <p className="text-gray-600 mb-4">
                  Managing your finances effectively reduces stress and creates a sense of control over your life. Atlas Budget empowers you to make informed decisions, fostering a healthier relationship with money.
                </p>
                <p className="text-gray-600">
                  By integrating budgeting into your daily routine, you prioritize your financial health as a fundamental aspect of self-care.
                </p>
              </motion.div>
            </div>
          </div>
        </section>
        
        {/* Benefits Section */}
        <section className="py-16 bg-gray-50">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold text-center mb-12">Benefits of Using Atlas Budget</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="flex flex-col items-center text-center p-6 bg-white rounded-lg shadow-md">
                <FiDollarSign className="text-blue-800 w-12 h-12 mb-4" aria-hidden="true" />
                <h3 className="text-xl font-semibold mb-2">Achieve Financial Goals</h3>
                <p className="text-gray-600">
                  Set and reach your financial targets with clear planning and actionable insights.
                </p>
              </div>
              <div className="flex flex-col items-center text-center p-6 bg-white rounded-lg shadow-md">
                <FiCheckCircle className="text-blue-800 w-12 h-12 mb-4" aria-hidden="true" />
                <h3 className="text-xl font-semibold mb-2">Simplify Budgeting</h3>
                <p className="text-gray-600">
                  Streamline your budgeting process with user-friendly tools designed for everyone.
                </p>
              </div>
              <div className="flex flex-col items-center text-center p-6 bg-white rounded-lg shadow-md">
                <FiUser className="text-blue-800 w-12 h-12 mb-4" aria-hidden="true" />
                <h3 className="text-xl font-semibold mb-2">Personalized Support</h3>
                <p className="text-gray-600">
                  Receive customized advice and support tailored to your unique financial situation.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Social Proof / Testimonials Section with Enhanced Carousel */}
        <section className="py-16 bg-gray-50">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold text-center mb-12">What Our Users Say</h2>
            <Slider {...sliderSettings}>
              {testimonialsData.map((testimonial, index) => (
                <div key={index} className="px-4">
                  <Testimonial
                    icon={testimonial.icon}
                    name={testimonial.name}
                    role={testimonial.role}
                    feedback={testimonial.feedback}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </section>
        
        {/* Additional Features / Interactive Elements Section */}
        <section className="py-16 bg-white">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold text-center mb-12">Discover More Features</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              
              {/* Feature/Image 1 */}
              <motion.div
                className="flex flex-col justify-center p-6 bg-gray-100 rounded-lg shadow-md"
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, delay: 0.2 }}
              >
                <FiUsers className="text-blue-800 w-12 h-12 mb-4" aria-hidden="true" />
                <h3 className="text-xl font-semibold mb-2">Collaborative Planning</h3>
                <p className="text-gray-600 mb-4">
                  Plan your finances collaboratively with family or team members, ensuring everyone is aligned towards financial goals.
                </p>
                <motion.img
                  src={heroImage2}
                  alt="Collaborative Planning Illustration"
                  className="w-full h-48 object-cover rounded-md shadow-inner"
                  loading="lazy"
                  srcSet={`${heroImage2} 1x, ${heroImage2} 2x`}
                  sizes="(max-width: 768px) 100vw, 50vw"
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.3 }}
                />
              </motion.div>
              
              {/* Feature/Image 2 */}
              <motion.div
                className="flex flex-col justify-center p-6 bg-gray-100 rounded-lg shadow-md"
                initial={{ opacity: 0, x: 50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, delay: 0.4 }}
              >
                <FiCheckCircle className="text-blue-800 w-12 h-12 mb-4" aria-hidden="true" />
                <h3 className="text-xl font-semibold mb-2">Automated Insights</h3>
                <p className="text-gray-600 mb-4">
                  Receive personalized financial advice and automated insights to optimize your spending and savings.
                </p>
                <motion.img
                  src={heroImage3}
                  alt="Automated Insights Illustration"
                  className="w-full h-48 object-cover rounded-md shadow-inner"
                  loading="lazy"
                  srcSet={`${heroImage3} 1x, ${heroImage3} 2x`}
                  sizes="(max-width: 768px) 100vw, 50vw"
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.3 }}
                />
              </motion.div>
            </div>
          </div>
        </section>
        
        {/* Final Call to Action Section with Gradient Background */}
        <section className="bg-gradient-to-r from-blue-800 to-orange-500 text-white py-16">
          <div className="container mx-auto px-6 text-center">
            <motion.h2
              className="text-3xl font-bold mb-4"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
            >
              Take Control of Your Finances Today
            </motion.h2>
            <motion.p
              className="text-lg mb-8"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, delay: 0.3 }}
            >
              Join thousands of users who have streamlined their budgeting process with Atlas Budget.
            </motion.p>
            <motion.div
              className="flex flex-row justify-center gap-4"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, delay: 0.6 }}
            >
              <Link to="/signup">
                <motion.button
                  className="bg-orange-500 text-white font-semibold py-3 px-6 rounded-full shadow hover:bg-orange-600 transition focus:outline-none focus:ring-2 focus:ring-orange-400"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  aria-label="Get Started"
                >
                  Get Started
                </motion.button>
              </Link>
              <Link to="/login">
                <motion.button
                  className="bg-transparent border border-orange-500 text-orange-500 font-semibold py-3 px-6 rounded-full hover:bg-orange-500 hover:text-white transition focus:outline-none focus:ring-2 focus:ring-orange-400"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  aria-label="Log In"
                >
                  Log In
                </motion.button>
              </Link>
            </motion.div>
          </div>
        </section>
      </main>
      
      {/* Footer with Social Media Icons and Animated Links */}
      <footer className="bg-gray-800 text-gray-300 py-8">
        <div className="container mx-auto px-6 flex flex-col md:flex-row justify-between items-center">
          
          {/* Logo / Brand Name */}
          <div className="mb-4 md:mb-0 flex items-center">
            <img src={logo} alt="Atlas Budget Logo" className="w-8 h-8 mr-2" />
            <h2 className="text-xl font-bold text-white">Atlas Budget</h2>
          </div>
          
          {/* Footer Links */}
          <div className="flex flex-col sm:flex-row gap-4 items-center">
            <Link to="/privacy-policy" className="hover:text-white transition">
              Privacy Policy
            </Link>
            <Link to="/user-rights" className="hover:text-white transition">
              User Rights
            </Link>
            <Link to="/terms-and-conditions" className="hover:text-white transition">
              Terms and Conditions
            </Link>
          </div>
          
          {/* Social Media Icons */}
          <div className="flex mt-4 md:mt-0 space-x-4">
            <motion.a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-white transition"
              whileHover={{ scale: 1.2, color: '#3b5998' }}
              aria-label="Facebook"
            >
              <FaFacebookF className="w-5 h-5" aria-hidden="true" />
            </motion.a>
            <motion.a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-white transition"
              whileHover={{ scale: 1.2, color: '#1DA1F2' }}
              aria-label="Twitter"
            >
              <FaTwitter className="w-5 h-5" aria-hidden="true" />
            </motion.a>
            <motion.a
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-white transition"
              whileHover={{ scale: 1.2, color: '#E1306C' }}
              aria-label="Instagram"
            >
              <FaInstagram className="w-5 h-5" aria-hidden="true" />
            </motion.a>
            <motion.a
              href="https://linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-white transition"
              whileHover={{ scale: 1.2, color: '#0077B5' }}
              aria-label="LinkedIn"
            >
              <FaLinkedinIn className="w-5 h-5" aria-hidden="true" />
            </motion.a>
          </div>
        </div>
      </footer>
      
    </div>
  );
};

export default LandingPage;
