// src/components/AssetsDashboard.js

import React, { useEffect, useState, useCallback, useRef, useContext } from 'react';
import axios from 'axios';
import PortfolioChart from './PortfolioChart';
import AssetChart from './AssetChart';
import { FaPlus, FaTrash, FaArrowLeft, FaInfoCircle } from 'react-icons/fa'; // Added FaInfoCircle
import { toast } from 'react-toastify';
import { useAuth } from '../contexts/AuthContext';
import { ProfileContext } from '../contexts/ProfileContext';
import { db } from '../firebase-config';
import {
  collection,
  setDoc,
  updateDoc,
  deleteDoc,
  getDocs,
  doc,
  onSnapshot,
  query,
  where,
  serverTimestamp,
} from 'firebase/firestore';
import { format } from 'date-fns'; // Ensure date-fns is installed: npm install date-fns

const CRYPTO_TIMEFRAMES = {
  '1D': { days: 1, isHourly: true },
  '7D': { days: 7, isHourly: false },
  '30D': { days: 30, isHourly: false },
  '1Y': { days: 365, isHourly: false },
};

// Debounce function to limit API calls
const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};

const AssetsDashboard = () => {
  const { user } = useAuth();
  const { activeProfileId, profilesLoading } = useContext(ProfileContext);
  const userId = user?.uid;
  const profileId = activeProfileId;

  const [assets, setAssets] = useState([]);
  const [prices, setPrices] = useState({});
  const [portfolioValue, setPortfolioValue] = useState(0);

  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  
  const [portfolioChartTimeframe, setPortfolioChartTimeframe] = useState('30D'); // Default to last 30 days
  const [assetChartTimeframe, setAssetChartTimeframe] = useState('1D'); // Default to 1 Day

  const [showAddForm, setShowAddForm] = useState(false);
  const [addQuantity, setAddQuantity] = useState('');
  const [addCostBasis, setAddCostBasis] = useState('');

  const [portfolioChartData, setPortfolioChartData] = useState([]);
  const [isPortfolioLoading, setIsPortfolioLoading] = useState(false);
  const [portfolioError, setPortfolioError] = useState(null);

  // Reference for polling interval
  const pollingRef = useRef(null);

  // Debounced search to limit API calls
  const debouncedSearch = useRef(
    debounce(async (query) => {
      if (query.trim().length < 2) {
        setSearchResults([]);
        return;
      }

      try {
        // Crypto search via CoinGecko with API key
        const res = await axios.get('https://api.coingecko.com/api/v3/search', {
          params: { query },
          headers: { 'x-cg-demo-api-key': process.env.REACT_APP_COINGECKO_API_KEY },
        });
        const coins = res.data.coins.map((c) => ({
          symbol: c.symbol.toUpperCase(),
          name: c.name,
          id: c.id,
          type: 'crypto',
        }));
        if (coins.length === 0) toast.info('No crypto results found for that query.');
        setSearchResults(coins);
      } catch (error) {
        console.error('Error searching crypto:', error);
        if (error.response && error.response.status === 429) {
          toast.error('CoinGecko rate limit hit. Please wait before searching again.');
        } else if (error.message.includes('CORS')) {
          toast.error('CORS issue encountered. Please try again later or use a proxy.');
        } else {
          toast.error('Error searching assets.');
        }
        setSearchResults([]);
      }
    }, 500)
  ).current;

  // Subscribe to user's assets in Firestore
  useEffect(() => {
    if (userId && profileId) {
      const assetsCollection = collection(db, 'users', userId, 'profiles', profileId, 'assets');
      const unsubscribe = onSnapshot(
        assetsCollection,
        (snapshot) => {
          const fetchedAssets = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setAssets(fetchedAssets);
        },
        (error) => {
          console.error('Error subscribing to assets:', error);
          toast.error('Failed to subscribe to assets.');
        }
      );
      return () => {
        unsubscribe();
      };
    }
  }, [userId, profileId]);

  // Fetch crypto prices from CoinGecko
  const fetchCryptoPrices = useCallback(async () => {
    if (assets.length === 0) {
      setPrices({});
      return;
    }

    const cryptoAssets = assets.filter((a) => a.type === 'crypto');
    const cryptoIds = cryptoAssets.map((a) => a.id).join(',');

    if (!cryptoIds) {
      setPrices({});
      return;
    }

    try {
      const res = await axios.get('https://api.coingecko.com/api/v3/simple/price', {
        params: {
          ids: cryptoIds,
          vs_currencies: 'usd',
        },
        headers: { 'x-cg-demo-api-key': process.env.REACT_APP_COINGECKO_API_KEY },
      });

      const newPrices = {};
      cryptoAssets.forEach((asset) => {
        if (res.data[asset.id]) {
          newPrices[asset.symbol] = res.data[asset.id].usd;
        }
      });

      setPrices(newPrices);
    } catch (error) {
      console.error('Error fetching crypto prices:', error);
      if (error.response && error.response.status === 429) {
        toast.error('CoinGecko rate limit hit. Prices may not be updated in real-time.');
      } else if (error.message.includes('CORS')) {
        toast.error('CORS issue encountered. Please try again later or use a proxy.');
      } else {
        toast.error('Error fetching crypto prices.');
      }
    }
  }, [assets]);

  // Calculate portfolio value
  useEffect(() => {
    let total = 0;
    for (let asset of assets) {
      const price = prices[asset.symbol] || asset.price || 0;
      total += price * asset.quantity;
    }
    setPortfolioValue(total);
  }, [assets, prices]);

  // Initialize polling for real-time updates
  useEffect(() => {
    fetchCryptoPrices(); // Initial fetch

    // Clear any existing intervals
    if (pollingRef.current) clearInterval(pollingRef.current);

    // Set up polling every 60 seconds
    pollingRef.current = setInterval(() => {
      fetchCryptoPrices();
    }, 60000); // 60,000 ms = 60 seconds

    return () => {
      if (pollingRef.current) clearInterval(pollingRef.current);
    };
  }, [fetchCryptoPrices]);

  // Debounced search effect
  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery, debouncedSearch]);

  // Function: Handle inline editing
  const handleInlineEdit = async (assetId, field, value) => {
    if (value === '' || isNaN(value) || Number(value) <= 0) {
      toast.error(`Invalid ${field}`);
      return;
    }

    // If editing Cost Basis, limit to 2 decimal places
    if (field === 'costBasis') {
      value = parseFloat(value).toFixed(2);
    }

    try {
      const assetDocRef = doc(db, 'users', userId, 'profiles', profileId, 'assets', assetId);
      const updatedData = {};

      if (field === 'quantity') {
        updatedData.quantity = Number(value);
      } else if (field === 'costBasis') {
        updatedData.costBasis = Number(value);
      }

      await updateDoc(assetDocRef, updatedData);
      toast.success(`${field === 'quantity' ? 'Quantity' : 'Cost Basis'} updated successfully!`);
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
      toast.error(`Error updating ${field}.`);
    }
  };

  // Function: Handle returning to dashboard from detailed view
  const handleBackToDashboard = () => {
    setSelectedAsset(null);
    setShowAddForm(false);
    setAddQuantity('');
    setAddCostBasis('');
    setAssetChartTimeframe('1D'); // Reset AssetChart timeframe to default
  };

  // Function: Confirm adding to portfolio
  const confirmAddToPortfolio = async (e) => {
    e.preventDefault();
    if (!addQuantity || Number(addQuantity) <= 0) {
      toast.error('Invalid quantity');
      return;
    }
    if (!addCostBasis || Number(addCostBasis) <= 0) {
      toast.error('Invalid cost basis');
      return;
    }
    if (!userId || !profileId || !selectedAsset) {
      console.error('Missing user, profile, or asset info:', { userId, profileId, selectedAsset });
      toast.error('Unable to add asset. User or profile not set.');
      return;
    }

    try {
      const assetsCollection = collection(db, 'users', userId, 'profiles', profileId, 'assets');
      const q = query(assetsCollection, where('symbol', '==', selectedAsset.symbol));

      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        // Asset exists, update it
        const docRef = querySnapshot.docs[0].ref;
        const existingData = querySnapshot.docs[0].data();
        const newQuantity = existingData.quantity + Number(addQuantity);
        const newCostBasis =
          ((existingData.costBasis * existingData.quantity) + (Number(addCostBasis) * Number(addQuantity))) /
          newQuantity;

        await updateDoc(docRef, {
          quantity: newQuantity,
          costBasis: Number(newCostBasis.toFixed(2)), // Limit to 2 decimal places
        });
        toast.success(`${selectedAsset.symbol} quantity updated in your portfolio!`);
      } else {
        // Add new asset using setDoc with CoinGecko id as document ID
        const assetDocRef = doc(db, 'users', userId, 'profiles', profileId, 'assets', selectedAsset.id);
        await setDoc(assetDocRef, {
          symbol: selectedAsset.symbol,
          type: selectedAsset.type,
          name: selectedAsset.name,
          quantity: Number(addQuantity),
          costBasis: Number(parseFloat(addCostBasis).toFixed(2)), // Ensure two decimal places
          addedAt: serverTimestamp(), // Track when the asset was added
        });
        toast.success(`${selectedAsset.symbol} added to your portfolio!`);
      }

      setAddQuantity('');
      setAddCostBasis('');
      setShowAddForm(false);
    } catch (error) {
      console.error('Error adding/updating asset:', error);
      toast.error('Error adding/updating asset.');
    }
  };

  // Function: Handle selecting a search result
  const handleSelectResult = async (asset) => {
    setSearchResults([]);
    setShowAddForm(false);
    setAddQuantity('');
    setAddCostBasis('');
    setAssetChartTimeframe('1D'); // Reset AssetChart timeframe to default

    if (!asset.symbol) {
      toast.error('Invalid asset selected.');
      return;
    }

    let symbol = asset.symbol;
    let type = asset.type;
    let name = asset.name;
    let price = 0;
    let chartData = [];
    let marketCap = null;
    let changePercent = null;

    try {
      const coinId = asset.id;
      const marketRes = await axios.get(`https://api.coingecko.com/api/v3/coins/markets`, {
        params: { vs_currency: 'usd', ids: coinId },
        headers: { 'x-cg-demo-api-key': process.env.REACT_APP_COINGECKO_API_KEY },
      });
      if (marketRes.data.length > 0) {
        const c = marketRes.data[0];
        price = c.current_price;
        marketCap = c.market_cap;
        changePercent = c.price_change_percentage_24h;
      }

      // Fetch chart data without quantity
      chartData = await fetchCryptoChart(coinId, assetChartTimeframe);
    } catch (error) {
      console.error('Error selecting asset data:', error);
      if (error.response && error.response.status === 429) {
        toast.error('CoinGecko rate limit hit. Please try again later.');
      } else if (error.message.includes('CORS')) {
        toast.error('CORS issue encountered. Please try again later or use a proxy.');
      } else {
        toast.error('Error fetching asset data. Please try again later.');
      }
      return;
    }

    setSelectedAsset({ symbol, type, name, price, chartData, marketCap, changePercent, id: asset.id });
  };

  // Function: Fetch crypto chart data from CoinGecko
  const fetchCryptoChart = async (coinId, tf) => { // Removed 'quantity' parameter
    const { days, isHourly } = CRYPTO_TIMEFRAMES[tf];

    try {
      const res = await axios.get(
        `https://api.coingecko.com/api/v3/coins/${coinId}/market_chart`,
        {
          params: { vs_currency: 'usd', days },
          headers: { 'x-cg-demo-api-key': process.env.REACT_APP_COINGECKO_API_KEY },
        }
      );

      let data;
      if (isHourly) {
        // For '1D', format date to include hour
        data = res.data.prices.map(([timestamp, p]) => ({
          date: format(new Date(timestamp), 'yyyy-MM-dd HH:00'), // 'yyyy-MM-dd HH:00' format
          price: p,
        }));
      } else {
        // For other timeframes, correctly destructure [timestamp, p]
        data = res.data.prices.map(([timestamp, p]) => ({
          date: format(new Date(timestamp), 'yyyy-MM-dd'),
          price: p,
        }));
      }

      if (isHourly) {
        const now = new Date();
        data = data.filter((d) => {
          const date = new Date(`${d.date}:00`); // Append seconds to create a valid Date object
          return date <= now;
        });
      }

      if (data.length === 0) {
        toast.warning('No data available for this timeframe.');
        return [];
      }

      return data.map((d) => ({
        time: isHourly ? d.date.split(' ')[1] : d.date, // 'HH:00' or 'yyyy-MM-dd'
        price: d.price, // Return price instead of totalValue
      }));
    } catch (error) {
      console.error('Error fetching crypto chart:', error);
      if (error.response && error.response.status === 429) {
        toast.error('CoinGecko rate limit hit. Try again later.');
      } else if (error.message.includes('CORS')) {
        toast.error('CORS issue encountered. Please try again later or use a proxy.');
      } else {
        toast.error('Error fetching crypto chart.');
      }
      return [];
    }
  };

  // Function: Handle changing timeframe for AssetChart
  const changeAssetChartTimeframe = async (tf) => {
    setAssetChartTimeframe(tf);
    if (!selectedAsset) return;

    try {
      let chartData = [];
      if (selectedAsset.type === 'crypto') {
        const coinId = selectedAsset.id;
        chartData = await fetchCryptoChart(coinId, tf); // Removed quantity
      }
      setSelectedAsset((prev) => ({ ...prev, chartData }));
    } catch (error) {
      console.error('Error changing asset chart timeframe:', error);
      toast.error('Error updating chart. Try again later.');
    }
  };

  // Function: Handle changing timeframe for PortfolioChart
  const changePortfolioChartTimeframe = async (tf) => {
    setPortfolioChartTimeframe(tf);
    fetchPortfolioHistoricalData(tf);
  };

  // Function: Description based on timeframe for AssetChart
  const assetTimeframeDescription = () => {
    switch (assetChartTimeframe) {
      case '1D':
        return "Showing today's intraday crypto data";
      case '7D':
        return 'Showing the last 7 days of crypto data';
      case '30D':
        return 'Showing the last 30 days of crypto data';
      case '1Y':
        return 'Showing the last 1 year of crypto data';
      default:
        return '';
    }
  };

  // Function: Description based on timeframe for PortfolioChart
  const portfolioTimeframeDescription = () => {
    switch (portfolioChartTimeframe) {
      case '1D':
        return "Showing today's intraday portfolio data";
      case '7D':
        return 'Showing the last 7 days of portfolio data';
      case '30D':
        return 'Showing the last 30 days of portfolio data';
      case '1Y':
        return 'Showing the last 1 year of portfolio data';
      default:
        return '';
    }
  };

  // Function: Fetch and aggregate portfolio historical data
  const fetchPortfolioHistoricalData = useCallback(
    async (tf) => {
      if (assets.length === 0) {
        setPortfolioChartData([]);
        return;
      }

      setIsPortfolioLoading(true);
      setPortfolioError(null);

      try {
        const { days, isHourly } = CRYPTO_TIMEFRAMES[tf];

        // Function to fetch historical data for a single asset
        const fetchAssetHistoricalData = async (asset) => {
          try {
            const res = await axios.get(
              `https://api.coingecko.com/api/v3/coins/${asset.id}/market_chart`,
              {
                params: { vs_currency: 'usd', days },
                headers: { 'x-cg-demo-api-key': process.env.REACT_APP_COINGECKO_API_KEY },
              }
            );

            let prices;
            if (isHourly) {
              // For '1D', format date to include hour
              prices = res.data.prices.map(([timestamp, p]) => ({
                date: format(new Date(timestamp), 'yyyy-MM-dd HH:00'), // 'yyyy-MM-dd HH:00' format
                price: p,
              }));
            } else {
              // For other timeframes, correctly destructure [timestamp, p]
              prices = res.data.prices.map(([timestamp, p]) => ({
                date: format(new Date(timestamp), 'yyyy-MM-dd'),
                price: p,
              }));
            }

            return prices;
          } catch (error) {
            console.error(`Error fetching historical data for ${asset.symbol}:`, error);
            throw error;
          }
        };

        // Fetch historical data for all assets in parallel
        const allHistoricalData = await Promise.all(assets.map((asset) => fetchAssetHistoricalData(asset)));
        console.log('All Historical Data:', allHistoricalData);

        const dateMap = {};
        const countMap = {}; // Initialize countMap to count data points per date

        // Generate date strings based on timeframe
        let dates;
        if (isHourly) {
          // For '1D', generate hourly timestamps up to the current hour
          const now = new Date();
          const currentHour = now.getHours();
          dates = Array.from({ length: currentHour + 1 }, (_, i) => {
            const date = new Date();
            date.setHours(i, 0, 0, 0);
            return format(date, 'yyyy-MM-dd HH:00');
          });
        } else {
          // For other timeframes, generate daily timestamps up to today
          dates = Array.from({ length: days }, (_, i) => {
            const date = new Date();
            date.setDate(date.getDate() - (days - 1 - i));
            return format(date, 'yyyy-MM-dd');
          });
        }
        console.log('Date Range:', dates);

        // Initialize dateMap and countMap with zero values
        dates.forEach((date) => {
          dateMap[date] = 0;
          countMap[date] = 0;
        });
        console.log('Initialized Date Map:', dateMap);
        console.log('Initialized Count Map:', countMap);

        // Aggregate data
        allHistoricalData.forEach((assetData, index) => {
          const asset = assets[index];
          const assetAddedAt = asset.addedAt && asset.addedAt.toDate ? asset.addedAt.toDate() : new Date(0);
          const assetAddedAtStr = isHourly
            ? format(assetAddedAt, 'yyyy-MM-dd HH:00')
            : format(assetAddedAt, 'yyyy-MM-dd');

          console.log(`Processing Asset: ${asset.symbol}, Added At: ${assetAddedAtStr}`);

          assetData.forEach((dataPoint) => {
            const { date, price } = dataPoint;
            if (date >= assetAddedAtStr && dateMap.hasOwnProperty(date)) {
              if (isHourly) {
                dateMap[date] += price * asset.quantity;
                countMap[date] += 1; // Increment count for averaging
              } else {
                // Overwrite to keep the latest price of the day
                dateMap[date] = price * asset.quantity;
              }
            }
          });
        });
        console.log('Aggregated Date Map (Before Averaging):', dateMap);
        console.log('Count Map:', countMap);

        // For hourly data, average the totalValue by the number of data points
        if (isHourly) {
          dates.forEach((date) => {
            if (countMap[date] > 0) {
              dateMap[date] = Number((dateMap[date] / countMap[date]).toFixed(2));
            }
          });
        }

        console.log('Aggregated Date Map (After Averaging):', dateMap);

        // Convert the dateMap to an array sorted by date
        const aggregatedData = dates.map((date) => ({
          date,
          totalValue: Number(dateMap[date].toFixed(2)),
        }));
        console.log('Aggregated Data for Chart:', aggregatedData);

        setPortfolioChartData(aggregatedData);
      } catch (error) {
        console.error('Error fetching portfolio historical data:', error);
        setPortfolioError('Failed to load portfolio historical data.');
        toast.error('Failed to load portfolio historical data.');
      } finally {
        setIsPortfolioLoading(false);
      }
    },
    [assets]
  );

  // Fetch portfolio historical data whenever portfolioChartTimeframe or assets change
  useEffect(() => {
    fetchPortfolioHistoricalData(portfolioChartTimeframe);
  }, [fetchPortfolioHistoricalData, portfolioChartTimeframe]);

  // Function: Handle deleting an asset
  const handleDeleteAsset = async (assetId, symbol) => {
    if (!window.confirm(`Are you sure you want to remove ${symbol} from your portfolio?`)) return;

    try {
      const assetDocRef = doc(db, 'users', userId, 'profiles', profileId, 'assets', assetId);
      await deleteDoc(assetDocRef);
      toast.success(`${symbol} removed from your portfolio.`);
    } catch (error) {
      console.error('Error deleting asset:', error);
      toast.error('Error deleting asset.');
    }
  };

  // Function: Get analytics for an asset
  const getAssetAnalytics = (asset) => {
    const currentPrice = Number(prices[asset.symbol] || asset.price || 0);
    const costBasis = Number(asset.costBasis || 0);
    const totalCost = costBasis * asset.quantity;
    const currentValue = currentPrice * asset.quantity;
    const profitLoss = currentValue - totalCost;
    const profitLossPercent = totalCost !== 0 ? (profitLoss / totalCost) * 100 : 0;

    console.log(
      `Asset: ${asset.symbol}, Current Price: ${currentPrice}, Quantity: ${asset.quantity}, Total Cost: ${totalCost}, Current Value: ${currentValue}, Profit/Loss: ${profitLoss}, Profit/Loss %: ${profitLossPercent}`
    );

    return { currentPrice, totalCost, currentValue, profitLoss, profitLossPercent };
  };

  // Main render
  return (
    <div className="flex flex-col items-center justify-start w-full h-full px-4 sm:px-6 lg:px-8 py-8 min-h-screen font-sans bg-gray-100">
      <div className="w-full max-w-6xl bg-white rounded-xl shadow-2xl p-6 sm:p-8">
        {/* Header */}
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl sm:text-4xl font-extrabold text-gray-800">Crypto Asset Manager</h1>
          {/* Removed ThemeToggle */}
        </div>

        {/* Disclaimer Section */}
        <div className="w-full max-w-6xl mb-6">
          <div className="flex items-center bg-yellow-100 border border-yellow-500 text-yellow-700 px-4 py-3 rounded-md">
            <FaInfoCircle className="mr-2" />
            <p className="text-sm">
              <strong>Disclaimer:</strong> This application is for monitoring existing assets and providing data only. It does not facilitate buying, selling, or trading any actual assets.
            </p>
          </div>
        </div>

        {/* Search Section */}
        <div className="mb-8 relative">
          <h2 className="text-2xl sm:text-3xl font-semibold text-gray-700 mb-4 text-center">Search for a Crypto</h2>
          <div className="flex flex-col sm:flex-row items-center justify-center gap-4 mb-4 relative">
            <input
              type="text"
              placeholder="Type crypto name or symbol (min 2 chars)"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="border border-gray-300 bg-gray-50 rounded-md px-4 py-2 focus:outline-none focus:ring focus:ring-indigo-300 shadow-sm w-full sm:w-72"
            />
          </div>
          {searchResults.length > 0 && (
            <div className="absolute left-1/2 transform -translate-x-1/2 mt-1 z-10 w-full sm:w-72 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-y-auto">
              <ul>
                {searchResults.map((res, i) => (
                  <li
                    key={i}
                    className="px-4 py-2 hover:bg-indigo-100 cursor-pointer flex items-center"
                    onClick={() => handleSelectResult(res)}
                  >
                    <span className="font-semibold text-indigo-600 mr-2">{res.symbol}</span>
                    <span className="text-gray-700">{res.name}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        {/* Conditional Rendering: Show PortfolioChart or AssetChart */}
        {selectedAsset ? (
          // Render AssetChart and related UI
          <div className="flex flex-col gap-8">
            {/* Crypto Chart Section */}
            <div className="flex-1">
              <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-4 text-center">Crypto Details</h2>

              {/* Timeframe Selection for AssetChart */}
              <div className="flex justify-center gap-3 mb-4 flex-wrap">
                {['1D', '7D', '30D', '1Y'].map((tf) => (
                  <button
                    key={tf}
                    onClick={() => changeAssetChartTimeframe(tf)}
                    className={`px-4 py-2 rounded-md ${
                      assetChartTimeframe === tf
                        ? 'bg-indigo-600 text-white'
                        : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                    } transition duration-200`}
                  >
                    {tf}
                  </button>
                ))}
              </div>

              {/* Chart Description for AssetChart */}
              <p className="text-sm text-gray-500 mb-4 text-center">{assetTimeframeDescription()}</p>

              {/* Selected Asset Details and Chart */}
              <div className="bg-gray-50 p-6 rounded-xl shadow-md">
                {/* Detailed Analytics View */}
                <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
                  <h3 className="text-2xl sm:text-3xl font-bold text-gray-800">
                    {selectedAsset.name} ({selectedAsset.symbol})
                  </h3>
                  <button
                    onClick={handleBackToDashboard}
                    className="flex items-center bg-indigo-500 text-white px-4 py-2 rounded-md hover:bg-indigo-600 transition duration-200 mt-4 sm:mt-0"
                  >
                    <FaArrowLeft className="mr-2" /> Back to Dashboard
                  </button>
                </div>
                <div className="mb-6 grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <p className="text-xl text-gray-700">
                    Current Price:{' '}
                    <span className="font-semibold text-green-600">
                      ${Number(selectedAsset.price || 0).toFixed(2)}
                    </span>
                  </p>
                  {selectedAsset.marketCap !== null && (
                    <p className="text-md text-gray-600">
                      Market Cap: ${Number(selectedAsset.marketCap || 0).toLocaleString()}
                    </p>
                  )}
                  {selectedAsset.changePercent !== null && (
                    <p
                      className={`text-md ${
                        selectedAsset.changePercent >= 0 ? 'text-green-600' : 'text-red-600'
                      }`}
                    >
                      Change: {Number(selectedAsset.changePercent || 0).toFixed(2)}%
                    </p>
                  )}
                </div>
                <AssetChart data={selectedAsset.chartData} assetName={selectedAsset.name} /> {/* Use AssetChart */}
              </div>

              {/* Option to Add More Quantity */}
              <div className="mt-6 flex justify-center">
                {!showAddForm ? (
                  <button
                    onClick={() => setShowAddForm(true)}
                    className="flex items-center bg-green-600 text-white font-semibold rounded-md px-6 py-3 hover:bg-green-700 transition duration-200 shadow-md"
                  >
                    <FaPlus className="mr-2" /> Add More to Portfolio
                  </button>
                ) : (
                  <form
                    onSubmit={confirmAddToPortfolio}
                    className="flex flex-col sm:flex-row items-center gap-4 mt-4 w-full sm:w-auto"
                  >
                    <input
                      type="number"
                      step="any"
                      placeholder="Quantity"
                      value={addQuantity}
                      onChange={(e) => setAddQuantity(e.target.value)}
                      className="border border-gray-300 bg-gray-50 rounded-md px-4 py-2 focus:outline-none focus:ring focus:ring-indigo-300 shadow-sm w-full sm:w-32"
                      required
                    />
                    <input
                      type="number"
                      step="0.01" // Limit to two decimal places
                      placeholder="Cost Basis (USD)"
                      value={addCostBasis}
                      onChange={(e) => setAddCostBasis(e.target.value)}
                      className="border border-gray-300 bg-gray-50 rounded-md px-4 py-2 focus:outline-none focus:ring focus:ring-indigo-300 shadow-sm w-full sm:w-48"
                      required
                    />
                    <button
                      type="submit"
                      className="flex items-center bg-indigo-600 text-white font-semibold rounded-md px-6 py-3 hover:bg-indigo-700 transition duration-200 shadow-md w-full sm:w-auto"
                    >
                      Confirm
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        ) : (
          // Render PortfolioChart and Asset List
          <div className="flex flex-col gap-8">
            {/* Portfolio Chart Section */}
            <div className="flex-1">
              <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-4 text-center">Your Portfolio</h2>

              {/* Timeframe Selection for PortfolioChart */}
              <div className="flex justify-center gap-4 mb-4 flex-wrap">
                {['1D', '7D', '30D', '1Y'].map((tf) => (
                  <button
                    key={tf}
                    onClick={() => changePortfolioChartTimeframe(tf)}
                    className={`px-4 py-2 rounded-md ${
                      portfolioChartTimeframe === tf
                        ? 'bg-indigo-600 text-white'
                        : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                    } transition duration-200`}
                  >
                    {tf}
                  </button>
                ))}
              </div>

              {/* Chart Description for PortfolioChart */}
              <p className="text-sm text-gray-500 mb-4 text-center">{portfolioTimeframeDescription()}</p>

              {/* Portfolio Chart */}
              <div className="mb-4">
                {isPortfolioLoading ? (
                  <div className="flex justify-center items-center h-64">
                    <svg
                      className="animate-spin h-8 w-8 text-indigo-600"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                      ></path>
                    </svg>
                  </div>
                ) : portfolioError ? (
                  <p className="text-red-500 text-center">{portfolioError}</p>
                ) : portfolioChartData.length > 0 ? (
                  <PortfolioChart data={portfolioChartData} />
                ) : (
                  <p className="text-gray-500 text-center">No historical data available.</p>
                )}
              </div>

              {/* Total Portfolio Value */}
              <h3 className="text-xl sm:text-2xl font-semibold mb-6 text-center text-gray-600">
                Total Value:{' '}
                <span className="text-green-600">${Number(portfolioValue || 0).toFixed(2)}</span>
              </h3>
            </div>

            {/* Asset List */}
            <div className="overflow-x-auto rounded-xl shadow-lg bg-gray-50 p-6">
              <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-4 text-center">Your Assets</h2>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-indigo-600">
                    <tr>
                      <th className="px-2 py-3 text-left text-xs font-semibold text-white uppercase tracking-wider">
                        Symbol
                      </th>
                      <th className="px-2 py-3 text-left text-xs font-semibold text-white uppercase tracking-wider">
                        Type
                      </th>
                      <th className="px-2 py-3 text-left text-xs font-semibold text-white uppercase tracking-wider">
                        Quantity
                      </th>
                      <th className="px-2 py-3 text-left text-xs font-semibold text-white uppercase tracking-wider hidden sm:table-cell">
                        Cost Basis (USD)
                      </th>
                      <th className="px-2 py-3 text-left text-xs font-semibold text-white uppercase tracking-wider hidden sm:table-cell">
                        Current Price (USD)
                      </th>
                      <th className="px-2 py-3 text-left text-xs font-semibold text-white uppercase tracking-wider hidden md:table-cell">
                        Total Cost (USD)
                      </th>
                      <th className="px-2 py-3 text-left text-xs font-semibold text-white uppercase tracking-wider hidden md:table-cell">
                        Current Value (USD)
                      </th>
                      <th className="px-2 py-3 text-left text-xs font-semibold text-white uppercase tracking-wider hidden lg:table-cell">
                        Profit/Loss (USD)
                      </th>
                      <th className="px-2 py-3 text-left text-xs font-semibold text-white uppercase tracking-wider hidden lg:table-cell">
                        Profit/Loss (%)
                      </th>
                      <th className="px-2 py-3 text-left text-xs font-semibold text-white uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {assets.length === 0 && (
                      <tr>
                        <td colSpan="10" className="px-6 py-4 text-center text-gray-500">
                          No assets yet.
                        </td>
                      </tr>
                    )}
                    {assets.map((asset) => {
                      const { currentPrice, totalCost, currentValue, profitLoss, profitLossPercent } = getAssetAnalytics(
                        asset
                      );
                      return (
                        <React.Fragment key={asset.id}>
                          {/* Table Row for Larger Screens */}
                          <tr className="hover:bg-gray-100 transition duration-200 lg:table-row hidden">
                            <td
                              className="px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer"
                              onClick={() => handleSelectResult(asset)}
                            >
                              {asset.symbol}
                            </td>
                            <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-700 capitalize">
                              {asset.type}
                            </td>
                            {/* Editable Quantity */}
                            <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-700">
                              <input
                                type="number"
                                step="any"
                                value={asset.quantity}
                                onChange={(e) => handleInlineEdit(asset.id, 'quantity', e.target.value)}
                                className="w-16 border border-gray-300 bg-gray-50 rounded-md px-2 py-1 focus:outline-none focus:ring focus:ring-indigo-200"
                              />
                            </td>
                            {/* Editable Cost Basis */}
                            <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-700 hidden sm:table-cell">
                              <input
                                type="number"
                                step="0.01" // Limit to two decimal places
                                value={asset.costBasis}
                                onChange={(e) => handleInlineEdit(asset.id, 'costBasis', e.target.value)}
                                className="w-24 border border-gray-300 bg-gray-50 rounded-md px-2 py-1 focus:outline-none focus:ring focus:ring-indigo-200"
                              />
                            </td>
                            <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-700 hidden sm:table-cell">
                              ${Number(currentPrice || 0).toFixed(2)}
                            </td>
                            <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-700 hidden md:table-cell">
                              ${Number(totalCost || 0).toFixed(2)}
                            </td>
                            <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-700 hidden md:table-cell">
                              ${Number(currentValue || 0).toFixed(2)}
                            </td>
                            <td
                              className={`px-2 py-4 whitespace-nowrap text-sm font-semibold ${
                                profitLoss >= 0 ? 'text-green-600' : 'text-red-600'
                              } hidden lg:table-cell`}
                            >
                              ${Number(profitLoss || 0).toFixed(2)}
                            </td>
                            <td
                              className={`px-2 py-4 whitespace-nowrap text-sm font-semibold ${
                                profitLossPercent >= 0 ? 'text-green-600' : 'text-red-600'
                              } hidden lg:table-cell`}
                            >
                              {Number(profitLossPercent || 0).toFixed(2)}%
                            </td>
                            <td className="px-2 py-4 whitespace-nowrap text-sm">
                              <button
                                onClick={() => handleDeleteAsset(asset.id, asset.symbol)}
                                className="flex items-center bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-600 transition duration-200"
                              >
                                <FaTrash className="mr-2" /> Delete
                              </button>
                            </td>
                          </tr>

                          {/* Card for Mobile Screens */}
                          <tr className="block lg:hidden border-b border-gray-200">
                            <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-700">
                              <div className="flex flex-col">
                                <span className="font-medium text-gray-900">{asset.symbol}</span>
                                <span className="text-xs text-gray-500 capitalize">{asset.type}</span>
                              </div>
                            </td>
                            <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-700">
                              <div className="flex flex-col">
                                <span className="font-semibold">Quantity:</span>
                                <input
                                  type="number"
                                  step="any"
                                  value={asset.quantity}
                                  onChange={(e) => handleInlineEdit(asset.id, 'quantity', e.target.value)}
                                  className="w-full border border-gray-300 bg-gray-50 rounded-md px-2 py-1 focus:outline-none focus:ring focus:ring-indigo-200"
                                />
                              </div>
                            </td>
                            <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-700">
                              <div className="flex flex-col">
                                <span className="font-semibold">Cost Basis (USD):</span>
                                <input
                                  type="number"
                                  step="0.01" // Limit to two decimal places
                                  value={asset.costBasis}
                                  onChange={(e) => handleInlineEdit(asset.id, 'costBasis', e.target.value)}
                                  className="w-full border border-gray-300 bg-gray-50 rounded-md px-2 py-1 focus:outline-none focus:ring focus:ring-indigo-200"
                                />
                              </div>
                            </td>
                            <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-700">
                              <div className="flex flex-col">
                                <span className="font-semibold">Current Price (USD):</span>
                                <span>${Number(currentPrice || 0).toFixed(2)}</span>
                              </div>
                            </td>
                            <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-700">
                              <div className="flex flex-col">
                                <span className="font-semibold">Total Cost (USD):</span>
                                <span>${Number(totalCost || 0).toFixed(2)}</span>
                              </div>
                            </td>
                            <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-700">
                              <div className="flex flex-col">
                                <span className="font-semibold">Current Value (USD):</span>
                                <span>${Number(currentValue || 0).toFixed(2)}</span>
                              </div>
                            </td>
                            <td className="px-2 py-4 whitespace-nowrap text-sm">
                              <button
                                onClick={() => handleDeleteAsset(asset.id, asset.symbol)}
                                className="flex items-center bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-600 transition duration-200"
                              >
                                <FaTrash className="mr-2" /> Delete
                              </button>
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AssetsDashboard;
