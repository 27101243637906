// src/components/ShoppingList.js

import React, { useState, useEffect, useContext } from 'react';
import './ShoppingList.css';
import {
  FaTrashAlt,
  FaPlus,
  FaSearch,
  FaChevronDown,
  FaChevronUp,
} from 'react-icons/fa';
import { useFirebaseService } from '../services/firebaseService';
import { AuthContext } from '../contexts/AuthContext';
import { ProfileContext } from '../contexts/ProfileContext'; // Import ProfileContext
import { toast } from 'react-toastify';

const ShoppingList = () => {
  const [lists, setLists] = useState([]);
  const [currentListName, setCurrentListName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const firebaseService = useFirebaseService();
  const { user, admin, loading: authLoading } = useContext(AuthContext);
  const {
    activeProfileId,
    profilesLoading,
    profiles,
    setActiveProfileId,
  } = useContext(ProfileContext); // Consume ProfileContext

  const userId = user ? user.uid : null;

  // Fetch and subscribe to shopping lists
  useEffect(() => {
    if (authLoading || profilesLoading) return; // Wait until contexts are loaded

    if (!userId || !activeProfileId) {
      console.error('User ID or Profile ID is missing.');
      return;
    }

    console.log(
      `Fetching shopping lists for User ID: ${userId}, Profile ID: ${activeProfileId}`
    );

    const unsubscribe = firebaseService.subscribeToShoppingLists(
      userId,
      activeProfileId,
      (fetchedLists) => {
        console.log('Fetched Shopping Lists:', fetchedLists);
        setLists(fetchedLists);
      }
    );

    // Cleanup subscription on unmount
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [firebaseService, userId, activeProfileId, authLoading, profilesLoading]);

  // Create a new shopping list
  const createList = async () => {
    if (currentListName.trim() !== '') {
      try {
        const newList = {
          name: currentListName,
          items: [], // Initialize as empty array
          alreadyShopped: [], // Initialize as empty array
          budget: '',
          isCollapsed: false,
          createdAt: new Date(), // Alternatively, use serverTimestamp()
        };
        await firebaseService.addShoppingList(userId, activeProfileId, newList);
        setCurrentListName('');
        toast.success('Shopping list created successfully!');
      } catch (error) {
        console.error('Error creating list:', error);
        // Error is handled in firebaseService via toast
      }
    } else {
      toast.error('Please enter a list name.');
    }
  };

  // Delete a shopping list
  const deleteList = async (listId) => {
    if (window.confirm('Are you sure you want to delete this list?')) {
      try {
        await firebaseService.deleteShoppingList(userId, activeProfileId, listId);
        toast.success('Shopping list deleted successfully!');
        // The subscription will automatically update the lists state
      } catch (error) {
        console.error('Error deleting list:', error);
        // Error is handled in firebaseService via toast
      }
    }
  };

  // Toggle list collapse
  const toggleCollapse = async (list) => {
    try {
      await firebaseService.updateShoppingList(userId, activeProfileId, list.id, {
        isCollapsed: !list.isCollapsed,
      });
      // The subscription will automatically update the lists state
    } catch (error) {
      console.error('Error toggling collapse:', error);
      // Error is handled in firebaseService via toast
    }
  };

  // Add a new empty item to the list
  const addNewItem = async (listId) => {
    try {
      // Fetch the current list
      const list = lists.find((l) => l.id === listId);
      if (!list) {
        toast.error('Shopping list not found.');
        return;
      }

      const updatedItems = [
        ...(Array.isArray(list.items) ? list.items : []),
        {
          name: '',
          quantity: 1,
          price: '',
          bought: false,
        },
      ];
      await firebaseService.updateShoppingList(userId, activeProfileId, listId, {
        items: updatedItems,
      });
      toast.success('New item added. Please fill in the details.');
    } catch (error) {
      console.error('Error adding new item:', error);
      // Error is handled in firebaseService via toast
    }
  };

  // Update item details
  const updateItem = async (listId, itemIndex, field, value, source) => {
    try {
      const list = lists.find((l) => l.id === listId);
      if (!list) {
        toast.error('Shopping list not found.');
        return;
      }

      let updatedItems = Array.isArray(list.items) ? [...list.items] : [];
      let updatedAlreadyShopped = Array.isArray(list.alreadyShopped)
        ? [...list.alreadyShopped]
        : [];

      if (source === 'items') {
        if (!updatedItems[itemIndex]) {
          toast.error('Item not found.');
          return;
        }
        updatedItems[itemIndex][field] = value;
      } else if (source === 'alreadyShopped') {
        if (!updatedAlreadyShopped[itemIndex]) {
          toast.error('Item not found.');
          return;
        }
        updatedAlreadyShopped[itemIndex][field] = value;
      }

      await firebaseService.updateShoppingList(userId, activeProfileId, listId, {
        items: updatedItems,
        alreadyShopped: updatedAlreadyShopped,
      });
    } catch (error) {
      console.error('Error updating item:', error);
      // Error is handled in firebaseService via toast
    }
  };

  // Toggle item bought status
  const toggleBought = async (listId, itemIndex, source) => {
    try {
      const list = lists.find((l) => l.id === listId);
      if (!list) {
        toast.error('Shopping list not found.');
        return;
      }

      let updatedItems = Array.isArray(list.items) ? [...list.items] : [];
      let updatedAlreadyShopped = Array.isArray(list.alreadyShopped)
        ? [...list.alreadyShopped]
        : [];

      if (source === 'items') {
        const item = updatedItems.splice(itemIndex, 1)[0];
        if (!item) {
          toast.error('Item not found.');
          return;
        }
        item.bought = true;
        updatedAlreadyShopped.push(item);
      } else if (source === 'alreadyShopped') {
        const item = updatedAlreadyShopped.splice(itemIndex, 1)[0];
        if (!item) {
          toast.error('Item not found.');
          return;
        }
        item.bought = false;
        updatedItems.push(item);
      }

      await firebaseService.updateShoppingList(userId, activeProfileId, listId, {
        items: updatedItems,
        alreadyShopped: updatedAlreadyShopped,
      });
      toast.success('Item status toggled successfully!');
    } catch (error) {
      console.error('Error toggling bought status:', error);
      // Error is handled in firebaseService via toast
    }
  };

  // Delete an item from the list
  const deleteItem = async (listId, itemIndex, source) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      try {
        const list = lists.find((l) => l.id === listId);
        if (!list) {
          toast.error('Shopping list not found.');
          return;
        }

        let updatedItems = Array.isArray(list.items) ? [...list.items] : [];
        let updatedAlreadyShopped = Array.isArray(list.alreadyShopped)
          ? [...list.alreadyShopped]
          : [];

        if (source === 'items') {
          if (itemIndex < 0 || itemIndex >= updatedItems.length) {
            toast.error('Item index out of bounds.');
            return;
          }
          updatedItems.splice(itemIndex, 1);
        } else if (source === 'alreadyShopped') {
          if (itemIndex < 0 || itemIndex >= updatedAlreadyShopped.length) {
            toast.error('Item index out of bounds.');
            return;
          }
          updatedAlreadyShopped.splice(itemIndex, 1);
        }

        await firebaseService.updateShoppingList(userId, activeProfileId, listId, {
          items: updatedItems,
          alreadyShopped: updatedAlreadyShopped,
        });
        toast.success('Item deleted successfully!');
      } catch (error) {
        console.error('Error deleting item:', error);
        // Error is handled in firebaseService via toast
      }
    }
  };

  // Set budget for a list
  const setBudget = async (listId, budget) => {
    try {
      await firebaseService.updateShoppingList(userId, activeProfileId, listId, {
        budget: budget,
      });
      toast.success('Budget updated successfully!');
    } catch (error) {
      console.error('Error setting budget:', error);
      // Error is handled in firebaseService via toast
    }
  };

  // Early return for loading states
  if (authLoading || profilesLoading) {
    return <div className="loading-message">Loading...</div>;
  }

  if (!userId || !activeProfileId) {
    console.error('User ID or Profile ID is missing.');
    return <div className="error-message">User ID or Profile ID is missing.</div>;
  }

  return (
    <div className="shopping-list-container">
      <h1>Shopping Lists</h1>

      <div className="create-list">
        <input
          type="text"
          placeholder="Enter new list name"
          value={currentListName}
          onChange={(e) => setCurrentListName(e.target.value)}
        />
        <button onClick={createList} title="Create List">
          <FaPlus />
        </button>
      </div>

      {lists.map((list) => {
        // Ensure items and alreadyShopped are arrays
        const itemsToBuy = Array.isArray(list.items) ? list.items : [];
        const alreadyShopped = Array.isArray(list.alreadyShopped) ? list.alreadyShopped : [];

        // Calculate total cost
        const listTotal = [...itemsToBuy, ...alreadyShopped].reduce(
          (total, item) =>
            total + (item.quantity || 0) * parseFloat(item.price || 0),
          0
        );

        return (
          <div key={list.id || list.name} className="shopping-list">
            <div className="list-header">
              <h2 onClick={() => toggleCollapse(list)}>
                {list.name}{' '}
                {list.isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
              </h2>
              <button
                className="delete-list-button"
                onClick={() => deleteList(list.id)}
                title="Delete List"
              >
                <FaTrashAlt />
              </button>
            </div>

            {!list.isCollapsed && (
              <div className="list-content">
                <div className="budget-section">
                  <label>
                    Budget:
                    <input
                      type="number"
                      value={list.budget || ''}
                      onChange={(e) => setBudget(list.id, e.target.value)}
                      placeholder="Enter budget"
                    />
                  </label>
                </div>

                <div className="search-filter">
                  <input
                    type="text"
                    placeholder="Search items..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <FaSearch className="search-icon" />
                </div>

                {/* Items to Buy */}
                <h3>Items to Buy</h3>
                <table className="items-table">
                  <thead>
                    <tr>
                      <th>Bought</th>
                      <th>Name</th>
                      <th>Quantity</th>
                      <th>Price ($)</th>
                      <th>Total ($)</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemsToBuy
                      .filter((item) =>
                        item.name
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      )
                      .map((item, itemIndex) => (
                        <tr key={item.id || itemIndex}>
                          <td>
                            <input
                              type="checkbox"
                              checked={item.bought || false}
                              onChange={() =>
                                toggleBought(list.id, itemIndex, 'items')
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={item.name || ''}
                              placeholder="Enter item name"
                              onChange={(e) =>
                                updateItem(
                                  list.id,
                                  itemIndex,
                                  'name',
                                  e.target.value,
                                  'items'
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              value={item.quantity || 1}
                              min="1"
                              placeholder="Qty"
                              onChange={(e) =>
                                updateItem(
                                  list.id,
                                  itemIndex,
                                  'quantity',
                                  Number(e.target.value),
                                  'items'
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              value={item.price || ''}
                              min="0"
                              step="0.01"
                              placeholder="Price per item"
                              onChange={(e) =>
                                updateItem(
                                  list.id,
                                  itemIndex,
                                  'price',
                                  e.target.value,
                                  'items'
                                )
                              }
                            />
                          </td>
                          <td>
                            {(
                              (item.quantity || 0) *
                              parseFloat(item.price || 0)
                            ).toFixed(2)}
                          </td>
                          <td>
                            <button
                              onClick={() =>
                                deleteItem(list.id, itemIndex, 'items')
                              }
                              title="Delete Item"
                            >
                              <FaTrashAlt />
                            </button>
                          </td>
                        </tr>
                      ))}

                    {/* Add New Item Row */}
                    <tr>
                      <td colSpan="6" style={{ textAlign: 'center' }}>
                        <button
                          onClick={() => addNewItem(list.id)}
                          title="Add Item"
                          className="add-item-button"
                        >
                          <FaPlus /> Add Item
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                {/* Already Shopped */}
                {alreadyShopped.length > 0 && (
                  <>
                    <h3>Already Shopped</h3>
                    <table className="items-table">
                      <thead>
                        <tr>
                          <th>Bought</th>
                          <th>Name</th>
                          <th>Quantity</th>
                          <th>Price ($)</th>
                          <th>Total ($)</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {alreadyShopped
                          .filter((item) =>
                            item.name
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          )
                          .map((item, itemIndex) => (
                            <tr key={item.id || itemIndex} className="bought">
                              <td>
                                <input
                                  type="checkbox"
                                  checked={item.bought || false}
                                  onChange={() =>
                                    toggleBought(
                                      list.id,
                                      itemIndex,
                                      'alreadyShopped'
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={item.name || ''}
                                  placeholder="Enter item name"
                                  onChange={(e) =>
                                    updateItem(
                                      list.id,
                                      itemIndex,
                                      'name',
                                      e.target.value,
                                      'alreadyShopped'
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  value={item.quantity || 1}
                                  min="1"
                                  placeholder="Qty"
                                  onChange={(e) =>
                                    updateItem(
                                      list.id,
                                      itemIndex,
                                      'quantity',
                                      Number(e.target.value),
                                      'alreadyShopped'
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  value={item.price || ''}
                                  min="0"
                                  step="0.01"
                                  placeholder="Price per item"
                                  onChange={(e) =>
                                    updateItem(
                                      list.id,
                                      itemIndex,
                                      'price',
                                      e.target.value,
                                      'alreadyShopped'
                                    )
                                  }
                                />
                              </td>
                              <td>
                                {(
                                  (item.quantity || 0) *
                                  parseFloat(item.price || 0)
                                ).toFixed(2)}
                              </td>
                              <td>
                                <button
                                  onClick={() =>
                                    deleteItem(
                                      list.id,
                                      itemIndex,
                                      'alreadyShopped'
                                    )
                                  }
                                  title="Delete Item"
                                >
                                  <FaTrashAlt />
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </>
                )}

                {/* List Total */}
                <div className="list-total">
                  <strong>List Total: ${listTotal.toFixed(2)}</strong>
                  {list.budget > 0 && (
                    <span>
                      {' '}
                      | Remaining Budget: $
                      {(list.budget - listTotal).toFixed(2)}
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ShoppingList;
