// src/components/TermsAndConditions.js

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet'; // For SEO and managing document head

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  return (
    <>
      <Helmet>
        <title>Terms and Conditions - Atlas Budget</title>
        <meta
          name="description"
          content="Read the comprehensive Terms and Conditions for Atlas Budget."
        />
      </Helmet>
      <div className="max-w-4xl mx-auto p-6">
        <h1 className="text-3xl font-bold mb-4">Terms and Conditions</h1>
        
        {/* 1. Introduction */}
        <section className="mb-4">
          <h2 className="text-xl font-semibold">1. Introduction</h2>
          <p>Welcome to Atlas Budget! These terms and conditions outline the rules and regulations for the use of Atlas Budget's Website.</p>
        </section>

        {/* 2. Definitions */}
        <section className="mb-4">
          <h2 className="text-xl font-semibold">2. Definitions</h2>
          <p><strong>"User," "You," and "Your"</strong> refers to you, the person accessing this Service and accepting the Company's terms and conditions.</p>
          <p><strong>"Company," "Ourselves," "We," "Our," and "Us"</strong> refers to Atlas Budget.</p>
          <p><strong>"Content"</strong> refers to any text, images, or other information that you submit to the Service.</p>
        </section>

        {/* 3. Affiliate Disclosure */}
        <section className="mb-4">
          <h2 className="text-xl font-semibold">3. Affiliate Disclosure</h2>
          <p>Some links on this website are affiliate links, which means that Atlas Budget may earn a commission if you click on the link or make a purchase using the link. This comes at no additional cost to you. We only promote products and services that we believe will add value to our users.</p>
          <p className="mt-2">As an Amazon Associate, we earn from qualifying purchases. Your support through these affiliate links helps us maintain and improve our services.</p>
        </section>

        {/* 4. Eligibility */}
        <section className="mb-4">
          <h2 className="text-xl font-semibold">4. Eligibility</h2>
          <p>You must be at least 18 years old to use this Service. By agreeing to these Terms, you represent and warrant that you are at least 18 years old.</p>
        </section>

        {/* 5. Account Registration */}
        <section className="mb-4">
          <h2 className="text-xl font-semibold">5. Account Registration</h2>
          <p><strong>a. Account Creation</strong><br />
          To access certain features of the Service, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate.</p>
          <p className="mt-2"><strong>b. Security</strong><br />
          You are responsible for safeguarding your password and other login credentials. You agree to notify us immediately of any unauthorized use of your account.</p>
        </section>

        {/* 6. Use of the Service */}
        <section className="mb-4">
          <h2 className="text-xl font-semibold">6. Use of the Service</h2>
          <p><strong>a. License</strong><br />
          Subject to your compliance with these Terms, Atlas Budget grants you a limited, non-exclusive, non-transferable, and revocable license to access and use the Service.</p>
          <p className="mt-2"><strong>b. Restrictions</strong><br />
          You agree not to:</p>
          <ul className="list-disc list-inside mt-2">
            <li>Use the Service for any unlawful purpose.</li>
            <li>Attempt to gain unauthorized access to the Service or its related systems.</li>
            <li>Interfere with the proper functioning of the Service.</li>
            <li>Use any automated means to access the Service for any purpose without our express written permission.</li>
          </ul>
        </section>

        {/* 7. User Content */}
        <section className="mb-4">
          <h2 className="text-xl font-semibold">7. User Content</h2>
          <p><strong>a. Ownership</strong><br />
          You retain ownership of any Content you submit to the Service. By submitting Content, you grant Atlas Budget a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and distribute your Content in connection with providing the Service.</p>
          <p className="mt-2"><strong>b. Responsibility</strong><br />
          You are solely responsible for the Content you upload, post, or otherwise transmit through the Service. You agree not to upload any Content that is illegal, harmful, defamatory, or infringes upon the rights of any third party.</p>
        </section>

        {/* 8. Intellectual Property */}
        <section className="mb-4">
          <h2 className="text-xl font-semibold">8. Intellectual Property</h2>
          <p>All content, trademarks, logos, and intellectual property displayed on the Service are the property of Atlas Budget or their respective owners. You agree not to reproduce, distribute, or create derivative works from any of this content without prior written consent.</p>
        </section>

        {/* 9. Termination */}
        <section className="mb-4">
          <h2 className="text-xl font-semibold">9. Termination</h2>
          <p>We reserve the right to terminate or suspend your account and access to the Service immediately, without prior notice or liability, for any reason, including if you breach these Terms.</p>
          <p className="mt-2">Upon termination, your right to use the Service will immediately cease. All provisions of the Terms which by their nature should survive termination shall survive termination.</p>
        </section>

        {/* 10. Disclaimer of Warranties */}
        <section className="mb-4">
          <h2 className="text-xl font-semibold">10. Disclaimer of Warranties</h2>
          <p>The Service is provided on an "AS IS" and "AS AVAILABLE" basis. Atlas Budget makes no warranties, express or implied, regarding the Service's accuracy, reliability, availability, or suitability for any purpose.</p>
        </section>

        {/* 11. Limitation of Liability */}
        <section className="mb-4">
          <h2 className="text-xl font-semibold">11. Limitation of Liability</h2>
          <p>In no event shall Atlas Budget, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from:</p>
          <ul className="list-disc list-inside mt-2">
            <li>Your access to or use of or inability to access or use the Service.</li>
            <li>Any conduct or content of any third party on the Service.</li>
          </ul>
        </section>

        {/* 12. Indemnification */}
        <section className="mb-4">
          <h2 className="text-xl font-semibold">12. Indemnification</h2>
          <p>You agree to defend, indemnify, and hold harmless Atlas Budget and its affiliates from any claims, damages, obligations, losses, liabilities, costs, or debt, and expenses arising from:</p>
          <ul className="list-disc list-inside mt-2">
            <li>Your use of and access to the Service.</li>
            <li>Your violation of any term of these Terms.</li>
          </ul>
        </section>

        {/* 13. Governing Law */}
        <section className="mb-4">
          <h2 className="text-xl font-semibold">13. Governing Law</h2>
          <p>These Terms shall be governed and construed in accordance with the laws of the State of [Your State], United States, without regard to its conflict of law provisions.</p>
        </section>

        {/* 14. Dispute Resolution */}
        <section className="mb-4">
          <h2 className="text-xl font-semibold">14. Dispute Resolution</h2>
          <p>Any disputes arising out of or related to these Terms shall be resolved through binding arbitration in [Your City], [Your State], under the rules of the American Arbitration Association.</p>
        </section>

        {/* 15. Changes to Terms */}
        <section className="mb-4">
          <h2 className="text-xl font-semibold">15. Changes to Terms</h2>
          <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect.</p>
          <p className="mt-2">By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms.</p>
        </section>

        {/* 16. Severability */}
        <section className="mb-4">
          <h2 className="text-xl font-semibold">16. Severability</h2>
          <p>If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect.</p>
        </section>

        {/* 17. Entire Agreement */}
        <section className="mb-4">
          <h2 className="text-xl font-semibold">17. Entire Agreement</h2>
          <p>These Terms constitute the entire agreement between you and Atlas Budget regarding the use of the Service, superseding any prior agreements.</p>
        </section>

        {/* 18. Contact Us */}
        <section>
          <h2 className="text-xl font-semibold">18. Contact Us</h2>
          <p>If you have any questions about these Terms, please contact us at:</p>
          <ul className="list-none mt-2">
            <li><strong>Email:</strong> <a href="mailto:support@atlasbudget.com" className="text-blue-500 hover:underline">support@atlasbudget.com</a></li>
          </ul>
        </section>
      </div>
    </>
  );
};

export default TermsAndConditions;
