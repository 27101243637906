// src/components/Dashboard.js

import React, { useState, useEffect, useContext, useMemo } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title as ChartTitle,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
} from 'chart.js';
import { Bar, Line, Doughnut } from 'react-chartjs-2';
import {
  format,
  endOfYear,
  startOfMonth,
  addMonths,
  addWeeks,
  addYears,
  isBefore,
  isSameMonth,
} from 'date-fns';

import Modal from 'react-modal';
import { useFirebaseService } from '../services/firebaseService';
import { ProfileContext } from '../contexts/ProfileContext';
import { AuthContext } from '../contexts/AuthContext';

import { FiX } from 'react-icons/fi';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartTitle,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement
);

Modal.setAppElement('#root');

const Dashboard = () => {
  // Contexts
  const { user } = useContext(AuthContext);
  const { activeProfileId, profilesLoading } = useContext(ProfileContext);

  // State variables
  const [yearlyBalances, setYearlyBalances] = useState({});
  const [topSpendingCategories, setTopSpendingCategories] = useState([]);
  const [mostFrequentExpenses, setMostFrequentExpenses] = useState([]);
  const [monthlyExpenses, setMonthlyExpenses] = useState({});
  const [monthlyIncome, setMonthlyIncome] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);

  // Firebase Service Hook
  const firebaseService = useFirebaseService();

  useEffect(() => {
    if (profilesLoading || !firebaseService || !activeProfileId || !user || !user.uid) {
      console.warn('Dashboard - Cannot subscribe to data. Profiles loading or missing context values.');
      return;
    }

    const incomeData = {};
    const expenseData = {};
    const categoryData = {};
    const monthlyCategoryData = {};
    let localFrequencyData = {};

    const currentYear = new Date().getFullYear();

    // Helper Function: Parse Local Date
    const parseLocalDate = (dateString) => {
      const [year, month, day] = dateString.split('-').map(Number);
      return new Date(year, month - 1, day);
    };

    // Function to get occurrences of a transaction in the current year
    const getOccurrencesInYear = (transaction) => {
      const occurrences = [];
      const transactionDate = transaction.date ? new Date(transaction.date) : null;
      if (!transactionDate || isNaN(transactionDate)) return occurrences;

      const startDate = new Date(currentYear, 0, 1);
      const endDate = endOfYear(startDate);
      let currentDate = new Date(Math.max(transactionDate, startDate));

      while (currentDate <= endDate) {
        if (transaction.recurrenceEndDate) {
          const recurrenceEndDate = new Date(transaction.recurrenceEndDate);
          if (currentDate > recurrenceEndDate) break;
        }
        occurrences.push(new Date(currentDate));
        if (!transaction.isRecurring || !transaction.recurrenceFrequency) break;

        switch (transaction.recurrenceFrequency) {
          case 'weekly':
            currentDate = addWeeks(currentDate, 1);
            break;
          case 'bi-weekly':
            currentDate = addWeeks(currentDate, 2);
            break;
          case 'monthly':
            currentDate = addMonths(currentDate, 1);
            break;
          case 'yearly':
            currentDate = addYears(currentDate, 1);
            break;
          default:
            currentDate = new Date(endDate.getTime() + 1);
            break;
        }
      }

      return occurrences.filter((date) => date.getFullYear() === currentYear);
    };

    // Function to process income data
    const processIncomes = (data) => {
      data.forEach((income) => {
        const occurrences = getOccurrencesInYear(income);
        occurrences.forEach((occurrenceDate) => {
          const monthKey = format(startOfMonth(occurrenceDate), 'MMMM yyyy');
          if (!incomeData[monthKey]) {
            incomeData[monthKey] = 0;
          }
          incomeData[monthKey] += parseFloat(income.amount) || 0;
        });
      });
      setMonthlyIncome(incomeData);
    };

    // Function to process expense data
    const processExpenses = (data) => {
      data.forEach((expense) => {
        const occurrences = getOccurrencesInYear(expense);
        occurrences.forEach((occurrenceDate) => {
          const monthKey = format(startOfMonth(occurrenceDate), 'MMMM yyyy');
          if (!expenseData[monthKey]) {
            expenseData[monthKey] = 0;
          }
          expenseData[monthKey] += parseFloat(expense.amount) || 0;

          if (expense.category) {
            const category = expense.category;
            if (!categoryData[category]) {
              categoryData[category] = 0;
              monthlyCategoryData[category] = new Set();
            }
            categoryData[category] += parseFloat(expense.amount) || 0;
            monthlyCategoryData[category].add(monthKey);

            if (!localFrequencyData[category]) {
              localFrequencyData[category] = {};
            }
            if (!localFrequencyData[category][monthKey]) {
              localFrequencyData[category][monthKey] = 0;
            }
            localFrequencyData[category][monthKey] += 1;
          }
        });
      });
      setMonthlyExpenses(expenseData);
      setTopSpendingCategories([]);
      setMostFrequentExpenses([]);
    };

    // Function to update yearly balances
    const updateYearlyBalances = () => {
      const totalIncome = Object.values(incomeData).reduce((acc, val) => acc + val, 0);
      const totalExpenses = Object.values(expenseData).reduce((acc, val) => acc + val, 0);
      const balance = totalIncome - totalExpenses;

      setYearlyBalances({
        incomes: totalIncome,
        expenses: totalExpenses,
        balance: balance,
      });
    };

    // Function to fetch top spending categories
    const fetchTopSpendingCategories = () => {
      const sortedCategories = Object.entries(categoryData)
        .map(([category, total]) => {
          const monthsWithData = monthlyCategoryData[category].size;
          const monthlyAverage = monthsWithData > 0 ? total / monthsWithData : 0;
          return { category, total, monthlyAverage };
        })
        .sort((a, b) => b.total - a.total)
        .slice(0, 5);
      setTopSpendingCategories(sortedCategories);
      setSelectedCategories(sortedCategories.map(cat => cat.category));
    };

    // Function to fetch most frequent expenses
    const fetchMostFrequentExpenses = () => {
      const sortedFrequencies = Object.entries(localFrequencyData)
        .map(([category, monthlyFreq]) => {
          const totalFrequency = Object.values(monthlyFreq).reduce(
            (acc, freq) => acc + freq,
            0
          );
          return { category, monthlyFreq, totalFrequency };
        })
        .sort((a, b) => b.totalFrequency - a.totalFrequency)
        .slice(0, 5);
      setMostFrequentExpenses(sortedFrequencies);
    };

    // Function to fetch and process incomes
    const fetchAndProcessIncomes = (data) => {
      processIncomes(data);
      updateYearlyBalances();
    };

    // Function to fetch and process expenses
    const fetchAndProcessExpenses = (data) => {
      processExpenses(data);
      updateYearlyBalances();
      fetchTopSpendingCategories();
      fetchMostFrequentExpenses();
      setIsLoading(false);
    };

    // Subscribe to Incomes
    const unsubscribeIncomes = firebaseService.subscribeToData(
      ['users', user.uid, 'profiles', activeProfileId, 'incomes'],
      (data) => {
        console.log('Fetched Incomes:', data);
        fetchAndProcessIncomes(data);
      },
      (err) => {
        console.error('Error fetching incomes:', err);
        setError('Failed to load income data.');
        setIsLoading(false);
      }
    );

    // Subscribe to Expenses
    const unsubscribeExpenses = firebaseService.subscribeToData(
      ['users', user.uid, 'profiles', activeProfileId, 'expenses'],
      (data) => {
        console.log('Fetched Expenses:', data);
        fetchAndProcessExpenses(data);
      },
      (err) => {
        console.error('Error fetching expenses:', err);
        setError('Failed to load expense data.');
        setIsLoading(false);
      }
    );

    // Cleanup subscriptions on unmount
    return () => {
      if (typeof unsubscribeIncomes === 'function') {
        unsubscribeIncomes();
        console.log('Unsubscribed from incomes data.');
      }
      if (typeof unsubscribeExpenses === 'function') {
        unsubscribeExpenses();
        console.log('Unsubscribed from expenses data.');
      }
    };
  }, [firebaseService, activeProfileId, profilesLoading, user]);

  // Memoize averages
  const averageIncome = useMemo(() => {
    return yearlyBalances.incomes / (Object.keys(monthlyExpenses).length || 12);
  }, [yearlyBalances.incomes, monthlyExpenses]);

  const averageExpenses = useMemo(() => {
    return yearlyBalances.expenses / (Object.keys(monthlyExpenses).length || 12);
  }, [yearlyBalances.expenses, monthlyExpenses]);

  const averageSavings = useMemo(() => {
    return yearlyBalances.balance / (Object.keys(monthlyExpenses).length || 12);
  }, [yearlyBalances.balance, monthlyExpenses]);

  // Handle category selection
  const handleCategoryChange = (category) => {
    setSelectedCategories(prev =>
      prev.includes(category) ? prev.filter(cat => cat !== category) : [...prev, category]
    );
  };

  // Calculate total months
  const totalMonths = Object.keys(monthlyExpenses).length || 12;

  // Prepare data for Monthly Income and Expenses Trend Line Chart
  const monthlyTrendData = useMemo(() => {
    const months = Object.keys(monthlyIncome).sort((a, b) => new Date(a) - new Date(b));
    return {
      labels: months,
      datasets: [
        {
          label: 'Income ($)',
          data: months.map(month => monthlyIncome[month] || 0),
          borderColor: '#4ADE80',
          backgroundColor: '#4ADE80',
          fill: false,
          tension: 0.1,
        },
        {
          label: 'Expenses ($)',
          data: months.map(month => monthlyExpenses[month] || 0),
          borderColor: '#F87171',
          backgroundColor: '#F87171',
          fill: false,
          tension: 0.1,
        },
      ],
    };
  }, [monthlyIncome, monthlyExpenses]);

  // Prepare data for Monthly Summary Table
  const monthlySummary = useMemo(() => {
    const months = Object.keys(monthlyIncome).sort((a, b) => new Date(a) - new Date(b));
    return months.map(month => ({
      month,
      income: monthlyIncome[month] || 0,
      expenses: monthlyExpenses[month] || 0,
      savings: (monthlyIncome[month] || 0) - (monthlyExpenses[month] || 0),
    }));
  }, [monthlyIncome, monthlyExpenses]);

  return (
    <div className=" dark:text-white">
      <div className="max-w-7xl mx-auto px-4 lg:px-8 py-6">
        {/* Header Section */}
        <header className="flex justify-center items-center mb-6">
          <h3 className="text-6xl font-semibold">Dashboard</h3>
        </header>

        {/* Error Handling */}
        {error && (
          <div className="border border-red-400 text-red-700 px-4 py-3 rounded relative mb-6" role="alert">
            <strong className="font-bold">Error!</strong>
            <span className="block sm:inline"> {error}</span>
          </div>
        )}

        {/* Loading Indicator */}
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <svg
              className="animate-spin -ml-1 mr-3 h-10 w-10 text-indigo-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              aria-label="Loading"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8H4z"
              ></path>
            </svg>
            <span className="text-xl">Loading...</span>
          </div>
        ) : (
          <>
            {/* Financial Overview Cards */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-10">
              {/* Income Card */}
              <div className="p-6 bg-white dark:bg-gray-700 rounded-lg shadow flex flex-col items-center hover:shadow-lg transition-shadow duration-300">
                <h4 className="text-lg font-semibold mb-2">Total Income (YTD)</h4>
                <p className="text-3xl font-bold text-indigo-600 dark:text-indigo-400">
                  ${Number(yearlyBalances.incomes || 0).toFixed(2)}
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-300">
                  Average: ${averageIncome.toFixed(2)} per month
                </p>
              </div>
              {/* Expenses Card */}
              <div className="p-6 bg-white dark:bg-gray-700 rounded-lg shadow flex flex-col items-center hover:shadow-lg transition-shadow duration-300">
                <h4 className="text-lg font-semibold mb-2">Total Expenses (YTD)</h4>
                <p className="text-3xl font-bold text-red-600 dark:text-red-400">
                  ${Number(yearlyBalances.expenses || 0).toFixed(2)}
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-300">
                  Average: ${averageExpenses.toFixed(2)} per month
                </p>
              </div>
              {/* Net Savings Card */}
              <div className="p-6 bg-white dark:bg-gray-700 rounded-lg shadow flex flex-col items-center hover:shadow-lg transition-shadow duration-300">
                <h4 className="text-lg font-semibold mb-2">Net Savings (YTD)</h4>
                <p className="text-3xl font-bold text-blue-600 dark:text-blue-400">
                  ${Number(yearlyBalances.balance || 0).toFixed(2)}
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-300">
                  Average: ${averageSavings.toFixed(2)} per month
                </p>
              </div>
            </div>

            {/* Monthly Income and Expenses Trend Line Chart */}
            <div className="bg-white dark:bg-gray-700 rounded-lg shadow p-6 mb-10">
              <h4 className="text-lg font-semibold mb-4 text-center">Monthly Income & Expenses Trend</h4>
              <div className="h-64">
                <Line
                  data={monthlyTrendData}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        position: 'bottom',
                      },
                      tooltip: {
                        mode: 'index',
                        intersect: false,
                        callbacks: {
                          label: function(context) {
                            const label = context.dataset.label || '';
                            const value = context.parsed.y || 0;
                            return `${label}: $${value.toFixed(2)}`;
                          },
                        },
                      },
                    },
                    interaction: {
                      mode: 'nearest',
                      axis: 'x',
                      intersect: false,
                    },
                    scales: {
                      y: {
                        beginAtZero: true,
                        ticks: {
                          callback: function (value) {
                            return '$' + value;
                          },
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>

            {/* Doughnut Chart for Top Spending Categories */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-10">
              <div className="bg-white dark:bg-gray-700 rounded-lg shadow p-6">
                <h4 className="text-lg font-semibold mb-4 text-center">Top Spending Categories</h4>
                <div className="h-64">
                  <Doughnut
                    data={{
                      labels: topSpendingCategories.map(cat => cat.category),
                      datasets: [{
                        data: topSpendingCategories.map(cat => cat.total),
                        backgroundColor: ['#6366F1', '#10B981', '#F59E0B', '#EF4444', '#3B82F6'],
                      }],
                    }}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          position: 'bottom',
                        },
                        tooltip: {
                          callbacks: {
                            label: function(context) {
                              const label = context.label || '';
                              const value = context.parsed || 0;
                              return `${label}: $${value.toFixed(2)}`;
                            },
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>

              {/* Most Frequent Expenses Line Chart */}
              <div className="bg-white dark:bg-gray-700 rounded-lg shadow p-6">
                <h4 className="text-lg font-bold mb-4 text-center">Most Frequent Expenses</h4>
                <div className="flex flex-wrap justify-center mb-4">
                  {topSpendingCategories.map(cat => (
                    <label key={cat.category} className="inline-flex items-center mr-4 mb-2 sm:mb-0">
                      <input
                        type="checkbox"
                        checked={selectedCategories.includes(cat.category)}
                        onChange={() => handleCategoryChange(cat.category)}
                        className="form-checkbox h-4 w-4 text-indigo-600 dark:text-indigo-300"
                        aria-label={`Filter ${cat.category}`}
                      />
                      <span className="ml-2 capitalize">{cat.category}</span>
                    </label>
                  ))}
                </div>
                <div className="h-64">
                  <Line
                    data={{
                      labels: monthlyTrendData.labels,
                      datasets: mostFrequentExpenses
                        .filter(expense => selectedCategories.includes(expense.category))
                        .map((expense, index) => ({
                          label: expense.category,
                          data: monthlyTrendData.labels.map(month => expense.monthlyFreq[month] || 0),
                          fill: false,
                          borderColor: ['#6366F1', '#10B981', '#F59E0B', '#EF4444', '#3B82F6'][index % 5],
                          borderDash: index % 2 === 0 ? [] : [5, 5],
                          borderWidth: 2,
                          pointRadius: 4,
                          pointHoverRadius: 6,
                          tension: 0.3,
                        })),
                    }}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          position: 'bottom',
                        },
                        tooltip: {
                          enabled: true,
                          mode: 'index',
                          intersect: false,
                        },
                      },
                      interaction: {
                        mode: 'nearest',
                        axis: 'x',
                        intersect: false,
                      },
                      scales: {
                        y: {
                          beginAtZero: true,
                          ticks: {
                            precision: 0,
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>

            {/* Monthly Summary Table */}
            <div className="bg-white dark:bg-gray-700 rounded-lg shadow p-6 mb-10">
              <h4 className="text-lg font-bold mb-4 text-center">Monthly Summary</h4>
              <div className="overflow-x-auto">
                <table className="min-w-full text-center">
                  <thead>
                    <tr>
                      <th className="py-3 px-4 border-b text-sm font-medium">Month</th>
                      <th className="py-3 px-4 border-b text-sm font-medium">Income ($)</th>
                      <th className="py-3 px-4 border-b text-sm font-medium">Expenses ($)</th>
                      <th className="py-3 px-4 border-b text-sm font-medium">Savings ($)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {monthlySummary.length > 0 ? (
                      monthlySummary.map((monthData, index) => (
                        <tr key={index} className="hover:bg-gray-100 dark:hover:bg-gray-600">
                          <td className="py-4 px-4 border-b">{monthData.month}</td>
                          <td className="py-4 px-4 border-b">
                            ${monthData.income.toFixed(2)}
                          </td>
                          <td className="py-4 px-4 border-b">
                            ${monthData.expenses.toFixed(2)}
                          </td>
                          <td className="py-4 px-4 border-b">
                            ${monthData.savings.toFixed(2)}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="py-6 px-4 text-center text-gray-600 dark:text-gray-400">
                          No monthly data available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Dashboard;








































































































