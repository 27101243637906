// src/contexts/AuthContext.js

import React, { createContext, useState, useEffect, useContext } from 'react';
import { auth, db } from '../firebase-config';
import { onAuthStateChanged, getIdTokenResult } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';

export const AuthContext = createContext();

// Custom hook to access AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [admin, setAdmin] = useState(false);
  const [hasConsented, setHasConsented] = useState(false);
  const [hasCompletedOnboarding, setHasCompletedOnboarding] = useState(false);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null); // State for ID token

  // Helper function to fetch or initialize the user's document in Firestore
  const initializeUserDoc = async (currentUser) => {
    const userDocRef = doc(db, `users/${currentUser.uid}`);
    try {
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        setHasConsented(userData.hasConsented || false);

        if ('hasCompletedOnboarding' in userData) {
          setHasCompletedOnboarding(userData.hasCompletedOnboarding);
        } else {
          // If field doesn't exist, set default and update Firestore
          setHasCompletedOnboarding(false);
          await setDoc(userDocRef, { hasCompletedOnboarding: false }, { merge: true });
        }
      } else {
        // If no user document, create a default one
        await setDoc(userDocRef, {
          admin: false,
          hasConsented: false,
          hasCompletedOnboarding: false,
        });
        setHasConsented(false);
        setHasCompletedOnboarding(false);
      }
    } catch (error) {
      console.error('Error initializing user document:', error);
      toast.error('Failed to fetch or initialize user data.');
    }
  };

  // Monitor auth state changes and fetch token
  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, async (currentUser) => {
      setLoading(true);
      setUser(currentUser);
      setToken(null); // Reset token if user changes

      if (currentUser) {
        try {
          // Refresh token and get claims
          const tokenResult = await getIdTokenResult(currentUser, true);
          setAdmin(!!tokenResult.claims.admin);

          const fetchedToken = await currentUser.getIdToken(true);
          setToken(fetchedToken);

          console.log('User authenticated:', currentUser.email);
        } catch (error) {
          console.error('Error fetching custom claims or token:', error);
          setAdmin(false);
          toast.error('Failed to verify admin status or fetch token.');
        }

        // Initialize user doc in Firestore
        await initializeUserDoc(currentUser);
      } else {
        // User signed out
        setUser(null);
        setAdmin(false);
        setHasConsented(false);
        setHasCompletedOnboarding(false);
        setToken(null);
      }

      setLoading(false);
    });

    return () => unsubscribeAuth();
  }, []);

  const value = {
    user,
    admin,
    hasConsented,
    setHasConsented,
    hasCompletedOnboarding,
    setHasCompletedOnboarding,
    loading,
    token, // The token is now always in the context
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
};
