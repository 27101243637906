// src/App.js

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';

// Import your components
import SignUp from './components/SignUp';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Sidebar from './components/Sidebar';
import LandingPage from './components/LandingPage';
import ExpenseForm from './components/ExpenseForm';
import IncomeForm from './components/IncomeForm';
import SavingsGoalForm from './components/SavingsGoalForm';
import ViewBudget from './components/ViewBudget';
import PrivacyPolicy from './components/PrivacyPolicy';
import UserRights from './components/UserRights';
import ProfileManager from './components/ProfileManager';
import ProtectedRoute from './components/ProtectedRoute';
import ShoppingList from './components/ShoppingList';
import NotFound from './components/NotFound';
import AdminAddCoupon from './components/AdminAddCoupon';
import Coupons from './components/Coupons';
import AdminRoute from './components/AdminRoute';
import TermsAndConditions from './components/TermsAndConditions';
import ConsentForm from './components/ConsentForm';

// Import the new AssetsDashboard (Stock Manager)
import AssetsDashboard from './components/AssetsDashboard'; 

import { ToastContainer } from 'react-toastify';
import { useAuth } from './contexts/AuthContext';
import { useSidebar } from './contexts/SidebarContext';
import 'react-toastify/dist/ReactToastify.css';
import './index.css'; // Ensure Tailwind CSS is configured

const App = () => {
  const { user, admin, loading, hasConsented } = useAuth();
  const { isSidebarOpen, toggleSidebar } = useSidebar();

  // Handle loading state to prevent rendering until authentication and consent are determined
  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900">
        <p className="text-gray-500 text-xl">Loading...</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex">
      {/* Sidebar is rendered only if user is authenticated and has consented */}
      {user && hasConsented && <Sidebar admin={admin} />}

      {/* Main Content Area */}
      <div className="flex-grow flex flex-col overflow-y-auto w-full">
        {/* Header is rendered only if user is authenticated and has consented */}
        {user && hasConsented && (
          <header className="header p-4">
            {/* Menu Toggle Button (visible on mobile) */}
            <button
              onClick={toggleSidebar}
              className="text-gray-700 focus:outline-none p-4 rounded-md bg-gray-100 hover:bg-gray-200 shadow-md lg:hidden"
              aria-label="Toggle Sidebar"
            >
              <FiMenu className="w-6 h-6 sm:w-7 sm:h-7" />
            </button>

            {/* Header Title */}
            <h1 className="text-2xl sm:text-3xl font-semibold text-center flex-1 mx-4 sm:mx-6 leading-tight">
              Manage Your Finances With Peace Of Mind
            </h1>

            {/* Optional Placeholder for Right Side */}
            <div className="mr-2 lg:hidden">
              {/* Additional buttons or empty to maintain symmetry */}
            </div>
          </header>
        )}

        {/* Main Content Section */}
        <main className={`flex-grow ${user && hasConsented ? 'pt-20' : 'pt-0'} px-2 sm:px-4 lg:px-8 overflow-auto w-full`}>
          <ToastContainer position="top-right" reverseOrder={false} />

          <Routes>
            {/* Root Route */}
            <Route
              path="/"
              element={user ? <Navigate to="/dashboard" replace /> : <LandingPage />}
            />
            {/* Authentication Routes */}
            <Route
              path="/signup"
              element={!user ? <SignUp /> : <Navigate to="/dashboard" replace />}
            />
            <Route
              path="/login"
              element={!user ? <Login /> : <Navigate to="/dashboard" replace />}
            />
            {/* Protected Routes */}
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile-manager"
              element={
                <ProtectedRoute>
                  <ProfileManager />
                </ProtectedRoute>
              }
            />
            <Route
              path="/expenses"
              element={
                <ProtectedRoute>
                  <ExpenseForm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/income"
              element={
                <ProtectedRoute>
                  <IncomeForm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/savings-goals"
              element={
                <ProtectedRoute>
                  <SavingsGoalForm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/edit-expense/:id"
              element={
                <ProtectedRoute>
                  <ExpenseForm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/view-budget"
              element={
                <ProtectedRoute>
                  <ViewBudget />
                </ProtectedRoute>
              }
            />

            {/* Coupons and Shopping List */}
            <Route path="/coupons" element={<Coupons />} />
            <Route
              path="/shopping-list"
              element={
                <ProtectedRoute>
                  <ShoppingList />
                </ProtectedRoute>
              }
            />

            {/* Admin Protected Route */}
            <Route
              path="/admin/add-coupon"
              element={
                <AdminRoute>
                  <AdminAddCoupon />
                </AdminRoute>
              }
            />

            {/* Stock Manager (AssetsDashboard) */}
            <Route
              path="/stock-manager"
              element={
                <ProtectedRoute>
                  <AssetsDashboard />
                </ProtectedRoute>
              }
            />

            {/* Policy Pages */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/user-rights" element={<UserRights />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

            {/* Fallback Route */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>

        {/* Footer is rendered only if user is authenticated and has consented */}
        {user && hasConsented && (
          <footer className="text-text text-center py-2 text-sm">
            <p>&copy; 2024 Atlas Budget. All rights reserved.</p>
            <div className="mt-2">
              <a href="/privacy-policy" className="text-blue-400 hover:underline mr-4">
                Privacy Policy
              </a>
              <a href="/user-rights" className="text-blue-400 hover:underline mr-4">
                User Rights
              </a>
              <a href="/terms-and-conditions" className="text-blue-400 hover:underline">
                Terms and Conditions
              </a>
            </div>
          </footer>
        )}
      </div>

      {/* Consent Form Overlay */}
      {user && !hasConsented && <ConsentForm />}
    </div>
  );
};

export default App;
