// src/components/ConsentForm.js

import React, { useState } from 'react';
import { db } from '../firebase-config';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom'; // Import Link

const ConsentForm = () => {
  const { user } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleConsent = async () => {
    setIsSubmitting(true);
    try {
      const userDocRef = doc(db, `users/${user.uid}`);
      await setDoc(
        userDocRef,
        {
          hasConsented: true,
          consentedAt: serverTimestamp(),
        },
        { merge: true }
      );
      toast.success('Thank you for your consent!');
    } catch (error) {
      console.error('Error updating consent status:', error);
      toast.error('Failed to submit consent. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 p-6 z-50">
      <div className="bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 p-8 rounded-lg shadow-lg max-w-3xl w-full overflow-y-auto max-h-screen">
        <h2 className="text-3xl font-bold mb-6 text-center">User Consent Agreement</h2>
        <div className="space-y-4">
          <p>
            Welcome to Atlas Budget! Before you proceed, we need to obtain your consent regarding our terms and conditions, privacy policies, and data usage. Please review the following documents carefully.
          </p>
          
          <div className="space-y-2">
            <h3 className="text-xl font-semibold">1. Terms and Conditions</h3>
            <p className="text-gray-600 dark:text-gray-300">
              By using our services, you agree to abide by our terms and conditions. These terms outline your rights and responsibilities as a user.
              <Link to="/terms-and-conditions" className="text-blue-500 hover:underline ml-2">
                Read More
              </Link>
            </p>
          </div>
          
          <div className="space-y-2">
            <h3 className="text-xl font-semibold">2. Privacy Policy</h3>
            <p className="text-gray-600 dark:text-gray-300">
              We value your privacy. Our privacy policy details how we collect, use, and protect your personal information.
              <Link to="/privacy-policy" className="text-blue-500 hover:underline ml-2">
                Read More
              </Link>
            </p>
          </div>
          
          <div className="space-y-2">
            <h3 className="text-xl font-semibold">3. User Rights</h3>
            <p className="text-gray-600 dark:text-gray-300">
              You have specific rights regarding your data and how it's used. Please refer to our User Rights document for more information.
              <Link to="/user-rights" className="text-blue-500 hover:underline ml-2">
                Read More
              </Link>
            </p>
          </div>
          
          {/* Optionally, add more sections with links as needed */}
        </div>
        <div className="mt-8 flex justify-end space-x-4">
          <button
            onClick={handleConsent}
            disabled={isSubmitting}
            className={`px-6 py-2 rounded-full text-white font-semibold ${
              isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'
            } transition-colors duration-300`}
          >
            {isSubmitting ? 'Submitting...' : 'I Agree'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConsentForm;





