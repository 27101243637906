// src/components/Login.js

import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from '../firebase-config';
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import { FiLock, FiMail } from 'react-icons/fi'; // Icons for better UI
import { motion } from 'framer-motion'; // For animations

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/dashboard'); // Redirect to dashboard
    } catch (error) {
      console.error('Error logging in:', error);
      alert('Invalid credentials, please try again.');
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate('/dashboard'); // Redirect to dashboard
    } catch (error) {
      console.error('Error logging in with Google:', error);
      alert('Failed to log in with Google. Please try again.');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-indigo-200 via-blue-200 to-purple-200">
      {/* Login Card */}
      <motion.div
        className="bg-white bg-opacity-95 p-8 rounded-lg shadow-lg w-full max-w-md"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        {/* Header */}
        <h2 className="text-2xl font-semibold text-center text-gray-800 mb-4">
          Welcome Back!
        </h2>
        <p className="text-center text-gray-600 mb-6">
          Manage your finances with peace of mind.
        </p>

        {/* Login Form */}
        <form onSubmit={handleLogin} className="space-y-4">
          {/* Email Input */}
          <div className="relative">
            <FiMail className="absolute left-3 top-3 text-gray-400" />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              className="pl-10 pr-4 py-3 w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 transition duration-200"
              required
              aria-label="Email"
            />
          </div>

          {/* Password Input */}
          <div className="relative">
            <FiLock className="absolute left-3 top-3 text-gray-400" />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              className="pl-10 pr-4 py-3 w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 transition duration-200"
              required
              aria-label="Password"
            />
          </div>

          {/* Login Button */}
          <button
            type="submit"
            className="w-full bg-indigo-600 text-white py-3 px-4 rounded-md shadow hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-200"
          >
            Log In
          </button>
        </form>

        {/* OR Divider */}
        <div className="flex items-center my-6">
          <hr className="flex-grow border-gray-300" />
          <span className="mx-2 text-gray-500">OR</span>
          <hr className="flex-grow border-gray-300" />
        </div>

        {/* Google Login Button */}
        <button
          onClick={handleGoogleLogin}
          className="w-full bg-red-500 text-white py-3 px-4 rounded-md shadow hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition duration-200 flex items-center justify-center"
        >
          {/* Google SVG Icon */}
          <svg
            className="w-5 h-5 mr-2"
            viewBox="0 0 533.5 544.3"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M533.5 278.4c0-17.4-1.5-34.1-4.7-50.3H272v95.4h147.4c-6.3 34.2-25.3 63.2-53.8 82.5v68.3h86.8c50.8-46.9 80.4-116.1 80.4-200.9z"
              fill="#4285f4"
            />
            <path
              d="M272 544.3c72.6 0 133.4-24 177.8-65.1l-86.8-68.3c-24.1 16.2-54.9 25.7-91 25.7-69.9 0-129-47-150.3-110.3h-90.3v69.4c44.5 87.4 135.3 147 240.6 147z"
              fill="#34a853"
            />
            <path
              d="M121.7 327.6c-4.9-14.7-7.7-30.4-7.7-46.6s2.8-31.9 7.7-46.6v-69.4h-90.3c-12.3 24.6-19.4 52.7-19.4 82.5s7.1 57.9 19.4 82.5l90.3-69.4z"
              fill="#fbbc04"
            />
            <path
              d="M272 107.6c38.5 0 73.3 13.3 100.9 39.4l75.6-75.6C405.4 24 345.6 0 272 0 166.7 0 75.9 59.6 31.4 147l90.3 69.4C143 154.6 202.1 107.6 272 107.6z"
              fill="#ea4335"
            />
          </svg>
          Log In with Google
        </button>

        {/* Sign Up Link */}
        <p className="mt-6 text-center text-gray-600">
          Don't have an account?{' '}
          <Link to="/signup" className="text-indigo-600 hover:underline">
            Sign Up
          </Link>
        </p>
      </motion.div>
    </div>
  );
};

export default Login;










