// src/components/ProfileManager.js

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from './Modal'; // Adjust the path as needed
import { auth, db, storage } from '../firebase-config'; // Updated to include Firestore db
import {
  doc,
  setDoc,
  deleteDoc,
} from 'firebase/firestore';
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import { ProfileContext } from '../contexts/ProfileContext'; // Import ProfileContext
import { toast } from 'react-toastify';

const ProfileManager = () => {
  // State variables for modals and profile data
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  
  // Profile data states including new fields for theme
  const [newProfileData, setNewProfileData] = useState({ 
    name: '', 
    email: '', 
    avatarUrl: '', 
    theme: 'Light' 
  });
  const [editProfileData, setEditProfileData] = useState({ 
    name: '', 
    email: '', 
    avatarUrl: '', 
    theme: 'Light' 
  });
  
  // File states for avatar uploads
  const [file, setFile] = useState(null);
  const [editFile, setEditFile] = useState(null);
  
  // Currently selected profile ID for editing or deleting
  const [currentProfileId, setCurrentProfileId] = useState(null);
  
  const navigate = useNavigate();

  // Extract profiles and active profile info from context
  const { profiles, activeProfileId, updateActiveProfile } = useContext(ProfileContext);

  // Redirect to login if user is not authenticated
  useEffect(() => {
    const user = auth.currentUser;
    if (!user) {
      navigate('/login');
    }
  }, [navigate]);

  // Define available theme options
  const themeOptions = ['Light', 'Dark', 'Blue', 'Green', 'Red', 'Purple', 'Yellow', 'Teal', 'Pink'];


  // Handle file selection for adding a new profile
  const handleAddProfileFileChange = (e) => {
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];
      if (selectedFile.size > 2 * 1024 * 1024) { // 2MB limit
        toast.error('File size exceeds 2MB. Please choose a smaller file.');
        return;
      }
      setFile(selectedFile);
    }
  };

  // Handle adding a new profile
  const handleAddProfileSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;
    if (!user) return;

    const profileId = `profile_${Date.now()}`; // Unique profile ID
    const profileDocRef = doc(db, `users/${user.uid}/profiles/${profileId}`);
    
    // Default avatar URL
    let avatarUrl = `https://plus.unsplash.com/premium_photo-1681469490209-c2f9f8f5c0a2?q=80&w=2683&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D`;

    try {
      if (file) {
        // Upload avatar to Firebase Storage
        const storageReference = storageRef(storage, `users/avatars/${user.uid}/${profileId}`);
        await uploadBytes(storageReference, file);
        avatarUrl = await getDownloadURL(storageReference);
      }

      // Save profile data to Firestore
      await setDoc(profileDocRef, {
        id: profileId,
        name: newProfileData.name,
        email: newProfileData.email,
        avatarUrl,
        theme: newProfileData.theme,
      });

      // Reset states and close modal
      setShowAddModal(false);
      setNewProfileData({ name: '', email: '', avatarUrl: '', theme: 'Light' });
      setFile(null);
      toast.success('Profile added successfully!');
    } catch (error) {
      console.error('Error adding profile:', error);
      toast.error('Failed to add profile. Please try again.');
    }
  };

  // Handle switching to a selected profile
  const handleSwitchProfile = (profileId) => {
    const selectedProfile = profiles.find((profile) => profile.id === profileId);
    if (selectedProfile) {
      updateActiveProfile(profileId);
      toast.success('Profile switched successfully.');
    } else {
      console.error(`Profile with ID ${profileId} does not exist.`);
      toast.error('Selected profile does not exist.');
    }
  };

  // Open Edit Modal and set current profile data
  const openEditModal = (profileId) => {
    const profile = profiles.find((profile) => profile.id === profileId);
    if (profile) {
      setCurrentProfileId(profileId);
      setEditProfileData({
        name: profile.name,
        email: profile.email,
        avatarUrl: profile.avatarUrl,
        theme: profile.theme || 'Light',
      });
      setShowEditModal(true);
    } else {
      console.error(`Profile with ID ${profileId} does not exist.`);
      toast.error('Unable to edit. Profile does not exist.');
    }
  };

  // Handle file selection for editing an existing profile
  const handleEditProfileFileChange = (e) => {
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];
      if (selectedFile.size > 2 * 1024 * 1024) { // 2MB limit
        toast.error('File size exceeds 2MB. Please choose a smaller file.');
        return;
      }
      setEditFile(selectedFile);
    }
  };

  // Handle updating an existing profile
  const handleEditProfileSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;
    if (!user || !currentProfileId) return;

    const profileDocRef = doc(db, `users/${user.uid}/profiles/${currentProfileId}`);
    let avatarUrl = editProfileData.avatarUrl;

    try {
      if (editFile) {
        // Upload new avatar if changed
        const storageReference = storageRef(storage, `users/avatars/${user.uid}/${currentProfileId}`);
        await uploadBytes(storageReference, editFile);
        avatarUrl = await getDownloadURL(storageReference);
      }

      // Update profile data in Firestore
      await setDoc(
        profileDocRef,
        {
          name: editProfileData.name,
          email: editProfileData.email,
          avatarUrl,
          theme: editProfileData.theme,
        },
        { merge: true } // Only update specific fields
      );

      // Reset states and close modal
      setShowEditModal(false);
      setEditProfileData({ name: '', email: '', avatarUrl: '', theme: 'Light' });
      setEditFile(null);
      setCurrentProfileId(null);
      toast.success('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Failed to update profile. Please try again.');
    }
  };

  // Open Delete Modal and set current profile ID
  const openDeleteModal = (profileId) => {
    const profile = profiles.find((profile) => profile.id === profileId);
    if (profile) {
      setCurrentProfileId(profileId);
      setShowDeleteModal(true);
    } else {
      console.error(`Profile with ID ${profileId} does not exist.`);
      toast.error('Unable to delete. Profile does not exist.');
    }
  };

  // Handle deleting a profile
  const handleDeleteProfile = async () => {
    const user = auth.currentUser;
    if (!user || !currentProfileId) return;

    const profileDocRef = doc(db, `users/${user.uid}/profiles/${currentProfileId}`);
    const avatarStorageRef = storageRef(storage, `users/avatars/${user.uid}/${currentProfileId}`);

    try {
      await deleteDoc(profileDocRef);
      await deleteObject(avatarStorageRef).catch(() => null); // Ignore errors if avatar does not exist

      if (activeProfileId === currentProfileId) {
        updateActiveProfile(null);
        toast.info('Deleted active profile. Please select another.');
      } else {
        toast.success('Profile deleted successfully!');
      }

      setShowDeleteModal(false);
      setCurrentProfileId(null);
    } catch (error) {
      console.error('Error deleting profile:', error);
      toast.error('Failed to delete profile. Please try again.');
    }
  };

  return (
    <div className="py-10 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-modal-background p-6 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold mb-6 text-center text-text">Profile Manager</h1>
        <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
          <button
            className={`px-6 py-2 rounded-md text-white ${
              profiles.length >= 5 ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'
            } transition-colors duration-200`}
            onClick={() => setShowAddModal(true)}
            disabled={profiles.length >= 5}
            aria-disabled={profiles.length >= 5}
          >
            Add Profile
          </button>
          {profiles.length >= 5 && (
            <p className="text-red-500 mt-2 sm:mt-0">Maximum of 5 profiles reached.</p>
          )}
        </div>
        <ul className="mt-4 space-y-4">
          {profiles.length === 0 ? (
            <li className="text-center text-gray-500">No profiles available. Please add one.</li>
          ) : (
            profiles.map((profile) => {
              const isActive = activeProfileId === profile.id;
              return (
                <li
                  key={profile.id}
                  className={`flex items-center justify-between p-4 rounded shadow cursor-pointer ${
                    isActive ? 'border-2 border-blue-500' : 'border border-transparent'
                  }`}
                  onClick={() => handleSwitchProfile(profile.id)}
                  aria-current={isActive ? 'true' : 'false'}
                  role="button"
                  tabIndex="0"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleSwitchProfile(profile.id);
                    }
                  }}
                >
                  <div className="flex items-center">
                    <img
                      src={profile.avatarUrl || 'https://placehold.co/100x100'}
                      alt={`${profile.name} Avatar`}
                      className="w-16 h-16 rounded-full mr-4 object-cover"
                    />
                    <div>
                      <p className="text-lg font-semibold text-text">{profile.name}</p>
                      <p className="text-sm text-gray-600">{profile.email}</p>
                      <p className="text-sm text-gray-500">Theme: {profile.theme}</p>
                    </div>
                  </div>
                  <div className="flex space-x-2">
                    <button
                      className="px-3 py-1 bg-yellow-500 hover:bg-yellow-600 text-white rounded"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering the li onClick
                        openEditModal(profile.id);
                      }}
                      aria-label={`Edit profile ${profile.name}`}
                    >
                      Edit
                    </button>
                    <button
                      className="px-3 py-1 bg-red-500 hover:bg-red-600 text-white rounded"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering the li onClick
                        openDeleteModal(profile.id);
                      }}
                      aria-label={`Delete profile ${profile.name}`}
                    >
                      Delete
                    </button>
                  </div>
                </li>
              );
            })
          )}
        </ul>

        {/* Add Profile Modal */}
        {showAddModal && (
          <Modal isOpen={showAddModal} onClose={() => setShowAddModal(false)} title="Add New Profile">
            <form onSubmit={handleAddProfileSubmit} className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Name:
                </label>
                <input
                  type="text"
                  id="name"
                  value={newProfileData.name}
                  onChange={(e) => setNewProfileData({ ...newProfileData, name: e.target.value })}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  required
                  placeholder="Enter profile name"
                />
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email:
                </label>
                <input
                  type="email"
                  id="email"
                  value={newProfileData.email}
                  onChange={(e) => setNewProfileData({ ...newProfileData, email: e.target.value })}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  required
                  placeholder="Enter email address"
                />
              </div>

              <div>
                <label htmlFor="theme" className="block text-sm font-medium text-gray-700">
                  Theme:
                </label>
                <select
                  id="theme"
                  value={newProfileData.theme}
                  onChange={(e) => setNewProfileData({ ...newProfileData, theme: e.target.value })}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                >
                  {themeOptions.map((theme) => (
                    <option key={theme} value={theme}>
                      {theme}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="avatar" className="block text-sm font-medium text-gray-700">
                  Upload Avatar:
                </label>
                <input
                  type="file"
                  id="avatar"
                  accept="image/*"
                  onChange={handleAddProfileFileChange}
                  className="mt-1 block w-full text-sm text-gray-500
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-full file:border-0
                  file:text-sm file:font-semibold
                  file:bg-blue-50 file:text-blue-700
                  hover:file:bg-blue-100"
                />
              </div>

              <div className="flex justify-end space-x-2">
                <button type="submit" className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded">
                  Create Profile
                </button>
                <button type="button" onClick={() => setShowAddModal(false)} className="px-4 py-2 bg-gray-300 hover:bg-gray-400 text-gray-800 rounded">
                  Cancel
                </button>
              </div>
            </form>
          </Modal>
        )}

        {/* Edit Profile Modal */}
        {showEditModal && (
          <Modal isOpen={showEditModal} onClose={() => setShowEditModal(false)} title="Edit Profile">
            <form onSubmit={handleEditProfileSubmit} className="space-y-4">
              <div>
                <label htmlFor="edit-name" className="block text-sm font-medium text-gray-700">
                  Name:
                </label>
                <input
                  type="text"
                  id="edit-name"
                  value={editProfileData.name}
                  onChange={(e) => setEditProfileData({ ...editProfileData, name: e.target.value })}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  required
                  placeholder="Enter new name"
                />
              </div>

              <div>
                <label htmlFor="edit-email" className="block text-sm font-medium text-gray-700">
                  Email:
                </label>
                <input
                  type="email"
                  id="edit-email"
                  value={editProfileData.email}
                  readOnly
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 bg-gray-100 cursor-not-allowed"
                  placeholder="Email cannot be changed"
                />
              </div>

              <div>
                <label htmlFor="edit-theme" className="block text-sm font-medium text-gray-700">
                  Theme:
                </label>
                <select
                  id="edit-theme"
                  value={editProfileData.theme}
                  onChange={(e) => setEditProfileData({ ...editProfileData, theme: e.target.value })}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                >
                  {themeOptions.map((theme) => (
                    <option key={theme} value={theme}>
                      {theme}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="edit-avatar" className="block text-sm font-medium text-gray-700">
                  Change Avatar:
                </label>
                <input
                  type="file"
                  id="edit-avatar"
                  accept="image/*"
                  onChange={handleEditProfileFileChange}
                  className="mt-1 block w-full text-sm text-gray-500
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-full file:border-0
                  file:text-sm file:font-semibold
                  file:bg-blue-50 file:text-blue-700
                  hover:file:bg-blue-100"
                />
              </div>

              <div className="flex justify-end space-x-2">
                <button type="submit" className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded">
                  Save Changes
                </button>
                <button type="button" onClick={() => setShowEditModal(false)} className="px-4 py-2 bg-gray-300 hover:bg-gray-400 text-gray-800 rounded">
                  Cancel
                </button>
              </div>
            </form>
          </Modal>
        )}

        {/* Delete Confirmation Modal */}
        {showDeleteModal && (
          <Modal isOpen={showDeleteModal} onClose={() => setShowDeleteModal(false)} title="Confirm Deletion">
            <p className="text-gray-700 mb-4">Are you sure you want to delete this profile? This action cannot be undone.</p>
            <div className="flex justify-end space-x-2">
              <button type="button" className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded" onClick={handleDeleteProfile}>
                Delete
              </button>
              <button type="button" onClick={() => setShowDeleteModal(false)} className="px-4 py-2 bg-gray-300 hover:bg-gray-400 text-gray-800 rounded">
                Cancel
              </button>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default ProfileManager;
