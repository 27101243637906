// src/components/Sidebar.js

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  FiHome,
  FiLogOut,
  FiShoppingCart,
  FiGift,
  FiBarChart,
  FiDollarSign,
  FiUser,
  FiClipboard,
  FiX,
  FiMenu
} from 'react-icons/fi';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase-config';
import { useSidebar } from '../contexts/SidebarContext';
import { useAuth } from '../contexts/AuthContext';


const Sidebar = () => {
  const { isSidebarOpen, toggleSidebar, isCollapsed, toggleCollapse } = useSidebar();
  const { admin } = useAuth();
  const location = useLocation();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      // Redirect to landing page after logout
      window.location.href = '/';
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  // Authenticated user links
  const authLinks = [
    { to: '/dashboard', icon: <FiHome />, label: 'Dashboard' },
    { to: '/view-budget', icon: <FiBarChart />, label: 'View Budget' },
    { to: '/coupons', icon: <FiGift />, label: 'Deals' },
    { to: '/shopping-list', icon: <FiShoppingCart />, label: 'Shopping List' },
    { to: '/stock-manager', icon: <FiDollarSign />, label: 'Crypto Asset Dashboard' },
    { to: '/profile-manager', icon: <FiUser />, label: 'Switch Profile' },
  ];

  // Admin-specific links
  const adminLinks = [
    { to: '/admin/add-coupon', icon: <FiClipboard />, label: 'Add Coupon' },
  ];

  return (
    <>
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-30 lg:hidden"
          onClick={toggleSidebar}
          aria-hidden="true"
        ></div>
      )}

      <div
        className={`fixed top-0 left-0 h-full z-40 transition-transform duration-300 transform ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } lg:translate-x-0 ${isCollapsed ? 'lg:w-20' : 'lg:w-64'} sidebar`}
      >
        <div className="flex flex-col h-full justify-between">
          {/* Sidebar Header */}
          <div className="sidebar-header p-4 flex items-center justify-between">
            <div className="flex items-center">
              {!isCollapsed && (
                <Link to="/">
                  <img
                    src="/AtlasBudget.png"
                    alt="Atlas Budget"
                    className="w-24 h-24 rounded-full"
                  />
                </Link>
              )}
              {isCollapsed && (
                <Link to="/">
                  <img
                    src="/AtlasBudget.png"
                    alt="Atlas Budget"
                    className="w-10 h-10 rounded-full lg:hidden"
                  />
                </Link>
              )}
            </div>

            <div className="flex items-center">
              {/* Collapse Toggle Button - Visible on Desktop */}
              <button
                onClick={toggleCollapse}
                className="hidden lg:block hover:opacity-75 focus:outline-none mr-2"
                aria-label={isCollapsed ? 'Expand Sidebar' : 'Collapse Sidebar'}
              >
                {isCollapsed ? <FiMenu className="w-6 h-6" /> : <FiX className="w-6 h-6" />}
              </button>

              {/* Close Sidebar Button - Visible on Mobile */}
              <button
                onClick={toggleSidebar}
                className="lg:hidden hover:opacity-75 focus:outline-none"
                aria-label="Close Sidebar"
              >
                <FiX className="w-6 h-6" />
              </button>
            </div>
          </div>

          {/* Navigation Links */}
          <nav className="mt-4 flex-grow overflow-y-auto">
            <ul className="space-y-2 px-2">
              {authLinks.map((link, index) => (
                <li key={index}>
                  <Link
                    to={link.to}
                    className={`sidebar-link flex items-center p-3 rounded-md ${
                      location.pathname === link.to
                        ? 'active-link'
                        : 'hover-link'
                    } transition-colors duration-200`}
                    onClick={() => {
                      if (isSidebarOpen) toggleSidebar(); // Close sidebar on mobile
                    }}
                  >
                    {link.icon}
                    {!isCollapsed && <span className="ml-3">{link.label}</span>}
                  </Link>
                </li>
              ))}

              {admin &&
                adminLinks.map((link, index) => (
                  <li key={index}>
                    <Link
                      to={link.to}
                      className={`sidebar-link flex items-center p-3 rounded-md ${
                        location.pathname === link.to
                          ? 'active-link'
                          : 'hover-link'
                      } transition-colors duration-200`}
                      onClick={() => {
                        if (isSidebarOpen) toggleSidebar();
                      }}
                    >
                      {link.icon}
                      {!isCollapsed && <span className="ml-3">{link.label}</span>}
                    </Link>
                  </li>
                ))}
            </ul>
          </nav>

          {/* Logout Button */}
          <div className="sidebar-logout p-4">
            <button
              onClick={handleLogout}
              className="logout-button flex items-center p-3 rounded-md hover-button transition-colors duration-200 w-full"
              aria-label="Logout"
            >
              <FiLogOut />
              {!isCollapsed && <span className="ml-3">Logout</span>}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
