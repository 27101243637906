// src/contexts/ProfileContext.js

import React, { createContext, useState, useEffect, useContext } from 'react';
import { db } from '../firebase-config';
import { collection, onSnapshot } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { AuthContext } from './AuthContext';

export const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [profiles, setProfiles] = useState([]);
  const [activeProfileId, setActiveProfileId] = useState(null);
  const [profilesLoading, setProfilesLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      setProfiles([]);
      setActiveProfileId(null);
      setProfilesLoading(false);
      return;
    }

    const profilesRef = collection(db, 'users', user.uid, 'profiles');

    const unsubscribe = onSnapshot(
      profilesRef,
      (snapshot) => {
        const fetchedProfiles = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setProfiles(fetchedProfiles);
        console.log('Fetched Profiles:', fetchedProfiles);
        if (fetchedProfiles.length > 0 && !activeProfileId) {
          setActiveProfileId(fetchedProfiles[0].id); // Set first profile as active by default
          console.log(`Default active profile set to: ${fetchedProfiles[0].name}`);
        }
        setProfilesLoading(false);
      },
      (error) => {
        console.error('Error fetching profiles:', error);
        toast.error('Failed to fetch profiles.');
        setProfilesLoading(false);
      }
    );

    return () => unsubscribe();
  }, [user, activeProfileId]);

  const updateActiveProfile = (profileId) => {
    if (profiles.some(profile => profile.id === profileId)) {
      setActiveProfileId(profileId);
      toast.success('Active profile updated.');
      console.log(`Active profile updated to ID: ${profileId}`);
    } else {
      console.error(`Profile with ID ${profileId} does not exist.`);
      toast.error('Selected profile does not exist.');
    }
  };

  return (
    <ProfileContext.Provider value={{ profiles, activeProfileId, updateActiveProfile, profilesLoading }}>
      {children}
    </ProfileContext.Provider>
  );
};
